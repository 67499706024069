import axios from "axios";
import { useEffect, useState } from "react";
import { page, action, headers } from "Common/WSCommon";

function Popup({param, popAction, uploadProgress, data, ip, handleData, preVideoName, preVideoId, videoData, userData, partList }){

    const [overlayValue, setOverlayValue] = useState(false);
    const [addAdvanced, setAddAdvanced] = useState(false);
    const [profileList, setProfileList] = useState();
    const [sizeList, setSizeList] = useState();
    const [VADList, setVADList] = useState();
    const [profile, setProfile] = useState();
    const [size, setSize] = useState();
    const [VAD, setVAD] = useState();
    const [roomName, setRoomName] = useState();
    const [roomNumber, setRoomNumber] = useState();
    const [firstPassword, setFirstPassword] = useState();
    const [curVideoName, setCurVideoName] = useState(preVideoName);
    const [videoName, setVideoName] = useState(preVideoName);
    const [videoId, setVideoId] = useState(preVideoId);
    const [secondPassword, setSecondPassword] = useState();
    const [userId, setUserId] = useState();
    const [userName, setUserName] = useState();
    const [userPassword, setUserPassword] = useState();
    const [userPart, setUserPart] = useState();
    const [userLevel, setUserLevel] = useState();


    useEffect(() => {

        setOverlayValue(true);
        if(data){
            setProfileList(data.Profile);
            setSizeList(data.Size);
            setVADList(data.VAD);
            setProfile(data.Profile[0].key);
            setSize(data.Size[0].key);
            setVAD(data.VAD[1].key);
        }
        if(userData){
            setUserId(userData.id);
            setUserName(userData.name);
            setUserPart(userData.part);
            setUserLevel(userData.level);
        }
        console.log(partList);
    }, []);


    function popupClose(){
        setOverlayValue(false);
        popAction(false);
    }

    function handleFirstPassword(e){
        setFirstPassword(e.target.value);
    }

    function handleSecondPassword(e){
        setSecondPassword(e.target.value);
    }

    function handleAddAdvanced(){
        setAddAdvanced(!addAdvanced);
    }

    function handleSize(e){
        setSize(e.target.value);
    }

    function handleVAD(e){
        setVAD(e.target.value);
    }

    function handleProfile(e){
        setProfile(e.target.value);
    }

    function handleRoomName(e){
        setRoomName(e.target.value);
    }

    function handleRoomNumber(e){
        setRoomNumber(e.target.value);
    }

    function handleUserName(e){
        setUserName(e.target.value);
    }

    function handleUserPart(e){
        setUserPart(e.target.value);
    }

    function handleUserPassword(e){
        setUserPassword(e.target.value);
    }

    function handleUserLevel(e){
        setUserLevel(e.target.value);
    }

    function handleUserId(e){
        setUserId(e.target.value);
    }

    async function changePassword(){
        if(firstPassword != secondPassword){
            alert("비밀번호를 확인하여 주십시오!");
            return false;
        }

        const postData = {
            "pw": firstPassword
        };

        try{
            await axios.post(action + "ChangePasswordAction", JSON.stringify(postData), headers());
            alert("변경되었습니다!");
            popupClose();
        }catch(ex){
            console.log(ex);
        }
    }

    async function createRoom(){
        const postData ={
            "ip": ip,
            "name": roomName,
            "did": roomNumber,
            "size": size,
            "vad": VAD,
            "profileId": profile
        };

        try{
            await axios.post(action + "CreateRoomAction", JSON.stringify(postData), headers());
            const pre = await axios.post(page + "RoomPage", JSON.stringify(postData), headers());
            handleData(pre.data);
            popupClose();
        }catch(ex){
            console.log(ex);
        }
    }

    async function downloadUtil(){
        try{
            const res = await axios.post(action + "DownloadUtilAction", "", { 
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem("token")
                },
                responseType: "blob"
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'VBCABLE.zip'); 
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }catch(ex){
            console.log(ex);
        }

    }

    function handleVideoName(e){
        let value = e.target.value;
        value = value.replace(/\s/g, '');
        setVideoName(value);
    }

    async function changeVideoName(){
        const postData = {
            "id": videoId,
            "curName": curVideoName,
            "name": videoName
        };

        try{
            await axios.post(action + "ChangeVideoNameAction", JSON.stringify(postData), headers());
            videoData();
            popupClose();
        }catch(ex){
            console.log(ex);
        }
    }

    async function modifyUser(){
        const postData = {
            "id": userId,
            "name": userName,
            "password": userPassword,
            "part": userPart,
            "level": userLevel
        };

        try{
            await axios.post(action + "ModifyUserAction", JSON.stringify(postData), headers());
            popupClose();
        }catch(ex){
            console.log(ex);
        }
    }

    async function createUser(){
        const postData = {
            "id": userId,
            "name": userName,
            "password": userPassword,
            "part": userPart,
            "level": userLevel
        };

        try{
            await axios.post(action + "CreateUserAction", JSON.stringify(postData), headers());
            popupClose();
        }catch(ex){
            console.log(ex);
        }
    }

    return(
        <div>
            {overlayValue && (
                <div className="popup-overlay">
                    <div className="popup">
                        {param === "util" && (
                            <>
                                <div className="popup-title">
                                    <h2>유틸리티 설치 가이드</h2>
                                    <button className="popup-close" onClick={() => popupClose()}>
                                        <img src={require("Img/popup-close-icon.png")}></img>
                                    </button>
                                </div>
                                <div className="popup-content">
                                    <label>1. VBCABLE.zip 다운로드 후 압축해제</label>
                                    <label>2. 압축해제한 폴더에서 VBCABLE_Setup_x64.exe 우클릭(32비트는 VBCABLE_Setup.exe)</label>
                                    <label>3. 관리자 권한으로 실행</label>
                                    <label>4. Install Driver 클릭</label>
                                    <label>-- ECB 제어페이지에서 마이크 권한 획득 --</label>
                                    <button onClick={downloadUtil}>다운로드</button>
                                </div>
                            </>
                        )}
                        {param === "password" && (
                            <>
                                <div className="popup-title">
                                    <h2>비밀번호 변경</h2>
                                    <button className="popup-close" onClick={() => popupClose()}>
                                        <img src={require("Img/popup-close-icon.png")}></img>
                                    </button>
                                </div>
                                <div className="popup-content">
                                    <label>비밀번호 변경</label>
                                    <div className="popup-password-area">
                                        <input type="password" placeholder="비밀번호 입력" value={firstPassword} onChange={handleFirstPassword}></input>
                                        <input type="password" placeholder="비밀번호 재입력" value={secondPassword} onChange={handleSecondPassword}></input>
                                    </div>
                                    <button onClick={changePassword}>수정</button>
                                </div>
                            </>
                        )}
                        {param === "saving" && (
                            <>
                                <div className="popup-title">
                                    <h2>녹화 저장</h2>
                                </div>
                                <div className="popup-content">
                                    <label>녹화를 저장 중입니다...</label>
                                    <label>{uploadProgress} % 진행중...</label>
                                </div>
                            </>
                        )}
                        {param === "roomAdd" && (
                            <>
                                <div className="popup-title">
                                    <h2>방 생성</h2>
                                    <button className="popup-close" onClick={() => popupClose()}>
                                        <img src={require("Img/popup-close-icon.png")}></img>
                                    </button>
                                </div>
                                <div className="popup-content">
                                    <label>방 이름</label>
                                    <input type="text" placeholder="방 이름을 입력해 주세요" value={roomName} onChange={handleRoomName}></input>
                                    <label>방 번호</label>
                                    <input type="text" placeholder="방 번호를 입력해 주세요" value={roomNumber} onChange={handleRoomNumber}></input>
                                    <label style={{cursor: "pointer"}} onClick={() => handleAddAdvanced()}>+ 고급옵션</label>
                                    {addAdvanced && (
                                        <>
                                            <label>VAD</label>
                                            <select onChange={handleVAD}>
                                            {VADList && (
                                                VADList.map((item, index) => (
                                                    <option key={index} value={item.key}>{item.value}</option>
                                                ))
                                            )}
                                            </select>
                                            <label>해상도</label>
                                            <select onChange={handleSize}>
                                            {sizeList && (
                                                sizeList.map((item, index) => (
                                                    <option key={index} value={item.key}>{item.value}</option>
                                                ))
                                            )}
                                            </select>
                                            <label>프로파일</label>
                                            <select onChange={handleProfile}>
                                            {profileList && (
                                                profileList.map((item, index) => (
                                                    <option key={index} value={item.key}>{item.value}</option>
                                                ))
                                            )}
                                            </select>
                                        </>
                                    )}
                                    <button onClick={createRoom}>방 생성</button>
                                </div>
                            </>
                        )}
                        {param === "videoName" && (
                            <>
                                <div className="popup-title">
                                    <h2>이름 변경</h2>
                                    <button className="popup-close" onClick={() => popupClose()}>
                                        <img src={require("Img/popup-close-icon.png")}></img>
                                    </button>
                                </div>
                                <div className="popup-content">
                                    <label>이름 변경</label>
                                    <div className="popup-password-area">
                                        <input type="text" placeholder="이름 입력" value={videoName} onChange={handleVideoName}></input>
                                    </div>
                                    <button onClick={changeVideoName}>수정</button>
                                </div>
                            </>
                        )}
                        {param === "userModify" && (
                            <>
                                <div className="popup-title">
                                    <h2>사용자 수정</h2>
                                    <button className="popup-close" onClick={() => popupClose()}>
                                        <img src={require("Img/popup-close-icon.png")}></img>
                                    </button>
                                </div>
                                <div className="popup-content">
                                    {userData && (
                                        <>
                                            <label>사용자 이름</label>
                                            <input type="text" placeholder="이름 입력" value={userName} onChange={handleUserName}></input>
                                            <label>아이디</label>
                                            <input type="text" readOnly placeholder="아이디 입력" defaultValue={userId}></input>
                                            <label>비밀번호</label>
                                            <input type="password" placeholder="비밀번호 입력" value={userPassword} onChange={handleUserPassword}></input>
                                            <label>소속</label>
                                            <select onChange={handleUserPart}>
                                                {partList && (
                                                    partList.partList.map((item, index) => (
                                                        <option key={index} value={item.part}>{item.name}</option>
                                                    ))
                                                )}
                                            </select>
                                            <label>권한</label>
                                            <select onChange={handleUserLevel}>
                                                <option value="0">일반 사용자</option>
                                                <option value="10">녹화 관리자</option>
                                                <option value="15">엔터테인먼트 관리자</option>
                                                <option value="20">시스템 관리자</option>
                                            </select>
                                            <button onClick={modifyUser}>수정</button>
                                        </>
                                    )}
                                    
                                </div>
                            </>
                        )}
                        {param === "userAdd" && (
                            <>
                                <div className="popup-title">
                                    <h2>사용자 생성</h2>
                                    <button className="popup-close" onClick={() => popupClose()}>
                                        <img src={require("Img/popup-close-icon.png")}></img>
                                    </button>
                                </div>
                                <div className="popup-content">
                                    
                                    <label>사용자 이름</label>
                                    <input type="text" placeholder="이름 입력" value={userName} onChange={handleUserName}></input>
                                    <label>아이디</label>
                                    <input type="text" placeholder="아이디 입력" value={userId} onChange={handleUserId}></input>
                                    <label>비밀번호</label>
                                    <input type="password" placeholder="비밀번호 입력" value={userPassword} onChange={handleUserPassword}></input>
                                    <label>소속</label>
                                    <select onChange={handleUserPart}>
                                        {partList && (
                                            partList.partList.map((item, index) => (
                                                <option key={index} value={item.part}>{item.name}</option>
                                            ))
                                        )}
                                    </select>
                                    <label>권한</label>
                                    <select onChange={handleUserLevel}>
                                        <option value="0">일반 사용자</option>
                                        <option value="10">녹화 관리자</option>
                                        <option value="15">엔터테인먼트 관리자</option>
                                        <option value="20">시스템 관리자</option>
                                    </select>
                                    <button onClick={createUser}>생성</button>
                                
                                </div>
                            </>
                        )}
                        {param === "update" && (
                            <>
                                <div className="popup-title">
                                    <h2>업데이트 내역</h2>
                                    <button className="popup-close" onClick={() => popupClose()}>
                                        <img src={require("Img/popup-close-icon.png")}></img>
                                    </button>
                                </div>
                                <div className="popup-content">
                                    <label>v2.02</label>
                                    <label>통계 기능이 추가되었습니다.</label>
                                    <label>얼굴 인식 기능을 통해 참석 인원을 분석해 줍니다.</label>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Popup;