
// publish
export const root = "https://mcuecb.com:40443/";
export const action = "https://mcuecb.com:40443/Action/";
export const page = "https://mcuecb.com:40443/Page/";

// dev
// export const root = "http://localhost:5000/";
// export const action = "http://localhost:5000/Action/";
// export const page = "http://localhost:5000/Page/";

export function headers(param) {
    if(param == "record"){
        return(
            { 
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem("token")
                },
                timeout: 0,
                onUploadProgress: progressEvent => {
                    
                }
            }
        );
    }else{
        return(
            { 
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem("token")
                },
            }
        );
    }
};
