import RefreshToken from "Components/RefreshToken";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Panel from "Components/Panel";
import VideoClent from "Components/VideoClient";
import axios from "axios";
import { page, action, headers, root } from "Common/WSCommon";
import Popup from "Components/Popup";
import recorder from "react-media-recorder";

function ControlPage(){

    const emoticonPreviewRef = useRef();
    const customSubtitlePreviewRef = useRef();
    const subtitlePreviewRef = useRef();
    const mediaRecorderRef = useRef();
    const localStreamRef = useRef();


    const videoRef = useRef();
    const panel1 = useRef();
    const panel2 = useRef();
    const panel3 = useRef();
    const panel4 = useRef();
    const panel5 = useRef();
    const emoticonUploadRef = useRef();
    const customSubtitleUploadRef = useRef();
    const subPresetOneRef = useRef("");
    const subPresetTwoRef = useRef("");
    const subPresetThreeRef = useRef("");

    const location = useLocation();
    const preData = location.state || {};

    const [ip, setIp] = useState(preData.ip);
    const [uid, setUid] = useState(preData.uid);
    const [did, setDid] = useState(preData.did);
    const [name, setName] = useState(preData.name);
    const [data, setData] = useState();
    const [tabMenu, setTabMenu] = useState("participant");
    const [preset, setPreset] = useState([]);
    const [presetNum, setPresetNum] = useState("1");
    const [emoticonRatio, setEmoticonRatio] = useState(10);
    const [count, setCount] = useState(0)
    const [partList, setPartList] = useState([]);
    const [hideState, setHideState] = useState("false");
    const [isAllMuted, setIsAllMuted] = useState(true);
    const [isAllEcho, setIsAllEcho] = useState(true);
    const [emoticonList, setEmoticonList] = useState([]);
    const [customEmoticonList, setCustomEmoticonList] = useState([]);
    const [emoticonPreviewSrc, setEmoticonPreviewSrc] = useState();
    const [emoticonSize, setEmoticonSize] = useState(37);
    const [emoticonHeight, setEmoticonHeight] = useState();
    const [isEmoticonRemoveOn, setIsEmoticonRemoveOn] = useState(false);
    const [subtitleSize, setSubtitleSize] = useState();
    const [subtitleColor, setSubtitleColor] = useState();
    const [subtitleContent, setSubtitleContent] = useState("");
    const [isFirstValueLoaded, setIsFirstValueLoaded] = useState(false);
    const [subtitleFontList, setSubtitleFontList] = useState([]);
    const [subtitleFont, setSubtitleFont] = useState("NanumGothicBold");
    const [subtitleFontVal, setSubtitleFontVal] = useState(0);
    const [customSubtitleList, setCustomSubtitleList] = useState([]);
    const [isCustomSubtitleRemoveOn, setIsCustomSubtitleRemoveOn] = useState(false);
    const [customSubtitlePreviewSrc, setCustomSubtitlePreviewSrc] = useState();
    const [customSubtitleHeight, setCustomSubtitleHeight] = useState();
    const [customSubtitleRatio, setCustomSubtitleRatio] = useState(10);
    const [customSubtitleSize, setCustomSubtitleSize] = useState();
    const [compType, setCompType] = useState();
    const [emoticonCoordList, setEmoticonCoordList] = useState([]);
    const [subtitleCoordList, setSubtitleCoordList] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [savingPop, setSavingPop] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [recordName, setRecordName] = useState("");
    const [mobilePanel, setMobilePanel] = useState();
    const [mobileRight, setMobileRight] = useState();
    const [safari, setSafari] = useState(false);


    
    
    const navigate = useNavigate();

    useEffect (() => {
        loadPreset();
    }, [presetNum]);

    useEffect(() => {
        setEmoticonScroll();
    }, [emoticonPreviewSrc])

    useEffect(() => {
        setTimeout(() => {
            setSubtitleScroll();
        }, 10);
    }, [subtitleColor, subtitleContent, subtitleFont, subtitleSize])

    useEffect(() => {
        const size = Math.round(Number(emoticonHeight) * Number(emoticonRatio) * videoRef.current.clientHeight / 720 / 100).toString();
        setEmoticonSize(size);
        setTimeout(() => {
            setEmoticonScroll();
        }, 10);
    }, [emoticonHeight, emoticonRatio])

    useEffect(() => {
        const size = Math.round(Number(customSubtitleHeight) * Number(customSubtitleRatio) * videoRef.current.clientHeight / 720 / 100).toString();
        setCustomSubtitleSize(size);
        setTimeout(() => {
            setCustomSubtitleScroll();
        }, 10);
    }, [customSubtitleHeight, customSubtitleRatio])

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isSafari = userAgent.includes('safari') && !userAgent.includes('chrome');
        setSafari(isSafari);

        loadData();
        loadEmoticonList();
        loadCustomEmoticonList();
        loadCustomSubtitleList();
        loadSubtitleFont();
        loadEmoticonCoord();
        loadSubtitleCoord();
        

        setInterval(() => {
            loadData();
        }, 5000);
    }, []);

    useEffect(() => {
        if(data){
            let count = data.participantCnt;

            for(let i = 0; i < data.partlist.length; i++){
                if(data.partlist[i].devName == "ECB"){
                    count -= 1;
                }
            }
            setCount(count);

            loadPartList();

            if(isFirstValueLoaded == false){
                setSubtitleSize(data.subtitleSize);
                setSubtitleColor(data.subtitleColor);
                setIsFirstValueLoaded(true);
            }
            setCompType(data.compType);
        }
    }, [data]);

    async function loadEmoticonCoord(){
        const postData = {
            "ip": ip,
            "uid": uid
        };

        try{
            const res = await axios.post(action + "GetEmoticonCoordsAction", JSON.stringify(postData), headers());
            let coordList = new Array;
            for(let i = 0; i < res.data.subtitle.length; i++){
                coordList.push({"x": res.data.subtitle[i].xCoord, "y": res.data.subtitle[i].yCoord, "idx": res.data.subtitle[i].idx, "id": res.data.subtitle[i].id});
            }
            setEmoticonCoordList(coordList);
        }catch(ex){
            console.log(ex);
        }
        
    }

    async function loadSubtitleCoord(){
        const postData = {
            "ip": ip,
            "uid": uid
        };

        try{
            const res = await axios.post(action + "GetSubtitleCoordsAction", JSON.stringify(postData), headers());
            let coordList = new Array;
            for(let i = 0; i < res.data.subtitle.length; i++){
                coordList.push({"x": res.data.subtitle[i].xCoord, "y": res.data.subtitle[i].yCoord, "idx": res.data.subtitle[i].idx});
            }
            setSubtitleCoordList(coordList);
        }catch(ex){
            console.log(ex);
        }
        
    }

    function loadSubtitleFont(){
        let subtitleFontList = new Array();
        subtitleFontList.push({"name": "고딕", "value": 0});
        subtitleFontList.push({"name": "바른펜", "value": 1});
        subtitleFontList.push({"name": "명조", "value": 2});
        subtitleFontList.push({"name": "펜스크립트", "value": 3});
        subtitleFontList.push({"name": "스퀘어라운드", "value": 4});

        setSubtitleFontList(subtitleFontList);
    }

    function loadEmoticonList(){
        let emoticonList = new Array;
        for(let i = 1; i <= 225; i++){
            emoticonList.push(i);
        }
        setEmoticonList(emoticonList);
    }

    async function loadCustomEmoticonList(){
        const postData = {
            "ip": ip
        }

        try{
            const res = await axios.post(action + "LoadCustomEmoticonAction", JSON.stringify(postData), headers());

            let customEmoticonList = new Array;

            if(res.data){
                for(let i = 0; i < res.data.custom.length; i++){
                    customEmoticonList.push(res.data.custom[i].name);
                }
            }

            setCustomEmoticonList(customEmoticonList);
        }catch(ex){
            console.log(ex);
        }
    }

    async function loadCustomSubtitleList(){
        const postData = {
            "ip": ip
        }

        try{
            const res = await axios.post(action + "LoadCustomSubtitleAction", JSON.stringify(postData), headers());

            let customSubtitleList = new Array;

            if(res.data){
                for(let i = 0; i < res.data.custom.length; i++){
                    customSubtitleList.push(res.data.custom[i].name);
                }
            }

            setCustomSubtitleList(customSubtitleList);
        }catch(ex){
            console.log(ex);
        }
    }

    function loadPartList(){
        let prePartList = data.partlist;
        prePartList = prePartList.filter(item => item.devName !== "ECB");
        if(prePartList.length == 0){
            setIsAllMuted(false);
        }else{
            for(let i = 0; i < prePartList.length; i++){
                if(prePartList[i].amute == "false"){
                    setIsAllMuted(false);
                    break;
                }else{
                    setIsAllMuted(true);
                }
            }
            for(let i = 0; i< prePartList.length; i++){
                if(prePartList[i].ec == "20"){
                    setIsAllEcho(false);
                    break;
                }else{
                    setIsAllEcho(true);
                }
            }
        }

        setPartList(prePartList);
    }

    async function loadData() {
        const postData = {
            "ip": ip,
            "uid": uid
        };

        try{
            const res = await axios.post(action + "LoadControlDataAction", JSON.stringify(postData), headers());
            setData(res.data);
        } catch (ex){
            console.log(ex);
        }

    }
    
    async function loadPreset(){
        try{
            const postData = {
                "num": presetNum
            }
            const res = await axios.post(action + "LoadPresetAction", JSON.stringify(postData), headers());
            setPreset(res.data);
        }catch(ex){
            console.log(ex)
        }
    }

    function handleSubtitlePreset(num){
        if(num == 1){
            setSubtitleContent(subPresetOneRef.current.value);
        }else if(num == 2){
            setSubtitleContent(subPresetTwoRef.current.value);
        }else if(num == 3){
            setSubtitleContent(subPresetThreeRef.current.value);
        }
    }

    function handleImgOver(e){
        e.preventDefault();
    }

    async function saveRecording(){
        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        const videos = videoRef.current.querySelectorAll("video");
        let videoObj;
        videos.forEach(video => {
            videoObj = video;
        }); 
        const video = videoObj.srcObject.getVideoTracks()[0].clone();
        const audio = videoObj.srcObject.getAudioTracks()[0].clone();
        videoObj.srcObject.getTracks().forEach(track => track.stop());
        videoObj.srcObject = null;

        const stream = new MediaStream();
        stream.addTrack(video);
        stream.addTrack(audio);
        videoObj.srcObject = stream;
        videoObj.play();

        const formData = new FormData();
        formData.append('video', blob);
        formData.append('ip', ip);
        formData.append("name", recordName);
        
        handleSaving(true);
        await axios.post(action + "UploadVideoAction", formData, { 
            headers: {
                "Authorization": 'Bearer ' + sessionStorage.getItem("token")
            },
            timeout: 1000000,
            onUploadProgress: progressEvent => {
                const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percent);
            }
        });
        alert("저장이 완료되었습니다!");
        handleSaving(false);
        setIsRecording(false);
        setRecordedChunks([]);
    }

    async function handleRecording(){
        const videos = videoRef.current.querySelectorAll("video");
        let videoObj;
        videos.forEach(video => {
            videoObj = video.srcObject;
        });
        if(videoObj){
            mediaRecorderRef.current = new MediaRecorder(videoObj, { mimeType: 'video/webm; codecs=vp8', videoBitsPerSecond: 3000000 });
        }else{
            return false;
        }

        if(isRecording){
            const postData ={
                "name": recordName
            };
            await axios.post(action + "CheckVideoNameAction", JSON.stringify(postData), headers()).then((res) => {
                if(res.data == "True"){
                    saveRecording();
                }else if(res.data == "False"){
                    alert("이름이 중복됩니다!");
                }
            });
        }else{
            mediaRecorderRef.current.start(1000);        

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setIsRecording(true);
                    setRecordedChunks((prev) => [...prev, event.data]);
                    console.log("progress...");
                }
            };
        }
    }

    function handleFullScreen(){
        const videos = videoRef.current.querySelectorAll("video");
        videos.forEach(video => {
            if (video) {
                if (video.requestFullscreen) {
                  video.requestFullscreen();
                } else if (video.mozRequestFullScreen) { /* Firefox */
                  video.mozRequestFullScreen();
                } else if (video.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                  video.webkitRequestFullscreen();
                } else if (video.msRequestFullscreen) { /* IE/Edge */
                  video.msRequestFullscreen();
                }
            }
        });
        
    }

    function handleImgDrag(e, type, item){
        if(type == "emoticon"){
            e.dataTransfer.setData("item", item);
            e.dataTransfer.setData("type", "emoticon");
        }else if(type == "customEmoticon"){
            e.dataTransfer.setData("item", item);
            e.dataTransfer.setData("type", "customEmoticon");
        }else if(type == "emoticonMove"){
            e.dataTransfer.setData("idx", e.target.getAttribute("data-idx"));
            e.dataTransfer.setData("item", e.target.getAttribute("data-num"));
            e.dataTransfer.setData("type", "emoticonMove");
        }else if(type == "subtitle"){
            e.dataTransfer.setData("type", "subtitle");
        }else if(type == "subtitleMove"){
            e.dataTransfer.setData("idx", e.target.getAttribute("data-idx"));
            e.dataTransfer.setData("type", "subtitleMove");
        }else if(type == "customSubtitle"){
            e.dataTransfer.setData("item", item);
            e.dataTransfer.setData("type", "customSubtitle");
        }
    }

    async function handleImgDrop(e){
        e.preventDefault();

        const item = e.dataTransfer.getData("item");
        let type = e.dataTransfer.getData("type");

        if(type == "emoticonMove"){
            if(item.startsWith("sub")){
                type = "customSubtitleMove";
            }
        }

        const offsetX = e.clientX - e.target.getBoundingClientRect().left;
        const offsetY = e.clientY - e.target.getBoundingClientRect().top;

        const x = Math.round((offsetX / videoRef.current.clientWidth) * 100);
        const y = Math.round((offsetY / videoRef.current.clientHeight) * 100);

        if(type == "emoticon"){
            if(item){
                const size = emoticonRatio;
                const postData = {
                    "ip": ip,
                    "uid": uid,
                    "x": x,
                    "y": y,
                    "num": item,
                    "size": size,
                    "id": item
                }
                try{
                    await axios.post(action + "SendDefaultEmoticonAction", JSON.stringify(postData), headers());
                    loadData();
                    loadEmoticonCoord();
                }catch(ex){
                    console.log(ex);
                }
            }
        }else if(type == "customEmoticon"){
            if(item){
                const size = emoticonRatio;
                const postData = {
                    "ip": ip,
                    "uid": uid,
                    "x": x,
                    "y": y,
                    "num": item,
                    "size": size,
                    "id": item
                }
                try{
                    await axios.post(action + "SendCustomEmoticonAction", JSON.stringify(postData), headers());
                    loadData();
                    loadEmoticonCoord();
                }catch(ex){
                    console.log(ex);
                }
            }
        }else if(type == "emoticonMove"){
            if(item){
                let type;
                if(isNaN(item)){
                    type = "custom";
                }else{
                    type = "default";
                }

                const postData = {
                    "ip": ip,
                    "uid": uid,
                    "x": x,
                    "y": y,
                    "num": item,
                    "id": item,
                    "idx": e.dataTransfer.getData("idx"),
                    "type": type
                };
                try{
                    await axios.post(action + "MoveEmoticonAction", JSON.stringify(postData), headers());
                    loadData();
                    loadEmoticonCoord();
                }catch(ex){
                    console.log(ex);
                }
            }
        }else if(type == "subtitle"){
            const postData = {
                "ip": ip,
                "uid": uid,
                "x": x,
                "y": y,
                "sub": subtitleContent,
                "size": subtitleSize,
                "color": subtitleColor,
                "font": subtitleFontVal
            }
            try{
                await axios.post(action + "SendSubtitleAction", JSON.stringify(postData), headers());
                loadData();
                loadSubtitleCoord();
            }catch(ex){
                console.log(ex);
            }
        }else if(type == "subtitleMove"){
            const postData = {
                "ip": ip,
                "uid": uid,
                "x": x,
                "y": y,
                "idx": e.dataTransfer.getData("idx")
            }
            try{
                await axios.post(action + "MoveSubtitleAction", JSON.stringify(postData), headers());
                loadData();
                loadSubtitleCoord();
            }catch(ex){
                console.log(ex);
            }
        }else if(type == "customSubtitle"){
            if(item){
                const size = customSubtitleRatio;
                const postData = {
                    "ip": ip,
                    "uid": uid,
                    "x": x,
                    "y": y,
                    "num": item,
                    "size": size,
                    "id": item
                }
                try{
                    await axios.post(action + "SendCustomSubtitleAction", JSON.stringify(postData), headers());
                    loadData();
                    loadEmoticonCoord();
                }catch(ex){
                    console.log(ex);
                }
            }
        }else if(type == "customSubtitleMove"){
            if(item){
                const postData = {
                    "ip": ip,
                    "uid": uid,
                    "x": x,
                    "y": y,
                    "num": item,
                    "id": item,
                    "idx": e.dataTransfer.getData("idx"),
                    "type": type
                };
                try{
                    await axios.post(action + "MoveEmoticonAction", JSON.stringify(postData), headers());
                    loadData();
                    loadEmoticonCoord();
                }catch(ex){
                    console.log(ex);
                }
            }
        }
    }

    function handleHideState(state) {
        setHideState(state);
    };

    function handleEmoticonRatio(e){
        if(e){
            setEmoticonRatio(e.target.value);
        }
    }

    function handleCustomSubtitleRatio(e){
        if(e){
            setCustomSubtitleRatio(e.target.value);
        }
    }

    function handleSubtitleContent(e){
        setSubtitleContent(e.target.value);
    }

    function handleSubtitleSize(e){
        setSubtitleSize(e.target.value);
    }

    function handleSubtitleFont(e){
        let value = e.target.value;
        let font;
        if(value == 0){
            font = "NanumGothicBold";
        }else if(value == 1){
            font = "NanumBarunpenB";
        }else if(value == 2){
            font = "NanumMyeongjoExtraBold";
        }else if(value == 3){
            font = "NanumPen";
        }else if(value == 4){
            font = "NanumSquareRoundEB";
        }

        setSubtitleFont(font);
        setSubtitleFontVal(value);
    }

    function handleSubtitleColor(e){
        setSubtitleColor(e.target.value);
    }

    function handleCustomSubtitleRatio(e){
        setCustomSubtitleRatio(e.target.value);
    }

    function handleTabMenu(menu){
        if(menu == "emoticon"){
            setTimeout(() => {
                setEmoticonScroll();
            }, 10);
        }else if(menu == "subtitle"){
            setTimeout(() => {
                setCustomSubtitleScroll();
            }, 10);
        }
        
        setTabMenu(menu);

    }

    function handlePresetNum(num){
        setPresetNum(num);
    }


    function handleDragStart(e, control){
        e.dataTransfer.setData("control", control);
        e.dataTransfer.setData("param", "control");
    }
    function handleDragOver(e, area){
        if(area == "area1"){
            panel1.current.classList.add("panel-drag-over");
        }else if(area == "area2"){
            panel2.current.classList.add("panel-drag-over");
        }else if(area == "area3"){
            panel3.current.classList.add("panel-drag-over");
        }else if(area == "area4"){
            panel4.current.classList.add("panel-drag-over");
        }else if(area == "area5"){
            panel5.current.classList.add("panel-drag-over");
        }
        e.preventDefault();

    }
    function handleDragLeave(e, area){
        e.preventDefault();

        if(area == "area1"){
            panel1.current.classList.remove("panel-drag-over");
        }else if(area == "area2"){
            panel2.current.classList.remove("panel-drag-over");
        }else if(area == "area3"){
            panel3.current.classList.remove("panel-drag-over");
        }else if(area == "area4"){
            panel4.current.classList.remove("panel-drag-over");
        }else if(area == "area5"){
            panel5.current.classList.remove("panel-drag-over");
        }
    }
    async function handleDrop(e, area){
        e.preventDefault();
        const control = e.dataTransfer.getData("control");
        const param = e.dataTransfer.getData("param");
        if(param === "control"){
            const postData = {
                "control": control,
                "num": presetNum,
                "area": area
            }

            try {
                const res = await axios.post(action + "LoadPresetAction", JSON.stringify(postData), headers());

                if(control == res.data.area1 || control == res.data.area2 || control == res.data.area3 || control == res.data.area4 || control == res.data.area5){
                    return false;
                }

                await axios.post(action + "UpdatePresetAction", JSON.stringify(postData), headers());
                await loadPreset();
            }catch(ex){
                console.log(ex);
            }
        }
        if(area == "area1"){
            panel1.current.classList.remove("panel-drag-over");
        }else if(area == "area2"){
            panel2.current.classList.remove("panel-drag-over");
        }else if(area == "area3"){
            panel3.current.classList.remove("panel-drag-over");
        }else if(area == "area4"){
            panel4.current.classList.remove("panel-drag-over");
        }else if(area == "area5"){
            panel5.current.classList.remove("panel-drag-over");
        }
    }

    async function exitToRoom(){
        const postData = {
            "ip": ip
        };
        try{
            const pre = await axios.post(page + "RoomPage", JSON.stringify(postData), headers());
            navigate("/Room/", {state : pre.data});
        }catch{
            // sessionStorage.clearItem("token");
            // navigate("/Login");
        }
    }

    async function exitToServer(){
        try{
            const pre = await axios.post(page + "ServerPage", "", headers());
            navigate("/Server", {state: pre.data});
        }catch{
            // sessionStorage.clearItem("token");
            // navigate("/Login");
        }
    }

    async function changeSlot(partid, slot){
        if(data){
            for(let i = 0; i < data.tail.length; i++){
                if(data.tail[i].partid == partid){
                    if(data.tail[i].hide == "true"){
                        const postData = {
                            "ip": ip,
                            "uid": uid,
                            "num": data.tail[i].tailNum,
                            "hide": "false"
                        };

                        try{
                            await axios.post(action + "HideAction", JSON.stringify(postData), headers());
                            await axios.post(action + "TailFreeAction", JSON.stringify(postData), headers());
                        }catch(ex){
                            console.log(ex);
                        }
                    }
                }
            }
        }

        const postData = {
            "ip": ip,
            "uid": uid,
            "partid": partid,
            "slot": slot
        };

        try{
            await axios.post(action + "ChangeSlotAction", JSON.stringify(postData), headers());
            loadData();
        }catch(ex){
            console.log(ex);
        }
    }

    async function changeEcho(partid, curEcho){
        let echo;
        if(curEcho == "9"){
            return false;
        }else if(curEcho == "23"){
            echo = "0";
        }else{
            echo = "3";
        }

        const postData = {
            "ip": ip,
            "uid": uid,
            "partid": partid,
            "ec": echo
        };

        try{
            await axios.post(action + "ChangeEchoAction", JSON.stringify(postData), headers());
            loadData();
        }catch(ex){
            console.log(ex);
        }
    }

    async function changeMute(partid, curState){
        let mute;
        if(curState == "true"){
            mute = false;
        }else{
            mute = true;
        }

        const postData = {
            "ip": ip,
            "uid": uid,
            "partid": partid,
            "mute": mute
        };

        try{
            await axios.post(action + "ChangeMuteAction", JSON.stringify(postData), headers());
            loadData();
        }catch(ex){
            console.log(ex);
        }
    }

    async function changeMuteAll(state){
        const postData = {
            "ip": ip,
            "uid": uid,
            "state": state
        };

        try{
            await axios.post(action + "ChangeMuteAllAction", JSON.stringify(postData), headers());
            loadData();
        }catch(ex){
            console.log(ex);
        }
    }

    async function changeEchoAll(state){
        let ec;
        if(state == "true"){
            ec = "3";
        }else{
            ec = "0";
        }
        
        const postData = {
            "ip": ip,
            "uid": uid,
            "partid": "101",
            "ec": ec
        };

        try{
            await axios.post(action + "ChangeEchoAction", JSON.stringify(postData), headers());
            loadData();
        }catch(ex){
            console.log(ex);
        }
    }

    function handleEmoticonUpload(){
        emoticonUploadRef.current.click();
    }

    function handleCustomSubtitleUpload(){
        customSubtitleUploadRef.current.click();
    }

    async function emoticonUpload(e){
        e.preventDefault();

        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("ip", ip);

        try{
            e.preventDefault();
            await axios.post(action + "UploadEmoticonAction", formData, headers());
            loadCustomEmoticonList();
        }catch(ex){
            console.log(ex);            
        }
    }

    async function customSubtitleUpload(e){
        e.preventDefault();

        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("ip", ip);

        try{
            e.preventDefault();
            await axios.post(action + "UploadCustomSubtitleAction", formData, headers());
            loadCustomSubtitleList();
        }catch(ex){
            console.log(ex);            
        }
    }

    async function getImgSize(e, type, num){
        let src;
        const height = e.target.naturalHeight;

        if(type == "defaultEmo"){
            src = require("Img/emoticon-" + num + ".png");
            setEmoticonPreviewSrc(src);
            setEmoticonHeight(height);
        }else if(type == "customEmo"){
            src = root + "emoticon/" + num + ".png";
            setEmoticonPreviewSrc(src);
            setEmoticonHeight(height);
        }else if(type == "customSub"){
            src = root + "subtitle/" + num + ".png";
            setCustomSubtitlePreviewSrc(src);
            setCustomSubtitleHeight(height);
        }
    }
    
    function setEmoticonScroll(){
        const preview = emoticonPreviewRef.current;

        if(preview){
            preview.scrollLeft = (preview.scrollWidth - preview.clientWidth) / 2;
            preview.scrollTop = (preview.scrollHeight - preview.clientHeight) / 2;
        }
    }

    function setSubtitleScroll(){
        const preview = subtitlePreviewRef.current;

        if(preview){
            preview.scrollLeft = (preview.scrollWidth - preview.clientWidth) / 2;
            preview.scrollTop = (preview.scrollHeight - preview.clientHeight) / 2;
        }
    }

    function setCustomSubtitleScroll(){
        const preview = customSubtitlePreviewRef.current;

        if(preview){
            preview.scrollLeft = (preview.scrollWidth - preview.clientWidth) / 2;
            preview.scrollTop = (preview.scrollHeight - preview.clientHeight) / 2;
        }
    }

    function handleEmoticonRemove(){
        setIsEmoticonRemoveOn(!isEmoticonRemoveOn);
    }

    function handleCustomSubtitleRemove(){
        setIsCustomSubtitleRemoveOn(!isCustomSubtitleRemoveOn);
    }

    async function removeImg(type, name){
        const postData = {
            "type": type,
            "name": name
        };

        console.log(postData);

        try{
            await axios.post(action + "RemoveCustomImgAction", JSON.stringify(postData), headers());
            loadCustomEmoticonList();
            loadCustomSubtitleList();
        }catch(ex){
            console.log(ex);
        }
    }

    async function clearItem(e, type){
        const postData = {
            "ip": ip,
            "uid": uid,
            "x": "-1",
            "type": type,
            "idx": e.target.getAttribute("data-idx")
        };

        try{
            await axios.post(action + "ClearItemAction", JSON.stringify(postData), headers());
            loadEmoticonCoord();
        }catch(ex){
            console.log(ex);
        }
    }

    function handleSaving(state){
        setSavingPop(state);
    }

    function handleRecordName(e){
        let value = e.target.value;
        value = value.replace(/\s/g, '');
        setRecordName(value);
    }

    function handleMobilePanel(e){
        setMobilePanel(e.target.value);
    }

    function handleMobileRight(){
        setMobileRight(!mobileRight);
    }

    return(
        <div className="control-container">
            {savingPop && (
                <Popup param={"saving"} action={handleSaving} uploadProgress={uploadProgress}></Popup>
            )}
            <RefreshToken></RefreshToken>
            <div className="control-left-menu">
                <div className="control-logo" onClick={exitToServer}>
                    <img src={require("Img/logo-01.png")}></img>
                </div>
                <ul className="control-left-items">
                    <li draggable onDragStart={(e) => handleDragStart(e, "layout")}>
                        <div className="item-img">
                            <img className="item-off" src={require("Img/layout-change-off.png")}></img>
                            <img className="item-on" src={require("Img/layout-change-on.png")}></img>
                        </div>
                        <h2>레이아웃 변경</h2>
                    </li>
                    <li draggable onDragStart={(e) => handleDragStart(e, "prefer")}>
                        <div className="item-img">
                            <img className="item-off" src={require("Img/layout-off.png")}></img>
                            <img className="item-on" src={require("Img/layout-on.png")}></img>
                        </div>
                        <h2>선호 레이아웃</h2>
                    </li>
                    <li draggable onDragStart={(e) => handleDragStart(e, "slot")}>
                        <div className="item-img">
                            <img className="item-off" src={require("Img/slot-off.png")}></img>
                            <img className="item-on" src={require("Img/slot-on.png")}></img>
                        </div>
                        <h2>슬롯 컨트롤</h2>
                    </li>
                    <li draggable onDragStart={(e) => handleDragStart(e, "mic")}>
                        <div className="item-img">
                            <img className="item-off" src={require("Img/mic-off.png")}></img>
                            <img className="item-on" src={require("Img/mic-on.png")}></img>
                        </div>
                        <h2>마이크</h2>
                    </li>
                    <li draggable onDragStart={(e) => handleDragStart(e, "camera")}>
                        <div className="item-img">
                            <img className="item-off" src={require("Img/camera-off.png")}></img>
                            <img className="item-on" src={require("Img/camera-on.png")}></img>
                        </div>
                        <h2>카메라</h2>
                    </li>
                    <li draggable onDragStart={(e) => handleDragStart(e, "text")}>
                        <div className="item-img">
                            <img className="item-off" src={require("Img/text-off.png")}></img>
                            <img className="item-on" src={require("Img/text-on.png")}></img>
                        </div>
                        <h2>텍스트</h2>
                    </li>
                    <li draggable onDragStart={(e) => handleDragStart(e, "etc")}>
                        <div className="item-img">
                            <img className="item-off" src={require("Img/etc-off.png")}></img>
                            <img className="item-on" src={require("Img/etc-on.png")}></img>
                        </div>
                        <h2>기타 컨트롤</h2>
                    </li>
                </ul>
            </div>
            <div className="control-middle">
                <div className="control-header">
                    <div className="control-header-left">
                        {name && did && (
                            <>
                                <h2>{name}</h2>
                                <h2>{did}</h2>
                            </>
                        )}
                        <div className="room-info">
                            <img src={require("Img/users-icon.png")}></img>
                            <span>{count}</span>
                        </div>
                    </div>
                    <div className="control-header-right">
                        <button onClick={exitToRoom}>
                            <img src={require("Img/exit-icon.png")}></img>
                            나가기
                        </button>
                    </div>
                </div>
                <div className="control-utils">
                    <div className="control-utils-left">
                        {!safari && (
                            <>
                            <button onTouchStart={handleFullScreen} onClick={handleFullScreen}>
                                <img src={require("Img/fullscreen-icon.png")}></img>
                                Full Screen
                            </button>
                            <button className={isRecording ? "active" : ""} onClick={handleRecording}>
                                <img src={isRecording ? require("Img/recording-icon.png") : require("Img/recording-icon-white.png")}></img>
                                Recording
                            </button>
                            <input type="text" placeholder="녹화 영상 제목" value={recordName} onChange={handleRecordName}></input>
                            </>
                        )}
                    </div>
                    <div className="control-utils-right">
                        <button className={presetNum == 1 ? "active" : ""} onClick={() => handlePresetNum("1")}>1</button>
                        <button className={presetNum == 2 ? "active" : ""} onClick={() => handlePresetNum("2")}>2</button>
                    </div>
                    <div className="control-utils-mobile-right">
                        <button onClick={handleMobileRight}>
                            <img src={require("Img/right-menu-arrow-on.png")}></img>
                        </button>
                    </div>
                </div>
                <div className="control-panels">
                    <div className="control-panels-left">
                        <div ref={videoRef} className="panel-screen" onDragOver={handleImgOver} onDrop={(e) => handleImgDrop(e)}>
                        {emoticonCoordList && (
                            emoticonCoordList.map((item) => (
                                <div className="item-overlay" data-idx={item.idx} data-num={item.id} style={{top: (Number(item.y) - 3) + "%", left: (Number(item.x) - 2) + "%"}} onClick={(e) => clearItem(e, "emoticon")} onDragStart={(e) => handleImgDrag(e, "emoticonMove")}></div>
                            ))
                        )}

                        {subtitleCoordList && (
                            subtitleCoordList.map((item) => (
                                <div className="item-overlay" data-idx={item.idx} style={{top: (Number(item.y) - 3) + "%", left: (Number(item.x) - 2) + "%"}} onClick={(e) => clearItem(e, "subtitle")} onDragStart={(e) => handleImgDrag(e, "subtitleMove")}></div>
                            ))
                        )}

                        {data && did && (
                            <VideoClent preData={data} did={did} compType={compType}></VideoClent>
                        )}
                        </div>
                        <div className="control-panels-mobile">
                            <select class="mobile-panel-select" onChange={handleMobilePanel}>
                                <option hidden>선택</option>
                                <option value="layout">레이아웃 변경</option>
                                <option value="prefer">선호 레이아웃</option>
                                <option value="slot">슬롯 컨트롤</option>
                                <option value="mic">마이크</option>
                                <option value="etc">기타 컨트롤</option>
                                <option value="camera">카메라</option>
                                <option value="text">텍스트</option>
                            </select>
                            {mobilePanel && (
                                <Panel isMobile={true} control={mobilePanel} area={"area1"} num={presetNum} loadData={loadData} loadPreset={loadPreset} preData={data} hideState={hideState} setHideState={handleHideState}></Panel>
                            )}
                        </div>
                        <div className="control-panels-bottom">
                            <div onDragOver={(e) => handleDragOver(e, "area1")} onDragLeave={(e) => handleDragLeave(e, "area1")} onDrop={(e) => handleDrop(e, "area1")} className="panel" ref={panel1}>
                                {preset.area1 && data && presetNum &&(
                                    <Panel control={preset.area1} area={"area1"} num={presetNum} loadData={loadData} loadPreset={loadPreset} preData={data} hideState={hideState} setHideState={handleHideState}></Panel>
                                )}
                            </div>
                            <div onDragOver={(e) => handleDragOver(e, "area2")} onDragLeave={(e) => handleDragLeave(e, "area2")} onDrop={(e) => handleDrop(e, "area2")} className="panel" ref={panel2}>
                                {preset.area2 && data && presetNum && (
                                    <Panel control={preset.area2} area={"area2"} num={presetNum} loadData={loadData} loadPreset={loadPreset} preData={data} hideState={hideState} setHideState={handleHideState}></Panel>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="control-panels-right">
                        <div onDragOver={(e) => handleDragOver(e, "area3")} onDragLeave={(e) => handleDragLeave(e, "area3")} onDrop={(e) => handleDrop(e, "area3")} className="panel" ref={panel3}>
                            {preset.area3 && data && presetNum && (
                                <Panel control={preset.area3} area={"area3"} num={presetNum} loadData={loadData} loadPreset={loadPreset} preData={data} hideState={hideState} setHideState={handleHideState}></Panel>
                            )}
                        </div>
                        <div onDragOver={(e) => handleDragOver(e, "area4")} onDragLeave={(e) => handleDragLeave(e, "area4")} onDrop={(e) => handleDrop(e, "area4")} className="panel" ref={panel4}>
                            {preset.area4 && data && presetNum && (
                                <Panel control={preset.area4} area={"area4"} num={presetNum} loadData={loadData} loadPreset={loadPreset} preData={data} hideState={hideState} setHideState={handleHideState}></Panel>
                            )}
                        </div>
                        <div onDragOver={(e) => handleDragOver(e, "area5")} onDragLeave={(e) => handleDragLeave(e, "area5")} onDrop={(e) => handleDrop(e, "area5")} className="panel" ref={panel5}>
                            {preset.area5 && data && presetNum &&(
                                <Panel control={preset.area5} area={"area5"} num={presetNum} loadData={loadData} loadPreset={loadPreset} preData={data} hideState={hideState} setHideState={handleHideState}></Panel>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div style={mobileRight ? {display: "block"} : {}} className="control-right-menu">
                <ul className="control-tab-box">
                    <li className={tabMenu == "participant" ? "" : "inactive"} onClick={() => handleTabMenu("participant")}>참여자</li>
                    {!mobileRight && (
                        <>
                        <li className={tabMenu == "emoticon" ? "" : "inactive"} onClick={() => handleTabMenu("emoticon")}>이모티콘</li>
                        <li className={tabMenu == "subtitle" ? "" : "inactive"} onClick={() => handleTabMenu("subtitle")}>자막</li>
                        </>
                    )}
                    {mobileRight && (
                        <li className="inactive">
                            <button className="mobile-right-close" onClick={handleMobileRight}>
                                <img src={require("Img/right-menu-arrow-off.png")}></img>
                            </button>
                        </li>
                    )}
                </ul>
                <div className="control-tab-content">
                    {tabMenu == "participant" && (
                        <>
                            <div className="control-tab-content-title">
                                <div className="tab-title-left">
                                    <h3>참여자 수</h3>
                                    <span>{count}</span>
                                </div>
                                <div className="tab-title-right">
                                    <div className="list-util">
                                        <img src={isAllEcho ? require("Img/echo-off.png") : require("Img/echo-on.png")} onClick={isAllEcho ? () => changeEchoAll("false") : () => changeEchoAll("true")}></img>
                                    </div>
                                    <div className="list-util">
                                        <img src={isAllMuted ? require("Img/microphone-off.png") : require("Img/microphone-on.png")} onClick={isAllMuted ? () => changeMuteAll("false") : () => changeMuteAll("true")}></img>
                                    </div>
                                </div>
                            </div>
                            <ul className="user-list">
                                {partList && (
                                    partList.map((item) => (
                                        <li key={item.partid}>
                                            <h2 className={item.isRed == "true" ? "user-red" : ""}>{item.devName}</h2>
                                            <div className="user-list-right">
                                                <div className="list-util">
                                                    <img src={item.isOne == "true" ? require("Img/number1-on.png") : require("Img/number1-off.png")} onClick={() => changeSlot(item.partid, 0)}></img>
                                                </div>
                                                <div className="list-util">
                                                    <img src={item.isTwo == "true" ? require("Img/number2-on.png") : require("Img/number2-off.png")} onClick={() => changeSlot(item.partid, 1)}></img>
                                                </div>
                                                <div className="list-util">
                                                    <img src={item.ec == "23" ? require("Img/echo-off.png") : item.ec == "9" ? require("Img/echo-disable.png") : require("Img/echo-on.png")} onClick={() => changeEcho(item.partid, item.ec)}></img>
                                                </div>
                                                <div className="list-util">
                                                    <img src={Number(item.partVAD) >= 800 && Number(item.partVAD) && item.amute == "false" < 1500 ? require("Img/microphone-800.png") : 
                                                        Number(item.partVAD) >= 1500 && Number(item.partVAD) && item.amute == "false" < 4000 ? require("Img/microphone-1500.png") :
                                                        Number(item.partVAD) >= 4000 && item.amute == "false" ? require("Img/microphone-4000.png") : item.amute == "true" ? require("Img/microphone-off.png") :require("Img/microphone-on.png")
                                                    } onClick={() => changeMute(item.partid, item.amute)}></img>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                )}
                            </ul>
                        </>
                    )}
                    {tabMenu == "emoticon" && (
                        <div className="emoticon-container">
                            <div className="emoticon-container-top">
                                <ul className="emoticon-list">
                                    {emoticonList && (
                                        emoticonList.map((item, index) => (
                                            <li draggable="true" key={index} onDragStart={(e) => handleImgDrag(e, "emoticon", item)}>
                                                <img draggable="false" src={require("Img/emoticon-" + item + ".png")} onClick={(e) => getImgSize(e, "defaultEmo", item)}></img>
                                            </li>
                                        ))
                                    )}
                                    {customEmoticonList && (
                                        customEmoticonList.map((item, index) => (
                                            <li draggable="true" key={index} onDragStart={(e) => handleImgDrag(e, "customEmoticon", item)}>
                                                <img className={isEmoticonRemoveOn ? "remove-on" : "" } draggable="false" src={root + "emoticon/" + item + ".png"} onClick={isEmoticonRemoveOn ? (e) => removeImg("customEmo", item) : (e) => getImgSize(e, "customEmo", item)}></img>
                                            </li>
                                        ))
                                    )}
                                </ul>
                                <div className="tab-border"></div>
                                <div className="emoticon-preview" ref={emoticonPreviewRef}>
                                    {emoticonPreviewSrc && (
                                        <img draggable="false" src={emoticonPreviewSrc} style={{height: isNaN(Number(emoticonSize)) ? 37 : Number(emoticonSize)}}></img>
                                    )}
                                </div>
                                <div className="tab-border"></div>
                                <div className="emoticon-size">
                                    <input className="emoticon-range" type="range" max={200} min={10} value={emoticonRatio} onChange={handleEmoticonRatio}></input>
                                    <input className="emoticon-value" type="number" value={emoticonRatio} onChange={handleEmoticonRatio}></input>
                                </div>
                             </div>
                             <div className="tab-border"></div>
                             <div className="item-custom">  
                                <button onClick={handleEmoticonUpload}>업로드</button>
                                <input ref={emoticonUploadRef} type="file" style={{display: "none"}} onChange={emoticonUpload} accept=".png"></input>
                                <button className={isEmoticonRemoveOn ? "active" : ""} onClick={handleEmoticonRemove}>삭제</button>
                             </div>
                        </div>
                    )}
                    {tabMenu == "subtitle" && (
                        <div className="subtitle-container">
                            <div className="subtitle-container-top">
                                <div className="subtitle-basic">
                                    <div className="subtitle-drag" onDragStart={(e) => handleImgDrag(e, "subtitle")}>
                                        <div draggable>
                                            <img draggable="false" src={require("Img/subtitle-btn.png")}></img>
                                        </div>
                                    </div>
                                    <div className="subtitle-input">
                                        <input type="text" value={subtitleContent} onChange={handleSubtitleContent} placeholder="자막 입력 후 화살표를 화면으로 드래그"></input>
                                    </div>
                                    <div className="subtitle-config">
                                        <div className="subtitle-config-left">
                                            <div className="subtitle-size">
                                                <h6>크기</h6>
                                                <input type="number" onChange={handleSubtitleSize} value={subtitleSize}></input>
                                            </div>
                                        </div>
                                        <div className="subtitle-config-right">
                                            <div className="subtitle-font">
                                                <h6>글꼴</h6>
                                                <select onChange={handleSubtitleFont}>
                                                {subtitleFontList && (
                                                    subtitleFontList.map((item, index) => (
                                                        <option key={index} value={item.value}>{item.name}</option>
                                                    ))
                                                )}
                                                </select>
                                            </div>
                                            <div className="subtitle-color">
                                                <h6>색</h6>
                                                <input type="color" value={subtitleColor} onChange={handleSubtitleColor}></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-border"></div>
                                <div ref={subtitlePreviewRef} className="subtitle-preview">
                                    <span style={{fontFamily: subtitleFont, fontSize: Math.round(Number(subtitleSize) * videoRef.current.clientHeight / 720), color: subtitleColor} }>{subtitleContent}</span>
                                </div>
                                <div className="tab-border"></div>
                                <div className="subtitle-preset">
                                    <div className="subtitle-preset-item">
                                        <input ref={subPresetOneRef} type="text"></input>
                                        <button onClick={() => handleSubtitlePreset(1)}>▲</button>
                                    </div>
                                    <div className="subtitle-preset-item">
                                        <input ref={subPresetTwoRef} type="text"></input>
                                        <button onClick={() => handleSubtitlePreset(2)}>▲</button>
                                    </div>
                                    <div className="subtitle-preset-item">
                                        <input ref={subPresetThreeRef} type="text"></input>
                                        <button onClick={() => handleSubtitlePreset(3)}>▲</button>
                                    </div>
                                </div>
                                <div className="tab-border"></div>
                                <ul className="custom-subtitle-list">
                                    {customSubtitleList && (
                                        customSubtitleList.map((item, index) => (
                                            <li draggable="true" key={index} onDragStart={(e) => handleImgDrag(e, "customSubtitle", item)}>
                                                <img className={isCustomSubtitleRemoveOn ? "remove-on" : "" } draggable="false" src={root + "subtitle/" + item + ".png"} onClick={isCustomSubtitleRemoveOn ? (e) => removeImg("customSub", item) : (e) => getImgSize(e, "customSub", item)}></img>
                                            </li>
                                        ))
                                    )}
                                </ul>                        
                                <div className="tab-border"></div>
                                <div className="custom-subtitle-preview" ref={customSubtitlePreviewRef}>
                                    {customSubtitlePreviewSrc && (
                                        <img draggable="false" src={customSubtitlePreviewSrc} style={{height: isNaN(Number(customSubtitleSize)) ? 37 : Number(customSubtitleSize)}}></img>
                                    )}
                                </div>
                                <div className="tab-border"></div>
                                <div className="emoticon-size">
                                    <input className="emoticon-range" type="range" max={200} min={10} value={customSubtitleRatio} onChange={handleCustomSubtitleRatio}></input>
                                    <input className="emoticon-value" type="number" value={customSubtitleRatio} onChange={handleCustomSubtitleRatio}></input>
                                </div>
                                <div className="tab-border"></div>
                            </div>
                            <div className="item-custom">  
                                <button onClick={handleCustomSubtitleUpload}>업로드</button>
                                <input ref={customSubtitleUploadRef} type="file" style={{display: "none"}} onChange={customSubtitleUpload} accept=".png"></input>
                                <button className={isCustomSubtitleRemoveOn ? "active" : ""} onClick={handleCustomSubtitleRemove}>삭제</button>
                             </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ControlPage;