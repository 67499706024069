export function slotChangeClick(e, compType, videoRemote) {
    var moveXup;
    var moveXdown;
    var moveYup;
    var moveYdown;
    var movieWidth;
    var movieHeight;
    var num;
    var num2;
    var temp;
    var OnehalfWidth;
    var OnehalfHeight;
    var TwohalfHeight;
    var TwohalfWidth;
    var ThreehalfWidth;
    var ThreehalfHeight;
    var One_onehalfWidth;
    var FourhalfWidth;
    var FourhalfHeight;

    var SixDivideWidth;
    var SixDivideHeight;
    var EightDivideWidth;
    var EightDivideHeight;
    var NineDivideWidth;
    var NineDivideHeight;
    var TenDivideWidth;
    var TenDivideHeight;
    var ElevenDivideWidth;
    var ElevenDivideHeight;
    var TwelveDivideWidth;
    var TwelveDivideHeight;
    var SixteenDivideWidth;
    var SixteenDivideHeight;

    // moveXdown = e.pageX - $('#videoRemote').offset().left;
    // moveYdown = e.pageY - $('#videoRemote').offset().top;

    // movieWidth = $('#videoRemote').css('width').replace(/[^-\d\.]/g, '');
    // movieHeight = $('#videoRemote').css('height').replace(/[^-\d\.]/g, '');
    
    let rect = videoRemote.getBoundingClientRect();
    moveXdown = e.pageX - rect.left;
    moveYdown = e.pageY - rect.top;
    movieWidth = parseFloat(getComputedStyle(videoRemote).width);
    movieHeight = parseFloat(getComputedStyle(videoRemote).height);
    

    OnehalfWidth = movieWidth / 2;
    OnehalfHeight = movieHeight / 2;
    TwohalfHeight = movieHeight / 3;
    TwohalfWidth = movieWidth / 3;
    ThreehalfWidth = movieWidth / 4;
    ThreehalfHeight = movieHeight / 4;
    One_onehalfWidth = OnehalfWidth / 2;
    FourhalfWidth = movieWidth / 5;
    FourhalfHeight = movieHeight / 5;

    SixDivideWidth = movieWidth / 6;
    SixDivideHeight = movieHeight / 6;
    EightDivideWidth = movieWidth / 8;
    EightDivideHeight = movieHeight / 8;
    NineDivideWidth = movieWidth / 9;
    NineDivideHeight = movieHeight / 9;
    TenDivideWidth = movieWidth / 10;
    TenDivideHeight = movieHeight / 10;
    ElevenDivideWidth = movieWidth / 11;
    ElevenDivideHeight = movieHeight / 11;
    TwelveDivideWidth = movieWidth / 12;
    TwelveDivideHeight = movieHeight / 12;
    SixteenDivideWidth = movieWidth / 16;
    SixteenDivideHeight = movieHeight / 16;

    console.log(moveXdown);
    console.log(moveYdown);
    console.log(movieWidth);
    console.log(movieHeight);
    var mcuCompType = Number(compType);
    console.log(mcuCompType);
    switch (mcuCompType) {
        case 0: console.log("화면 1"); break;
        case 1:
            if (moveXdown < OnehalfWidth && moveYdown < OnehalfHeight) {
                num = 0;
            }
            else if (moveXdown > OnehalfWidth && moveYdown < OnehalfHeight) {
                num = 1;
            }
            else if (moveXdown < OnehalfWidth && moveYdown > OnehalfHeight) {
                num = 2;
            }
            else {
                num = 3;
            }
            break;
        case 2:
            if (moveXdown < TwohalfWidth && moveYdown < TwohalfHeight) {
                num = 0;
            }
            else if (moveXdown < TwohalfWidth * 2 && moveYdown < TwohalfHeight) {
                num = 1;
            }
            else if (moveXdown > TwohalfWidth * 2 && moveYdown < TwohalfHeight) {
                num = 2;
            }
            else if (moveXdown < TwohalfWidth && moveYdown < TwohalfHeight * 2) {
                num = 3;
            }
            else if (moveXdown < TwohalfWidth * 2 && moveYdown < TwohalfHeight * 2) {
                num = 4;
            }
            else if (moveXdown > TwohalfWidth * 2 && moveYdown < TwohalfHeight * 2) {
                num = 5;
            }
            else if (moveXdown < TwohalfWidth && moveYdown > TwohalfHeight * 2) {
                num = 6;
            }
            else if (moveXdown < TwohalfWidth * 2 && moveYdown > TwohalfHeight * 2) {
                num = 7;
            }
            else {
                num = 8;
            }
            break;
        case 3:
            if (moveXdown < OnehalfWidth && moveYdown < OnehalfHeight) {
                num = 0;
            }
            else if (moveXdown > OnehalfWidth && moveYdown < OnehalfHeight) {
                num = 1;
            }
            else if (moveXdown < OnehalfWidth && moveYdown > OnehalfHeight) {
                num = 2;
            }
            else if (moveXdown > OnehalfWidth && moveYdown > OnehalfHeight && moveXdown < OnehalfWidth + ThreehalfWidth && moveYdown < OnehalfHeight + ThreehalfHeight) {
                num = 3;
            }
            else if (moveXdown > OnehalfWidth && moveYdown > OnehalfHeight && moveXdown > OnehalfWidth + ThreehalfWidth && moveYdown < OnehalfHeight + ThreehalfHeight) {
                num = 4;
            }
            else if (moveXdown > OnehalfWidth && moveYdown > OnehalfHeight && moveXdown < OnehalfWidth + ThreehalfWidth && moveYdown > OnehalfHeight + ThreehalfHeight) {
                num = 5;
            }
            else {
                num = 6;
            }
            break;
        case 4:
            if (moveXdown < OnehalfWidth + One_onehalfWidth && moveYdown < ThreehalfHeight * 3) {
                num = 0;
            }
            else if (moveXdown > OnehalfWidth + One_onehalfWidth && moveYdown < ThreehalfHeight) {
                num = 1;
            }
            else if (moveXdown > OnehalfWidth + One_onehalfWidth && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 2;
            }
            else if (moveXdown > OnehalfWidth + One_onehalfWidth && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 3;
            }
            else if (moveXdown < OnehalfWidth - One_onehalfWidth && moveYdown > ThreehalfHeight * 3) {
                num = 4;
            }
            else if (moveXdown > OnehalfWidth - One_onehalfWidth && moveXdown < OnehalfWidth && moveYdown > ThreehalfHeight * 3) {
                num = 5;
            }
            else if (moveXdown > OnehalfWidth && moveXdown < OnehalfWidth + One_onehalfWidth && moveYdown > ThreehalfHeight * 3) {
                num = 6;
            }
            else {
                num = 7;
            }
            break;
        case 5:
            if (moveXdown < TwohalfWidth * 2 && moveYdown < TwohalfHeight * 2) {
                num = 0;
            }
            else if (moveXdown > TwohalfWidth * 2 && moveYdown < TwohalfHeight) {
                num = 1;
            }
            else if (moveXdown > TwohalfWidth * 2 && moveYdown > TwohalfHeight && moveYdown < TwohalfHeight * 2) {
                num = 2;
            }
            else if (moveXdown > TwohalfWidth * 2 && moveYdown > TwohalfHeight * 2) {
                num = 5;
            }
            else if (moveXdown < TwohalfWidth && moveYdown > TwohalfHeight * 2) {
                num = 3;
            }
            else {
                num = 4;
            }
            break;
        case 7:
            if (moveXdown < OnehalfWidth - One_onehalfWidth && moveYdown > ThreehalfHeight * 3) {
                num = 1;
            }
            else {
                num = 0;
            }
            break;
        case 8:
            if (moveXdown < OnehalfWidth - One_onehalfWidth && moveYdown > ThreehalfHeight * 3) {
                num = 1;
            }
            else if (moveXdown > OnehalfWidth + One_onehalfWidth && moveYdown > ThreehalfHeight * 3) {
                num = 3;
            }
            else if (moveYdown < ThreehalfHeight * 3) {
                num = 0;
            }
            else {
                num = 2;
            }
            break;
        case 9:
            if (moveXdown < ThreehalfWidth && moveYdown < ThreehalfHeight) {
                num = 0;
            }
            else if (moveXdown > ThreehalfWidth && moveXdown < ThreehalfWidth * 2 && moveYdown < ThreehalfHeight) {
                num = 1;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown < ThreehalfHeight) {
                num = 2;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown < ThreehalfHeight) {
                num = 3;
            }
            else if (moveXdown < ThreehalfWidth && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 4;
            }
            else if (moveXdown > ThreehalfWidth && moveXdown < ThreehalfWidth * 2 && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 5;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 6;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown < ThreehalfHeight * 2) {
                num = 7;
            }
            else if (moveXdown < ThreehalfWidth && moveYdown < ThreehalfHeight * 3) {
                num = 8;
            }
            else if (moveXdown > ThreehalfWidth && moveXdown < ThreehalfWidth * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 9;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown < ThreehalfHeight * 3) {
                num = 10;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown < ThreehalfHeight * 3) {
                num = 11;
            }
            else if (moveXdown < ThreehalfWidth && moveYdown > ThreehalfHeight * 3) {
                num = 12;
            }
            else if (moveXdown > ThreehalfWidth && moveXdown < ThreehalfWidth * 2 && moveYdown > ThreehalfHeight * 3) {
                num = 13;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 3) {
                num = 14;
            }
            else {
                num = 15;
            }
            break;
        case 10:
            if (moveXdown < OnehalfWidth + One_onehalfWidth) {
                num = 0;
            }
            else if (moveXdown > OnehalfWidth + One_onehalfWidth && moveYdown < ThreehalfHeight) {
                num = 1;
            }
            else if (moveXdown > OnehalfWidth + One_onehalfWidth && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 2;
            }
            else if (moveXdown > OnehalfWidth + One_onehalfWidth && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 3;
            }
            else {
                num = 4;
            }
            break;
        case 11:
            if (moveXdown < TwohalfWidth * 2) {
                num = 0;
            }
            else if (moveXdown > TwohalfWidth * 2 && moveYdown < OnehalfHeight) {
                num = 1;
            }
            else if (moveXdown > TwohalfWidth * 2 && moveYdown > OnehalfHeight) {
                num = 2;
            }
            break;
        case 12:
            if (moveXdown < ThreehalfWidth * 2) {
                num = 0;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown < OnehalfHeight) {
                num = 1;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown < OnehalfHeight) {
                num = 2;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > OnehalfHeight) {
                num = 3;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > OnehalfHeight) {
                num = 4;
            }
            break;
        case 13:
            if (moveXdown < ThreehalfWidth * 2) {
                num = 0;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown < TwohalfHeight) {
                num = 1;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown < TwohalfHeight) {
                num = 2;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > TwohalfHeight && moveYdown < TwohalfHeight * 2) {
                num = 3;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > TwohalfHeight && moveYdown < TwohalfHeight * 2) {
                num = 4;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > TwohalfHeight * 2) {
                num = 5;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > TwohalfHeight * 2) {
                num = 6;
            }
            break;
        case 14:
            if (moveXdown < ThreehalfWidth * 2 && moveYdown < ThreehalfHeight * 2) {
                num = 0;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown < ThreehalfHeight) {
                num = 1;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown < ThreehalfHeight) {
                num = 2;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 3;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 4;
            }
            else if (moveXdown < ThreehalfWidth && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 5;
            }
            else if (moveXdown > ThreehalfWidth && moveXdown < ThreehalfWidth * 2 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 6;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 7;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 8;
            }
            else if (moveXdown < ThreehalfWidth && moveYdown > ThreehalfHeight * 3) {
                num = 9;
            }
            else if (moveXdown > ThreehalfWidth && moveXdown < ThreehalfWidth * 2 && moveYdown > ThreehalfHeight * 3) {
                num = 10;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 3) {
                num = 11;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 3) {
                num = 12;
            }
            break;
        case 15:
            if (moveXdown < FourhalfWidth * 2 && moveYdown < ThreehalfHeight * 2) {
                num = 0;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown < ThreehalfHeight) {
                num = 1;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown < ThreehalfHeight) {
                num = 2;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown < ThreehalfHeight) {
                num = 3;
            }

            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 4;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 5;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 6;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 7;
            }
            else if (moveXdown > FourhalfWidth && moveXdown < FourhalfWidth * 2 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 8;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 9;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 10;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 11;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > ThreehalfHeight * 3) {
                num = 12;
            }
            else if (moveXdown > FourhalfWidth && moveXdown < FourhalfWidth * 2 && moveYdown > ThreehalfHeight * 3) {
                num = 13;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > ThreehalfHeight * 3) {
                num = 14;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > ThreehalfHeight * 3) {
                num = 15;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > ThreehalfHeight * 3) {
                num = 16;
            }
            break;
        case 16:
            if (moveXdown < ThreehalfWidth && moveYdown < FourhalfHeight) {
                num = 0;
            }
            else if (moveXdown > ThreehalfWidth * 1 && moveXdown < ThreehalfWidth * 2 && moveYdown < FourhalfHeight) {
                num = 1;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown < FourhalfHeight) {
                num = 2;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown < FourhalfHeight) {
                num = 3;
            }

            else if (moveXdown < ThreehalfWidth && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 4;
            }
            else if (moveXdown > ThreehalfWidth * 1 && moveXdown < ThreehalfWidth * 2 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 5;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 6;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 7;
            }

            else if (moveXdown < ThreehalfWidth && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 8;
            }
            else if (moveXdown > ThreehalfWidth * 1 && moveXdown < ThreehalfWidth * 2 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 9;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 10;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 11;
            }

            else if (moveXdown < ThreehalfWidth && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 12;
            }
            else if (moveXdown > ThreehalfWidth * 1 && moveXdown < ThreehalfWidth * 2 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 13;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 14;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 15;
            }

            else if (moveXdown < ThreehalfWidth && moveYdown > FourhalfHeight * 4) {
                num = 16;
            }
            else if (moveXdown > ThreehalfWidth && moveXdown < ThreehalfWidth * 2 && moveYdown > FourhalfHeight * 4) {
                num = 17;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > FourhalfHeight * 4) {
                num = 18;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > FourhalfHeight * 4) {
                num = 19;
            }
            break;

        case 17:
            if (moveXdown < FourhalfWidth && moveYdown < FourhalfHeight) {
                num = 0;
            }
            else if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 2 && moveYdown < FourhalfHeight) {
                num = 1;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown < FourhalfHeight) {
                num = 2;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown < FourhalfHeight) {
                num = 3;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown < FourhalfHeight) {
                num = 4;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 5;
            }
            else if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 6;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 7;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 8;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 9;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 10;
            }
            else if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 11;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 12;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 13;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 14;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 15;
            }
            else if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 16;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 17;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 18;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 19;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 4) {
                num = 20;
            }
            else if (moveXdown > FourhalfWidth && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 4) {
                num = 21;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 4) {
                num = 22;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight * 4) {
                num = 23;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 4) {
                num = 24;
            }
            break;

        case 19:
            if (moveXdown < TwohalfWidth * 2) {
                num = 0;
            }
            else if (moveXdown > TwohalfWidth * 2 && moveYdown < OnehalfHeight) {
                num = 1;
            }
            else if (moveXdown > TwohalfWidth * 2 && moveYdown > OnehalfHeight) {
                num = 2;
            }
            break;

        case 20:
            if (moveXdown < EightDivideWidth * 6) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown < SixDivideHeight) {
                num = 1;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown < SixDivideHeight) {
                num = 2;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > SixDivideHeight && moveYdown < SixDivideHeight * 2) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > SixDivideHeight && moveYdown < SixDivideHeight * 2) {
                num = 4;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 6;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 8;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 10;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > SixDivideHeight * 5) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > SixDivideHeight * 5) {
                num = 12;
            }
            break;

        case 21:
            if (moveXdown < EightDivideWidth * 6) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown < SixDivideHeight * 2) {
                num = 1;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 3;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 5;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 7;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > SixDivideHeight * 5) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > SixDivideHeight * 5) {
                num = 9;
            }
            break;

        case 22:
            if (moveXdown < OnehalfWidth + One_onehalfWidth) {
                num = 0;
            }
            else if (moveXdown > OnehalfWidth + One_onehalfWidth && moveYdown < TwohalfHeight) {
                num = 1;
            }
            else if (moveXdown > OnehalfWidth + One_onehalfWidth && moveYdown > TwohalfHeight && moveYdown < TwohalfHeight * 2) {
                num = 2;
            }
            else {
                num = 3;
            }
            break;

        case 23:
            if (moveXdown > EightDivideWidth && moveXdown < EightDivideWidth * 7 && moveYdown < EightDivideHeight * 6) {
                num = 0;
            }

            else if (moveXdown < EightDivideWidth && moveYdown < EightDivideHeight) {
                num = 1;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown < EightDivideHeight) {
                num = 2;
            }

            else if (moveXdown < EightDivideWidth && moveYdown > EightDivideHeight && moveYdown < EightDivideHeight * 2) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight && moveYdown < EightDivideHeight * 2) {
                num = 4;
            }

            else if (moveXdown < EightDivideWidth && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 6;
            }

            else if (moveXdown < EightDivideWidth && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 8;
            }

            else if (moveXdown < EightDivideWidth && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 10;
            }

            else if (moveXdown < EightDivideWidth && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 12;
            }

            else if (moveXdown < EightDivideWidth && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 16;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 17;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 20;
            }

            else if (moveXdown < EightDivideWidth && moveYdown > EightDivideHeight * 7) {
                num = 21;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 22;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 23;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 24;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 25;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 26;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 27;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 28;
            }
            break;

        case 24:
            if (moveXdown < EightDivideWidth * 7 && moveYdown < EightDivideHeight * 7) {
                num = 0;
            }

            else if (moveXdown > EightDivideWidth * 7 && moveYdown < EightDivideHeight) {
                num = 1;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 7;
            }

            else if (moveXdown < EightDivideWidth && moveYdown > EightDivideHeight * 7) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 12;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 15;
            }
            break;

        case 25:
            if (moveXdown < ThreehalfWidth * 2 && moveYdown < ThreehalfHeight * 2) {
                num = 0;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveYdown < ThreehalfHeight * 2) {
                num = 1;
            }

            else if (moveXdown < ThreehalfWidth * 1 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 2;
            }
            else if (moveXdown > ThreehalfWidth * 1 && moveXdown < ThreehalfWidth * 2 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 3;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 4;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 5;
            }

            else if (moveXdown < ThreehalfWidth * 1 && moveYdown > ThreehalfHeight * 3) {
                num = 6;
            }
            else if (moveXdown > ThreehalfWidth * 1 && moveXdown < ThreehalfWidth * 2 && moveYdown > ThreehalfHeight * 3) {
                num = 7;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 3) {
                num = 8;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 3) {
                num = 9;
            }
            break;

        case 26:
            if (moveXdown < EightDivideWidth * 4 && moveYdown < EightDivideHeight * 4) {
                num = 0;
            }

            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown < EightDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown < EightDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown < EightDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown < EightDivideHeight * 1) {
                num = 4;
            }

            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 8;
            }

            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 12;
            }

            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 16;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 17;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 20;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 21;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 22;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 23;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 24;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 25;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 26;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 27;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 28;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 29;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 30;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 31;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 32;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 33;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 34;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 35;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 36;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 37;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 38;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 39;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 40;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 7) {
                num = 41;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 42;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 43;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 44;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 45;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 46;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 47;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 48;
            }
            break;

        case 27:
            if (moveXdown < EightDivideWidth * 4 && moveYdown < EightDivideHeight * 5) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveYdown < EightDivideHeight * 5) {
                num = 1;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 9;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 12;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 16;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 17;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 7) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 20;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 21;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 22;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 23;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 24;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 25;
            }
            break;
        case 28:
            if (moveXdown < EightDivideWidth * 6 && moveYdown < EightDivideHeight * 6) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown < EightDivideHeight * 2) {
                num = 1;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 3;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 5;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 7;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 9;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 12;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 16;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 17;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 7) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 20;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 21;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 22;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 23;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 24;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 25;
            }

            break;

        case 29:
            if (moveXdown > SixteenDivideWidth * 1 && moveXdown < SixteenDivideWidth * 15 && moveYdown < SixteenDivideHeight * 14) {
                num = 0;
            }

            else if (moveXdown < SixteenDivideWidth && moveYdown < SixteenDivideHeight) {
                num = 1;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown < SixteenDivideHeight) {
                num = 2;
            }

            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 1 && moveYdown < SixteenDivideHeight * 2) {
                num = 3;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 1 && moveYdown < SixteenDivideHeight * 2) {
                num = 4;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 2 && moveYdown < SixteenDivideHeight * 3) {
                num = 5;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 2 && moveYdown < SixteenDivideHeight * 3) {
                num = 6;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 3 && moveYdown < SixteenDivideHeight * 4) {
                num = 7;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 3 && moveYdown < SixteenDivideHeight * 4) {
                num = 8;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 4 && moveYdown < SixteenDivideHeight * 5) {
                num = 9;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 4 && moveYdown < SixteenDivideHeight * 5) {
                num = 10;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 5 && moveYdown < SixteenDivideHeight * 6) {
                num = 11;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 5 && moveYdown < SixteenDivideHeight * 6) {
                num = 12;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 6 && moveYdown < SixteenDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 6 && moveYdown < SixteenDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 7 && moveYdown < SixteenDivideHeight * 8) {
                num = 15;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 7 && moveYdown < SixteenDivideHeight * 8) {
                num = 16;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 8 && moveYdown < SixteenDivideHeight * 9) {
                num = 17;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 8 && moveYdown < SixteenDivideHeight * 9) {
                num = 18;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 9 && moveYdown < SixteenDivideHeight * 10) {
                num = 19;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 9 && moveYdown < SixteenDivideHeight * 10) {
                num = 20;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 10 && moveYdown < SixteenDivideHeight * 11) {
                num = 21;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 10 && moveYdown < SixteenDivideHeight * 11) {
                num = 22;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 11 && moveYdown < SixteenDivideHeight * 12) {
                num = 23;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 11 && moveYdown < SixteenDivideHeight * 12) {
                num = 24;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 12 && moveYdown < SixteenDivideHeight * 13) {
                num = 25;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 12 && moveYdown < SixteenDivideHeight * 13) {
                num = 26;
            }
            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 13 && moveYdown < SixteenDivideHeight * 14) {
                num = 27;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 13 && moveYdown < SixteenDivideHeight * 14) {
                num = 28;
            }

            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 29;
            }
            else if (moveXdown > SixteenDivideWidth * 1 && moveXdown < SixteenDivideWidth * 2 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 30;
            }
            else if (moveXdown > SixteenDivideWidth * 2 && moveXdown < SixteenDivideWidth * 3 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 31;
            }
            else if (moveXdown > SixteenDivideWidth * 3 && moveXdown < SixteenDivideWidth * 4 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 32;
            }
            else if (moveXdown > SixteenDivideWidth * 4 && moveXdown < SixteenDivideWidth * 5 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 33;
            }
            else if (moveXdown > SixteenDivideWidth * 5 && moveXdown < SixteenDivideWidth * 6 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 34;
            }
            else if (moveXdown > SixteenDivideWidth * 6 && moveXdown < SixteenDivideWidth * 7 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 35;
            }
            else if (moveXdown > SixteenDivideWidth * 7 && moveXdown < SixteenDivideWidth * 8 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 36;
            }
            else if (moveXdown > SixteenDivideWidth * 8 && moveXdown < SixteenDivideWidth * 9 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 37;
            }
            else if (moveXdown > SixteenDivideWidth * 9 && moveXdown < SixteenDivideWidth * 10 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 38;
            }
            else if (moveXdown > SixteenDivideWidth * 10 && moveXdown < SixteenDivideWidth * 11 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 39;
            }
            else if (moveXdown > SixteenDivideWidth * 11 && moveXdown < SixteenDivideWidth * 12 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 40;
            }
            else if (moveXdown > SixteenDivideWidth * 12 && moveXdown < SixteenDivideWidth * 13 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 41;
            }
            else if (moveXdown > SixteenDivideWidth * 13 && moveXdown < SixteenDivideWidth * 14 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 42;
            }
            else if (moveXdown > SixteenDivideWidth * 14 && moveXdown < SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 43;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 14 && moveYdown < SixteenDivideHeight * 15) {
                num = 44;
            }

            else if (moveXdown < SixteenDivideWidth && moveYdown > SixteenDivideHeight * 15) {
                num = 45;
            }
            else if (moveXdown > SixteenDivideWidth * 1 && moveXdown < SixteenDivideWidth * 2 && moveYdown > SixteenDivideHeight * 15) {
                num = 46;
            }
            else if (moveXdown > SixteenDivideWidth * 2 && moveXdown < SixteenDivideWidth * 3 && moveYdown > SixteenDivideHeight * 15) {
                num = 47;
            }
            else if (moveXdown > SixteenDivideWidth * 3 && moveXdown < SixteenDivideWidth * 4 && moveYdown > SixteenDivideHeight * 15) {
                num = 48;
            }
            else if (moveXdown > SixteenDivideWidth * 4 && moveXdown < SixteenDivideWidth * 5 && moveYdown > SixteenDivideHeight * 15) {
                num = 49;
            }
            else if (moveXdown > SixteenDivideWidth * 5 && moveXdown < SixteenDivideWidth * 6 && moveYdown > SixteenDivideHeight * 15) {
                num = 50;
            }
            else if (moveXdown > SixteenDivideWidth * 6 && moveXdown < SixteenDivideWidth * 7 && moveYdown > SixteenDivideHeight * 15) {
                num = 51;
            }
            else if (moveXdown > SixteenDivideWidth * 7 && moveXdown < SixteenDivideWidth * 8 && moveYdown > SixteenDivideHeight * 15) {
                num = 52;
            }
            else if (moveXdown > SixteenDivideWidth * 8 && moveXdown < SixteenDivideWidth * 9 && moveYdown > SixteenDivideHeight * 15) {
                num = 53;
            }
            else if (moveXdown > SixteenDivideWidth * 9 && moveXdown < SixteenDivideWidth * 10 && moveYdown > SixteenDivideHeight * 15) {
                num = 54;
            }
            else if (moveXdown > SixteenDivideWidth * 10 && moveXdown < SixteenDivideWidth * 11 && moveYdown > SixteenDivideHeight * 15) {
                num = 55;
            }
            else if (moveXdown > SixteenDivideWidth * 11 && moveXdown < SixteenDivideWidth * 12 && moveYdown > SixteenDivideHeight * 15) {
                num = 56;
            }
            else if (moveXdown > SixteenDivideWidth * 12 && moveXdown < SixteenDivideWidth * 13 && moveYdown > SixteenDivideHeight * 15) {
                num = 57;
            }
            else if (moveXdown > SixteenDivideWidth * 13 && moveXdown < SixteenDivideWidth * 14 && moveYdown > SixteenDivideHeight * 15) {
                num = 58;
            }
            else if (moveXdown > SixteenDivideWidth * 14 && moveXdown < SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 15) {
                num = 59;
            }
            else if (moveXdown > SixteenDivideWidth * 15 && moveYdown > SixteenDivideHeight * 15) {
                num = 60;
            }

            break;

        case 30:
            if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 8 && moveYdown < TenDivideHeight * 6) {
                num = 0;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown < TenDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown < TenDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 4;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 5;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 6;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 8;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 9;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 10;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 11;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 12;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 14;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 15;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 16;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 17;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 18;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 20;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 21;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 24;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 27;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 30;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 31;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 33;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 34;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 35;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 36;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 37;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 38;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 39;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 40;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 41;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 42;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 43;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 44;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 45;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 46;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 47;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 48;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 49;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 50;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 51;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 52;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 53;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 54;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 9) {
                num = 55;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 9) {
                num = 56;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 57;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 58;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 59;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 60;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 61;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 62;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 63;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 64;
            }
            break;

        case 31:
            if (moveXdown < TenDivideWidth * 1 && moveYdown < TenDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 9 && moveYdown < TenDivideHeight * 8) {
                num = 0;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 2;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 1 && moveYdown < TenDivideHeight * 2) {
                num = 3;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 1 && moveYdown < TenDivideHeight * 2) {
                num = 4;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 5;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 6;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 8;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 9;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 10;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 11;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 12;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 14;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 15;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 16;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 17;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 18;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 20;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 21;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 24;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 26;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 9) {
                num = 27;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 9) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 30;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 31;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 33;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 34;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 35;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 36;
            }
            break;
        case 32:
            if (moveXdown < EightDivideWidth * 4 && moveYdown < EightDivideHeight * 4) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveYdown < EightDivideHeight * 4) {
                num = 1;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 9;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 12;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 16;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 17;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 20;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 21;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 22;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 23;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 24;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 25;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 7) {
                num = 26;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 27;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 28;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 29;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 30;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 31;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 32;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 33;
            }
            break;

        case 33:
            if (moveXdown < SixDivideWidth * 2 && moveYdown < SixDivideHeight * 2) {
                num = 0;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown < SixDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown < SixDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown < SixDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown < SixDivideHeight * 1) {
                num = 4;
            }

            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 5;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 6;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 7;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 8;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 9;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 10;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 11;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 12;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 13;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 14;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 15;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 16;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 17;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 18;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 19;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 20;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 21;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 22;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 23;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 24;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 25;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 26;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 5) {
                num = 27;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 5) {
                num = 28;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 5) {
                num = 29;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 5) {
                num = 30;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 31;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 32;
            }
            break;

        case 34:
            if (moveXdown < FourhalfWidth * 2 && moveYdown < FourhalfHeight * 2) {
                num = 0;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown < FourhalfHeight) {
                num = 1;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown < FourhalfHeight) {
                num = 2;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown < FourhalfHeight) {
                num = 3;
            }

            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 4;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 5;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 6;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 7;
            }
            else if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 8;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 9;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 10;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 11;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 12;
            }
            else if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 13;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 14;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 15;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 16;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 4) {
                num = 17;
            }
            else if (moveXdown > FourhalfWidth && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 4) {
                num = 18;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 4) {
                num = 19;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight * 4) {
                num = 20;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 4) {
                num = 21;
            }
            break;

        case 35:
            if (moveXdown < SixDivideWidth * 1 && moveYdown < SixDivideHeight * 1) {
                num = 0;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown < SixDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown < SixDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown < SixDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown < SixDivideHeight * 1) {
                num = 4;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown < SixDivideHeight * 1) {
                num = 5;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 6;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 7;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 8;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 9;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 10;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 11;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 12;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 13;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 14;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 15;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 16;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 17;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 18;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 19;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 20;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 21;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 22;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 23;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 24;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 25;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 26;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 27;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 28;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 29;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 5) {
                num = 30;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 5) {
                num = 31;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 5) {
                num = 32;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 5) {
                num = 33;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 34;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 35;
            }
            break;

        case 36:
            if (moveXdown < EightDivideWidth * 1 && moveYdown < EightDivideHeight * 1) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown < EightDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown < EightDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown < EightDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown < EightDivideHeight * 1) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown < EightDivideHeight * 1) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown < EightDivideHeight * 1) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown < EightDivideHeight * 1) {
                num = 7;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 12;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 15;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 16;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 17;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 20;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 21;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 22;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 23;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 24;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 25;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 26;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 27;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 28;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 29;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 30;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 31;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 32;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 33;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 34;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 35;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 36;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 37;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 38;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 39;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 40;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 41;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 42;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 43;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 44;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 45;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 46;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 47;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 48;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 49;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 50;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 51;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 52;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 53;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 54;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 55;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 7) {
                num = 56;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 57;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 58;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 59;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 60;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 61;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 62;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 63;
            }
            break;

        case 37:
            if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 7 && moveYdown < TenDivideHeight * 5) {
                num = 0;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown < TenDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown < TenDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown < TenDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 4;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 5;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 6;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 8;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 9;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 10;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 11;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 12;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 14;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 15;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 16;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 17;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 18;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 20;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 21;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 24;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 25;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 27;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 30;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 31;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 33;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 34;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 35;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 36;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 37;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 38;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 39;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 40;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 41;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 42;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 43;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 44;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 45;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 46;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 47;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 48;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 49;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 50;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 51;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 52;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 53;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 54;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 55;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 56;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 57;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 58;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 59;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 60;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 61;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 62;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 63;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 64;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 65;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 9) {
                num = 66;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 9) {
                num = 67;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 68;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 69;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 70;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 71;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 72;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 73;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 74;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 75;
            }
            break;

        case 38:
            if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 6 && moveYdown < EightDivideHeight * 4) {
                num = 0;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown < EightDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown < EightDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown < EightDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown < EightDivideHeight * 1) {
                num = 4;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 8;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 12;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 16;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 17;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 20;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 21;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 22;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 23;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 24;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 25;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 26;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 27;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 28;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 29;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 30;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 31;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 32;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 33;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 34;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 35;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 36;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 37;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 38;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 39;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 40;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 7) {
                num = 41;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 42;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 43;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 44;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 45;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 46;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 47;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 48;
            }
            break;

        case 39:
            if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 7 && moveYdown < TenDivideHeight * 4) {
                num = 0;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown < TenDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown < TenDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown < TenDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown < TenDivideHeight * 1) {
                num = 4;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 5;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 6;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 8;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 9;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 10;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 11;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 12;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 14;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 15;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 16;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 17;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 18;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 20;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 21;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 24;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 27;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 30;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 31;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 33;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 34;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 35;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 36;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 37;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 38;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 39;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 40;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 41;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 42;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 43;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 44;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 45;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 46;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 47;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 48;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 49;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 50;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 51;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 52;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 53;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 54;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 55;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 56;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 57;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 58;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 59;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 60;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 61;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 62;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 63;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 64;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 65;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 66;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 67;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 68;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 69;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 70;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 71;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 72;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 73;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 74;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 9) {
                num = 75;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 9) {
                num = 76;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 77;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 78;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 79;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 80;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 81;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 82;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 83;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 84;
            }
            break;

        case 40:
            if (moveXdown < TenDivideWidth * 5 && moveYdown < TenDivideHeight * 5) {
                num = 0;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveYdown < TenDivideHeight * 5) {
                num = 1;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 2;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 3;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 4;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 5;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 6;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 8;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 9;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 10;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 11;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 12;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 15;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 16;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 17;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 18;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 20;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 21;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 24;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 27;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 30;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 31;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 33;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 34;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 35;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 36;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 37;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 38;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 39;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 40;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 41;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 9) {
                num = 42;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 9) {
                num = 43;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 44;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 45;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 46;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 47;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 48;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 49;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 50;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 51;
            }
            break;

        case 41:
            if (moveXdown < EightDivideWidth * 4 && moveYdown < EightDivideHeight * 4) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveYdown < EightDivideHeight * 4) {
                num = 1;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveYdown > EightDivideHeight * 4) {
                num = 2;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 6;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 10;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 12;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 14;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 7) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 16;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 17;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 18;
            }
            break;

        case 42:
            if (moveXdown < EightDivideWidth * 4 && moveYdown < EightDivideHeight * 4) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveYdown < EightDivideHeight * 4) {
                num = 1;
            }

            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 6) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 6) {
                num = 3;
            }

            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6) {
                num = 5;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 9;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 12;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 13;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 16;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 17;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 7) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 20;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 21;
            }
            break;

        case 43:
            if (moveXdown < EightDivideWidth * 4 && moveYdown < EightDivideHeight * 4) {
                num = 0;
            }

            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 6 && moveYdown < EightDivideHeight * 2) {
                num = 1;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown < EightDivideHeight * 2) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 4) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 4) {
                num = 4;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 12;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 16;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 17;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 20;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 21;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 22;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 23;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 24;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 25;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 26;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 27;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 28;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 7) {
                num = 29;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 30;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 31;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 32;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 33;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 34;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 35;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 36;
            }

            break;

        case 44:
            if (moveXdown < TwelveDivideWidth * 6 && moveYdown < TwelveDivideHeight * 6) {
                num = 0;
            }
            else if (moveXdown > TwelveDivideWidth * 6 && moveYdown < TwelveDivideHeight * 6) {
                num = 1;
            }

            else if (moveXdown < TwelveDivideWidth * 1 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 2;
            }
            else if (moveXdown > TwelveDivideWidth * 1 && moveXdown < TwelveDivideWidth * 2 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 3;
            }
            else if (moveXdown > TwelveDivideWidth * 2 && moveXdown < TwelveDivideWidth * 3 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 4;
            }
            else if (moveXdown > TwelveDivideWidth * 3 && moveXdown < TwelveDivideWidth * 4 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 5;
            }
            else if (moveXdown > TwelveDivideWidth * 4 && moveXdown < TwelveDivideWidth * 5 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 6;
            }
            else if (moveXdown > TwelveDivideWidth * 5 && moveXdown < TwelveDivideWidth * 6 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 7;
            }
            else if (moveXdown > TwelveDivideWidth * 6 && moveXdown < TwelveDivideWidth * 7 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 8;
            }
            else if (moveXdown > TwelveDivideWidth * 7 && moveXdown < TwelveDivideWidth * 8 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 9;
            }
            else if (moveXdown > TwelveDivideWidth * 8 && moveXdown < TwelveDivideWidth * 9 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 10;
            }
            else if (moveXdown > TwelveDivideWidth * 9 && moveXdown < TwelveDivideWidth * 10 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 11;
            }
            else if (moveXdown > TwelveDivideWidth * 10 && moveXdown < TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 12;
            }
            else if (moveXdown > TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 6 && moveYdown < TwelveDivideHeight * 7) {
                num = 13;
            }

            else if (moveXdown < TwelveDivideWidth * 1 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 14;
            }
            else if (moveXdown > TwelveDivideWidth * 1 && moveXdown < TwelveDivideWidth * 2 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 15;
            }
            else if (moveXdown > TwelveDivideWidth * 2 && moveXdown < TwelveDivideWidth * 3 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 16;
            }
            else if (moveXdown > TwelveDivideWidth * 3 && moveXdown < TwelveDivideWidth * 4 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 17;
            }
            else if (moveXdown > TwelveDivideWidth * 4 && moveXdown < TwelveDivideWidth * 5 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 18;
            }
            else if (moveXdown > TwelveDivideWidth * 5 && moveXdown < TwelveDivideWidth * 6 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 19;
            }
            else if (moveXdown > TwelveDivideWidth * 6 && moveXdown < TwelveDivideWidth * 7 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 20;
            }
            else if (moveXdown > TwelveDivideWidth * 7 && moveXdown < TwelveDivideWidth * 8 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 21;
            }
            else if (moveXdown > TwelveDivideWidth * 8 && moveXdown < TwelveDivideWidth * 9 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 22;
            }
            else if (moveXdown > TwelveDivideWidth * 9 && moveXdown < TwelveDivideWidth * 10 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 23;
            }
            else if (moveXdown > TwelveDivideWidth * 10 && moveXdown < TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 24;
            }
            else if (moveXdown > TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 7 && moveYdown < TwelveDivideHeight * 8) {
                num = 25;
            }

            else if (moveXdown < TwelveDivideWidth * 1 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 26;
            }
            else if (moveXdown > TwelveDivideWidth * 1 && moveXdown < TwelveDivideWidth * 2 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 27;
            }
            else if (moveXdown > TwelveDivideWidth * 2 && moveXdown < TwelveDivideWidth * 3 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 28;
            }
            else if (moveXdown > TwelveDivideWidth * 3 && moveXdown < TwelveDivideWidth * 4 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 29;
            }
            else if (moveXdown > TwelveDivideWidth * 4 && moveXdown < TwelveDivideWidth * 5 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 30;
            }
            else if (moveXdown > TwelveDivideWidth * 5 && moveXdown < TwelveDivideWidth * 6 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 31;
            }
            else if (moveXdown > TwelveDivideWidth * 6 && moveXdown < TwelveDivideWidth * 7 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 32;
            }
            else if (moveXdown > TwelveDivideWidth * 7 && moveXdown < TwelveDivideWidth * 8 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 33;
            }
            else if (moveXdown > TwelveDivideWidth * 8 && moveXdown < TwelveDivideWidth * 9 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 34;
            }
            else if (moveXdown > TwelveDivideWidth * 9 && moveXdown < TwelveDivideWidth * 10 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 35;
            }
            else if (moveXdown > TwelveDivideWidth * 10 && moveXdown < TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 36;
            }
            else if (moveXdown > TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 8 && moveYdown < TwelveDivideHeight * 9) {
                num = 37;
            }

            else if (moveXdown < TwelveDivideWidth * 1 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 38;
            }
            else if (moveXdown > TwelveDivideWidth * 1 && moveXdown < TwelveDivideWidth * 2 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 39;
            }
            else if (moveXdown > TwelveDivideWidth * 2 && moveXdown < TwelveDivideWidth * 3 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 40;
            }
            else if (moveXdown > TwelveDivideWidth * 3 && moveXdown < TwelveDivideWidth * 4 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 41;
            }
            else if (moveXdown > TwelveDivideWidth * 4 && moveXdown < TwelveDivideWidth * 5 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 42;
            }
            else if (moveXdown > TwelveDivideWidth * 5 && moveXdown < TwelveDivideWidth * 6 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 43;
            }
            else if (moveXdown > TwelveDivideWidth * 6 && moveXdown < TwelveDivideWidth * 7 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 44;
            }
            else if (moveXdown > TwelveDivideWidth * 7 && moveXdown < TwelveDivideWidth * 8 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 45;
            }
            else if (moveXdown > TwelveDivideWidth * 8 && moveXdown < TwelveDivideWidth * 9 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 46;
            }
            else if (moveXdown > TwelveDivideWidth * 9 && moveXdown < TwelveDivideWidth * 10 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 47;
            }
            else if (moveXdown > TwelveDivideWidth * 10 && moveXdown < TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 48;
            }
            else if (moveXdown > TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 9 && moveYdown < TwelveDivideHeight * 10) {
                num = 49;
            }

            else if (moveXdown < TwelveDivideWidth * 1 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 50;
            }
            else if (moveXdown > TwelveDivideWidth * 1 && moveXdown < TwelveDivideWidth * 2 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 51;
            }
            else if (moveXdown > TwelveDivideWidth * 2 && moveXdown < TwelveDivideWidth * 3 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 52;
            }
            else if (moveXdown > TwelveDivideWidth * 3 && moveXdown < TwelveDivideWidth * 4 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 53;
            }
            else if (moveXdown > TwelveDivideWidth * 4 && moveXdown < TwelveDivideWidth * 5 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 54;
            }
            else if (moveXdown > TwelveDivideWidth * 5 && moveXdown < TwelveDivideWidth * 6 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 55;
            }
            else if (moveXdown > TwelveDivideWidth * 6 && moveXdown < TwelveDivideWidth * 7 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 56;
            }
            else if (moveXdown > TwelveDivideWidth * 7 && moveXdown < TwelveDivideWidth * 8 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 57;
            }
            else if (moveXdown > TwelveDivideWidth * 8 && moveXdown < TwelveDivideWidth * 9 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 58;
            }
            else if (moveXdown > TwelveDivideWidth * 9 && moveXdown < TwelveDivideWidth * 10 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 59;
            }
            else if (moveXdown > TwelveDivideWidth * 10 && moveXdown < TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 60;
            }
            else if (moveXdown > TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 10 && moveYdown < TwelveDivideHeight * 11) {
                num = 61;
            }

            else if (moveXdown < TwelveDivideWidth * 1 && moveYdown > TwelveDivideHeight * 11) {
                num = 62;
            }
            else if (moveXdown > TwelveDivideWidth * 1 && moveXdown < TwelveDivideWidth * 2 && moveYdown > TwelveDivideHeight * 11) {
                num = 63;
            }
            else if (moveXdown > TwelveDivideWidth * 2 && moveXdown < TwelveDivideWidth * 3 && moveYdown > TwelveDivideHeight * 11) {
                num = 64;
            }
            else if (moveXdown > TwelveDivideWidth * 3 && moveXdown < TwelveDivideWidth * 4 && moveYdown > TwelveDivideHeight * 11) {
                num = 65;
            }
            else if (moveXdown > TwelveDivideWidth * 4 && moveXdown < TwelveDivideWidth * 5 && moveYdown > TwelveDivideHeight * 11) {
                num = 66;
            }
            else if (moveXdown > TwelveDivideWidth * 5 && moveXdown < TwelveDivideWidth * 6 && moveYdown > TwelveDivideHeight * 11) {
                num = 67;
            }
            else if (moveXdown > TwelveDivideWidth * 6 && moveXdown < TwelveDivideWidth * 7 && moveYdown > TwelveDivideHeight * 11) {
                num = 68;
            }
            else if (moveXdown > TwelveDivideWidth * 7 && moveXdown < TwelveDivideWidth * 8 && moveYdown > TwelveDivideHeight * 11) {
                num = 69;
            }
            else if (moveXdown > TwelveDivideWidth * 8 && moveXdown < TwelveDivideWidth * 9 && moveYdown > TwelveDivideHeight * 11) {
                num = 70;
            }
            else if (moveXdown > TwelveDivideWidth * 9 && moveXdown < TwelveDivideWidth * 10 && moveYdown > TwelveDivideHeight * 11) {
                num = 71;
            }
            else if (moveXdown > TwelveDivideWidth * 10 && moveXdown < TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 11) {
                num = 72;
            }
            else if (moveXdown > TwelveDivideWidth * 11 && moveYdown > TwelveDivideHeight * 11) {
                num = 73;
            }
            break;

        case 45:
            if (moveXdown < NineDivideWidth * 1 && moveYdown < NineDivideHeight * 1) {
                num = 0;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown < NineDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown < NineDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown < NineDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown < NineDivideHeight * 1) {
                num = 4;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown < NineDivideHeight * 1) {
                num = 5;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown < NineDivideHeight * 1) {
                num = 6;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown < NineDivideHeight * 1) {
                num = 7;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown < NineDivideHeight * 1) {
                num = 8;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 9;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 10;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 11;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 12;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 13;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 14;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 15;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 16;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 17;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 18;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 19;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 20;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 21;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 22;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 23;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 24;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 25;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 26;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 27;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 28;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 29;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 30;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 31;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 32;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 33;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 34;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 35;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 36;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 37;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 38;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 39;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 40;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 41;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 42;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 43;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 44;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 45;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 46;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 47;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 48;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 49;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 50;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 51;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 52;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 53;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 54;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 55;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 56;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 57;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 58;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 59;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 60;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 61;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 62;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 63;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 64;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 65;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 66;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 67;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 68;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 69;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 70;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 71;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 8) {
                num = 72;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 8) {
                num = 73;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 8) {
                num = 74;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 8) {
                num = 75;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 8) {
                num = 76;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 8) {
                num = 77;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 8) {
                num = 78;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 8) {
                num = 79;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 8) {
                num = 80;
            }
            break;

        case 46:
            if (moveXdown < NineDivideWidth * 5 && moveYdown < NineDivideHeight * 5) {
                num = 0;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveYdown < NineDivideHeight * 4) {
                num = 1;
            }

            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 2;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 3;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 4;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 5;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 6;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 7;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 8;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 9;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 10;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 11;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 12;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 13;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 14;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 15;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 16;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 17;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 18;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 19;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 20;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 21;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 22;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 23;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 24;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 25;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 26;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 27;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 28;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 29;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 30;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 31;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 32;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 8) {
                num = 33;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 8) {
                num = 34;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 8) {
                num = 35;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 8) {
                num = 36;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 8) {
                num = 37;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 8) {
                num = 38;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 8) {
                num = 39;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 8) {
                num = 40;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 8) {
                num = 41;
            }
            break;

        case 47:
            if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 5 && moveYdown < SixDivideHeight * 4) {
                num = 0;
            }
            else if (moveXdown < SixDivideWidth * 1 && moveYdown < SixDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown < SixDivideHeight * 1) {
                num = 2;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 3;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 4;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 5;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 6;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 7;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 8;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 9;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 10;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 11;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 12;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 13;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 14;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 5) {
                num = 15;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 5) {
                num = 16;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 5) {
                num = 17;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 5) {
                num = 18;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 19;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 20;
            }
            break;

        case 48:
            if (moveXdown < EightDivideWidth * 6 && moveYdown < EightDivideHeight * 6) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown < EightDivideHeight * 2) {
                num = 1;
            }
            else if (moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6) {
                num = 2;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 4;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 6;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 8;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 10;
            }

            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 12;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 16;
            }

            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 17;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 20;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 21;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 22;
            }

            break;

        case 49:
            if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 8 && moveYdown < TenDivideHeight * 8) {
                num = 0;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown < TenDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 3;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 4;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 5;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 6;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 8;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 9;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 10;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 11;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 12;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 14;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 15;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 16;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 17;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 18;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 20;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 21;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 24;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 27;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 30;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 31;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 33;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 34;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 9) {
                num = 35;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 9) {
                num = 36;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 37;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 38;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 39;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 40;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 41;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 42;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 43;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 44;
            }
            break;

        case 50:
            if (moveXdown < EightDivideWidth * 3 && moveYdown < EightDivideHeight * 3) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 6) {
                num = 1;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6) {
                num = 2;
            }

            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown < EightDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown < EightDivideHeight * 1) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown < EightDivideHeight * 1) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown < EightDivideHeight * 1) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown < EightDivideHeight * 1) {
                num = 7;
            }

            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 11;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 1 && moveYdown < EightDivideHeight * 2) {
                num = 12;
            }

            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 15;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 16;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 17;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 19;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 20;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 21;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 22;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 23;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 24;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 25;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 26;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 27;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 28;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 29;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 30;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 31;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 32;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 33;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 34;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 35;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 36;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 37;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 38;
            }

            else if (moveXdown < EightDivideWidth * 1 && moveYdown > EightDivideHeight * 7) {
                num = 39;
            }
            else if (moveXdown > EightDivideWidth * 1 && moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 7) {
                num = 40;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 41;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 42;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 43;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 44;
            }
            break;

        case 51:
            if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 4 && moveYdown < FourhalfHeight * 3) {
                num = 0;
            }
            else if (moveXdown < FourhalfWidth && moveYdown < FourhalfHeight) {
                num = 1;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown < FourhalfHeight) {
                num = 2;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 3;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 4;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 5;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 6;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 7;
            }
            else if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 8;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 9;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 10;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 11;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 4) {
                num = 12;
            }
            else if (moveXdown > FourhalfWidth && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 4) {
                num = 13;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 4) {
                num = 14;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight * 4) {
                num = 15;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 4) {
                num = 16;
            }
            break;

        case 52:
            if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 8 && moveYdown < TenDivideHeight * 7) {
                num = 0;
            }

            else if (moveXdown > TenDivideWidth * 8 && moveYdown < TenDivideHeight * 2) {
                num = 1;
            }
            else if (moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8) {
                num = 2;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8) {
                num = 3;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown < TenDivideHeight * 1) {
                num = 4;
            }
            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 1 && moveYdown < TenDivideHeight * 2) {
                num = 5;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 6;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 8;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 9;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 10;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 11;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 12;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 14;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 15;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 16;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 17;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 18;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 20;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 21;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 24;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 27;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 30;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 31;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 33;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 34;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 35;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 36;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 37;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 38;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 39;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 40;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 41;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 42;
            }
            break;

        case 53:
            if (moveXdown < SixDivideWidth * 3 && moveYdown < SixDivideHeight * 3) {
                num = 0;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveYdown < SixDivideHeight * 3) {
                num = 1;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 2;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 3;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 4;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 5;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 6;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 7;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 8;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 9;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 10;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 11;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 12;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 13;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 5) {
                num = 14;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 5) {
                num = 15;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 5) {
                num = 16;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 5) {
                num = 17;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 18;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 19;
            }
            break;

        case 54:
            if (moveXdown > ElevenDivideWidth * 1 && moveXdown < ElevenDivideWidth * 10 && moveYdown < ElevenDivideHeight * 9) {
                num = 0;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown < ElevenDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown < ElevenDivideHeight * 1) {
                num = 2;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown > ElevenDivideHeight * 1 && moveYdown < ElevenDivideHeight * 2) {
                num = 3;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 1 && moveYdown < ElevenDivideHeight * 2) {
                num = 4;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown > ElevenDivideHeight * 2 && moveYdown < ElevenDivideHeight * 3) {
                num = 5;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 2 && moveYdown < ElevenDivideHeight * 3) {
                num = 6;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown > ElevenDivideHeight * 3 && moveYdown < ElevenDivideHeight * 4) {
                num = 7;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 3 && moveYdown < ElevenDivideHeight * 4) {
                num = 8;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown > ElevenDivideHeight * 4 && moveYdown < ElevenDivideHeight * 5) {
                num = 9;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 4 && moveYdown < ElevenDivideHeight * 5) {
                num = 10;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown > ElevenDivideHeight * 5 && moveYdown < ElevenDivideHeight * 6) {
                num = 11;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 5 && moveYdown < ElevenDivideHeight * 6) {
                num = 12;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown > ElevenDivideHeight * 6 && moveYdown < ElevenDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 6 && moveYdown < ElevenDivideHeight * 7) {
                num = 14;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown > ElevenDivideHeight * 7 && moveYdown < ElevenDivideHeight * 8) {
                num = 15;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 7 && moveYdown < ElevenDivideHeight * 8) {
                num = 16;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown > ElevenDivideHeight * 8 && moveYdown < ElevenDivideHeight * 9) {
                num = 17;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 8 && moveYdown < ElevenDivideHeight * 9) {
                num = 18;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 19;
            }
            else if (moveXdown > ElevenDivideWidth * 1 && moveXdown < ElevenDivideWidth * 2 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 20;
            }
            else if (moveXdown > ElevenDivideWidth * 2 && moveXdown < ElevenDivideWidth * 3 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 21;
            }
            else if (moveXdown > ElevenDivideWidth * 3 && moveXdown < ElevenDivideWidth * 4 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 22;
            }
            else if (moveXdown > ElevenDivideWidth * 4 && moveXdown < ElevenDivideWidth * 5 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 23;
            }
            else if (moveXdown > ElevenDivideWidth * 5 && moveXdown < ElevenDivideWidth * 6 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 24;
            }
            else if (moveXdown > ElevenDivideWidth * 6 && moveXdown < ElevenDivideWidth * 7 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 25;
            }
            else if (moveXdown > ElevenDivideWidth * 7 && moveXdown < ElevenDivideWidth * 8 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 26;
            }
            else if (moveXdown > ElevenDivideWidth * 8 && moveXdown < ElevenDivideWidth * 9 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 27;
            }
            else if (moveXdown > ElevenDivideWidth * 9 && moveXdown < ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 28;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 9 && moveYdown < ElevenDivideHeight * 10) {
                num = 29;
            }

            else if (moveXdown < ElevenDivideWidth * 1 && moveYdown > ElevenDivideHeight * 10) {
                num = 30;
            }
            else if (moveXdown > ElevenDivideWidth * 1 && moveXdown < ElevenDivideWidth * 2 && moveYdown > ElevenDivideHeight * 10) {
                num = 31;
            }
            else if (moveXdown > ElevenDivideWidth * 2 && moveXdown < ElevenDivideWidth * 3 && moveYdown > ElevenDivideHeight * 10) {
                num = 32;
            }
            else if (moveXdown > ElevenDivideWidth * 3 && moveXdown < ElevenDivideWidth * 4 && moveYdown > ElevenDivideHeight * 10) {
                num = 33;
            }
            else if (moveXdown > ElevenDivideWidth * 4 && moveXdown < ElevenDivideWidth * 5 && moveYdown > ElevenDivideHeight * 10) {
                num = 34;
            }
            else if (moveXdown > ElevenDivideWidth * 5 && moveXdown < ElevenDivideWidth * 6 && moveYdown > ElevenDivideHeight * 10) {
                num = 35;
            }
            else if (moveXdown > ElevenDivideWidth * 6 && moveXdown < ElevenDivideWidth * 7 && moveYdown > ElevenDivideHeight * 10) {
                num = 36;
            }
            else if (moveXdown > ElevenDivideWidth * 7 && moveXdown < ElevenDivideWidth * 8 && moveYdown > ElevenDivideHeight * 10) {
                num = 37;
            }
            else if (moveXdown > ElevenDivideWidth * 8 && moveXdown < ElevenDivideWidth * 9 && moveYdown > ElevenDivideHeight * 10) {
                num = 38;
            }
            else if (moveXdown > ElevenDivideWidth * 9 && moveXdown < ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 10) {
                num = 39;
            }
            else if (moveXdown > ElevenDivideWidth * 10 && moveYdown > ElevenDivideHeight * 10) {
                num = 40;
            }

            break;

        case 55:
            if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 7 && moveYdown < NineDivideHeight * 5) {
                num = 0;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown < NineDivideHeight * 1) {
                num = 1;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown < NineDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown < NineDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown < NineDivideHeight * 1) {
                num = 4;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 5;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 6;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 7;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 1 && moveYdown < NineDivideHeight * 2) {
                num = 8;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 9;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 10;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 11;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 2 && moveYdown < NineDivideHeight * 3) {
                num = 12;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 13;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 14;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 15;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 3 && moveYdown < NineDivideHeight * 4) {
                num = 16;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 17;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 18;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 19;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 4 && moveYdown < NineDivideHeight * 5) {
                num = 20;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 21;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 22;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 23;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 24;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 25;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 26;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 27;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 28;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 5 && moveYdown < NineDivideHeight * 6) {
                num = 29;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 30;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 31;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 32;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 33;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 34;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 35;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 36;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 37;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 6 && moveYdown < NineDivideHeight * 7) {
                num = 38;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 39;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 40;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 41;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 42;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 43;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 44;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 45;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 46;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 7 && moveYdown < NineDivideHeight * 8) {
                num = 47;
            }

            else if (moveXdown < NineDivideWidth * 1 && moveYdown > NineDivideHeight * 8) {
                num = 48;
            }
            else if (moveXdown > NineDivideWidth * 1 && moveXdown < NineDivideWidth * 2 && moveYdown > NineDivideHeight * 8) {
                num = 49;
            }
            else if (moveXdown > NineDivideWidth * 2 && moveXdown < NineDivideWidth * 3 && moveYdown > NineDivideHeight * 8) {
                num = 50;
            }
            else if (moveXdown > NineDivideWidth * 3 && moveXdown < NineDivideWidth * 4 && moveYdown > NineDivideHeight * 8) {
                num = 51;
            }
            else if (moveXdown > NineDivideWidth * 4 && moveXdown < NineDivideWidth * 5 && moveYdown > NineDivideHeight * 8) {
                num = 52;
            }
            else if (moveXdown > NineDivideWidth * 5 && moveXdown < NineDivideWidth * 6 && moveYdown > NineDivideHeight * 8) {
                num = 53;
            }
            else if (moveXdown > NineDivideWidth * 6 && moveXdown < NineDivideWidth * 7 && moveYdown > NineDivideHeight * 8) {
                num = 54;
            }
            else if (moveXdown > NineDivideWidth * 7 && moveXdown < NineDivideWidth * 8 && moveYdown > NineDivideHeight * 8) {
                num = 55;
            }
            else if (moveXdown > NineDivideWidth * 8 && moveYdown > NineDivideHeight * 8) {
                num = 56;
            }
            break;
        case 56:
            if (moveXdown > ThreehalfWidth * 1 && moveXdown < ThreehalfWidth * 3 && moveYdown < ThreehalfHeight * 2) {
                num = 0;
            }
            else if (moveXdown < ThreehalfWidth * 1 && moveYdown < ThreehalfHeight) {
                num = 1;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown < ThreehalfHeight) {
                num = 2;
            }
            else if (moveXdown < ThreehalfWidth * 1 && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 3;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > ThreehalfHeight && moveYdown < ThreehalfHeight * 2) {
                num = 4;
            }
            else if (moveXdown < ThreehalfWidth && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 5;
            }
            else if (moveXdown > ThreehalfWidth && moveXdown < ThreehalfWidth * 2 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 6;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 7;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 2 && moveYdown < ThreehalfHeight * 3) {
                num = 8;
            }
            else if (moveXdown < ThreehalfWidth && moveYdown > ThreehalfHeight * 3) {
                num = 9;
            }
            else if (moveXdown > ThreehalfWidth && moveXdown < ThreehalfWidth * 2 && moveYdown > ThreehalfHeight * 3) {
                num = 10;
            }
            else if (moveXdown > ThreehalfWidth * 2 && moveXdown < ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 3) {
                num = 11;
            }
            else if (moveXdown > ThreehalfWidth * 3 && moveYdown > ThreehalfHeight * 3) {
                num = 12;
            }
            break;

        case 57:
            if (moveXdown < SixDivideWidth * 3 && moveYdown < SixDivideHeight * 3) {
                num = 0;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveYdown < SixDivideHeight * 3) {
                num = 1;
            }

            else if (moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 5) {
                num = 2;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 5) {
                num = 3;
            }

            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 4;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 5;
            }

            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 6;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 7;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 5) {
                num = 8;
            }
            else if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 5) {
                num = 9;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 5) {
                num = 10;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 5) {
                num = 11;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 12;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 13;
            }
            break;

            case 58:	
			if(moveXdown < EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 0;
			}
			else if(moveXdown > EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 1;
			}

            else if(moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*4)
            {
                num = 2;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 3;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 4;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 5;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 6;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 7;
            }

            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 8;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 9;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 10;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 11;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 12;
            }

            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 13;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 14;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 15;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 16;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 17;
            }

            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*7)
            {
                num = 18;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*7)
            {
                num = 19;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*7)
            {
                num = 20;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 21;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 22;
            }
			break;

		case 59:
            if(moveXdown < TwelveDivideWidth*1 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 0;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 1;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 2;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 3;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 4;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 5;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 6;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 7;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 8;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 9;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 10;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown < TwelveDivideHeight*1)
			{		
				num = 11;
			}

            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 12;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 13;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 14;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 15;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 16;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 17;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 18;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 19;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 20;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 21;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 22;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*1 && moveYdown < TwelveDivideHeight*2)
			{		
				num = 23;
			}

            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 24;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 25;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 26;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 27;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 28;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 29;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 30;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 31;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 32;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 33;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 34;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*2 && moveYdown < TwelveDivideHeight*3)
			{		
				num = 35;
			}

            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 36;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 37;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 38;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 39;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 40;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 41;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 42;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 43;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 44;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 45;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 46;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*3 && moveYdown < TwelveDivideHeight*4)
			{		
				num = 47;
			}

            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 48;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 49;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 50;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 51;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 52;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 53;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 54;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 55;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 56;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 57;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 58;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*4 && moveYdown < TwelveDivideHeight*5)
			{		
				num = 59;
			}

            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 60;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 61;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 62;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 63;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 64;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 65;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 66;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 67;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 68;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 69;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 70;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*5 && moveYdown < TwelveDivideHeight*6)
			{		
				num = 71;
			}

            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 72;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 73;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 74;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 75;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 76;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 77;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 78;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 79;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 80;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 81;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 82;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*6 && moveYdown < TwelveDivideHeight*7)
			{		
				num = 83;
			}

            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 84;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 85;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 86;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 87;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 88;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 89;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 90;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 91;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 92;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 93;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 94;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*7 && moveYdown < TwelveDivideHeight*8)
			{		
				num = 95;
			}
            
            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 96;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 97;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 98;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 99;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 100;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 101;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 102;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 103;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 104;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 105;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 106;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*8 && moveYdown < TwelveDivideHeight*9)
			{		
				num = 107;
			}

            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 108;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 109;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 110;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 111;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 112;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 113;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 114;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 115;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 116;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 117;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 118;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*9 && moveYdown < TwelveDivideHeight*10)
			{		
				num = 119;
			}

            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 120;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 121;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 122;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 123;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 124;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 125;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 126;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 127;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 128;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 129;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 130;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*10 && moveYdown < TwelveDivideHeight*11)
			{		
				num = 131;
			}

            else if(moveXdown < TwelveDivideWidth*1 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 132;
			}
            else if(moveXdown > TwelveDivideWidth*1 && moveXdown < TwelveDivideWidth*2 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 133;
			}
            else if(moveXdown > TwelveDivideWidth*2 && moveXdown < TwelveDivideWidth*3 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 134;
			}
            else if(moveXdown > TwelveDivideWidth*3 && moveXdown < TwelveDivideWidth*4 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 135;
			}
            else if(moveXdown > TwelveDivideWidth*4 && moveXdown < TwelveDivideWidth*5 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 136;
			}
            else if(moveXdown > TwelveDivideWidth*5 && moveXdown < TwelveDivideWidth*6 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 137;
			}
            else if(moveXdown > TwelveDivideWidth*6 && moveXdown < TwelveDivideWidth*7 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 138;
			}
            else if(moveXdown > TwelveDivideWidth*7 && moveXdown < TwelveDivideWidth*8 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 139;
			}
            else if(moveXdown > TwelveDivideWidth*8 && moveXdown < TwelveDivideWidth*9 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 140;
			}
            else if(moveXdown > TwelveDivideWidth*9 && moveXdown < TwelveDivideWidth*10 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 141;
			}
            else if(moveXdown > TwelveDivideWidth*10 && moveXdown < TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 142;
			}
            else if(moveXdown > TwelveDivideWidth*11 && moveYdown > TwelveDivideHeight*11)
			{		
				num = 143;
			}
            break;

        case 60:
			if(moveXdown < SixDivideWidth*3 && moveYdown < SixDivideHeight*3)
			{		
				num = 0;
			}
            else if(moveXdown > SixDivideWidth*3 && moveYdown < SixDivideHeight*3)
			{		
				num = 1;
			}

            else if(moveXdown < SixDivideWidth*2 && moveYdown > SixDivideHeight*3 && moveYdown < SixDivideHeight*5)
			{		
				num = 2;
			}
            else if(moveXdown > SixDivideWidth*2 && moveXdown < SixDivideWidth*4 && moveYdown > SixDivideHeight*3 && moveYdown < SixDivideHeight*5)
			{		
				num = 3;
			}
            else if(moveXdown > SixDivideWidth*4 && moveYdown > SixDivideHeight*3 && moveYdown < SixDivideHeight*5)
			{		
				num = 4;
			}

            else if(moveXdown < SixDivideWidth*1 && moveYdown > SixDivideHeight*5)
			{		
				num = 5;
			}
            else if(moveXdown > SixDivideWidth*1 && moveXdown < SixDivideWidth*2 && moveYdown > SixDivideHeight*5)
			{		
				num = 6;
			}
            else if(moveXdown > SixDivideWidth*2 && moveXdown < SixDivideWidth*3 && moveYdown > SixDivideHeight*5)
			{		
				num = 7;
			}
            else if(moveXdown > SixDivideWidth*3 && moveXdown < SixDivideWidth*4 && moveYdown > SixDivideHeight*5)
			{		
				num = 8;
			}
            else if(moveXdown > SixDivideWidth*4 && moveXdown < SixDivideWidth*5 && moveYdown > SixDivideHeight*5)
			{		
				num = 9;
			}
            else if(moveXdown > SixDivideWidth*5 && moveYdown > SixDivideHeight*5)
			{		
				num = 10;
			}
            break;

		case 61:	
			if(moveXdown < EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 0;
			}
			else if(moveXdown > EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 1;
			}

            else if(moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 2;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 3;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 4;
            }
            else if(moveXdown > EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 5;
            }

            else if(moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*6)
            {
                num = 6;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*6)
            {
                num = 7;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*6)
            {
                num = 8;
            }
            
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 9;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 10;
            }

            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 11;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 12;
            }
			break;

		case 62:	
			if(moveXdown < EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 0;
			}
			else if(moveXdown > EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 1;
			}

            else if(moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 2;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 3;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 4;
            }
            else if(moveXdown > EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 5;
            }

            else if(moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*6)
            {
                num = 6;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*6)
            {
                num = 7;
            }
            
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 8;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 9;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 10;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 11;
            }

            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*7)
            {
                num = 12;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*7)
            {
                num = 13;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 14;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 15;
            }
			break;

		case 63:	
			if(moveXdown < EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 0;
			}
			else if(moveXdown > EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 1;
			}

            else if(moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 2;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 3;
            }
            else if(moveXdown > EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 4;
            }

            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*6)
            {
                num = 5;
            }
            else if(moveXdown > EightDivideWidth*6 && moveYdown > EightDivideHeight*6)
            {
                num = 6;
            }

            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 7;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 8;
            }

            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 9;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 10;
            }
            
            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 11;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 12;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 13;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 14;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*7)
            {
                num = 15;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*7)
            {
                num = 16;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*7)
            {
                num = 17;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*7)
            {
                num = 18;
            }
			break;

		case 64:	
			if(moveXdown < EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 0;
			}
			else if(moveXdown > EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 1;
			}

            else if(moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 2;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 3;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 4;
            }
            else if(moveXdown > EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 5;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 6;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 7;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 8;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 9;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 10;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 11;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 12;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 13;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*7)
            {
                num = 14;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*7)
            {
                num = 15;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*7)
            {
                num = 16;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*7)
            {
                num = 17;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*7)
            {
                num = 18;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*7)
            {
                num = 19;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 20;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 21;
            }
			break;

		case 65:	
			if(moveXdown < EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 0;
			}
			else if(moveXdown > EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 1;
			}

            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 2;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 3;
            }
            else if(moveXdown > EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 4;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 5;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 6;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 7;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 8;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 9;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 10;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 11;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 12;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 13;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 14;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 15;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 16;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*7)
            {
                num = 17;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*7)
            {
                num = 18;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*7)
            {
                num = 19;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*7)
            {
                num = 20;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*7)
            {
                num = 21;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*7)
            {
                num = 22;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 23;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 24;
            }
			break;

		case 66:	
			if(moveXdown < EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 0;
			}
			else if(moveXdown > EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 1;
			}

            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 2;
            }
            else if(moveXdown > EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 3;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 4;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 5;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 6;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 7;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 8;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 9;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 10;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 11;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 12;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 13;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 14;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 15;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 16;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 17;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 18;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 19;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*7)
            {
                num = 20;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*7)
            {
                num = 21;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*7)
            {
                num = 22;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*7)
            {
                num = 23;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*7)
            {
                num = 24;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*7)
            {
                num = 25;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 26;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 27;
            }
			break;

		case 67:	
			if(moveXdown < NineDivideWidth*5 && moveYdown < NineDivideHeight*5)
			{
				num = 0;
			}
            else if(moveXdown > NineDivideWidth*5 && moveYdown < NineDivideHeight*4)
            {
                num = 1;
            }

            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*4 && moveYdown < NineDivideHeight*6)
            {
                num = 2;
            }
            else if(moveXdown > NineDivideWidth*7 && moveYdown > NineDivideHeight*4 && moveYdown < NineDivideHeight*6)
            {
                num = 3;
            }

            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*8)
            {
                num = 4;
            }
            else if(moveXdown > NineDivideWidth*7 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*8)
            {
                num = 5;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 6;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 7;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 8;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 9;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 10;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 11;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 12;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 13;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 14;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 15;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 16;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 17;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 18;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 19;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 20;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*8)
            {
                num = 21;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*8)
            {
                num = 22;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*8)
            {
                num = 23;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*8)
            {
                num = 24;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*8)
            {
                num = 25;
            }
            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*6 && moveYdown > NineDivideHeight*8)
            {
                num = 26;
            }
            else if(moveXdown > NineDivideWidth*6 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*8)
            {
                num = 27;
            }
            else if(moveXdown > NineDivideWidth*7 && moveXdown < NineDivideWidth*8 && moveYdown > NineDivideHeight*8)
            {
                num = 28;
            }
            else if(moveXdown > NineDivideWidth*8 && moveYdown > NineDivideHeight*8)
            {
                num = 29;
            }
			break;

		case 68:	
			if(moveXdown < EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 0;
			}
			else if(moveXdown > EightDivideWidth*4 && moveYdown < EightDivideHeight*4)
			{
				num = 1;
			}

            else if(moveXdown > EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*6)
            {
                num = 2;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 3;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 4;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 5;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 6;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 7;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*4 && moveYdown < EightDivideHeight*5)
            {
                num = 8;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 9;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 10;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 11;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 12;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 13;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*5 && moveYdown < EightDivideHeight*6)
            {
                num = 14;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 15;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 16;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 17;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 18;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 19;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 20;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 21;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*6 && moveYdown < EightDivideHeight*7)
            {
                num = 22;
            }

            else if(moveXdown < EightDivideWidth*1 && moveYdown > EightDivideHeight*7)
            {
                num = 23;
            }
            else if(moveXdown > EightDivideWidth*1 && moveXdown < EightDivideWidth*2 && moveYdown > EightDivideHeight*7)
            {
                num = 24;
            }
            else if(moveXdown > EightDivideWidth*2 && moveXdown < EightDivideWidth*3 && moveYdown > EightDivideHeight*7)
            {
                num = 25;
            }
            else if(moveXdown > EightDivideWidth*3 && moveXdown < EightDivideWidth*4 && moveYdown > EightDivideHeight*7)
            {
                num = 26;
            }
            else if(moveXdown > EightDivideWidth*4 && moveXdown < EightDivideWidth*5 && moveYdown > EightDivideHeight*7)
            {
                num = 27;
            }
            else if(moveXdown > EightDivideWidth*5 && moveXdown < EightDivideWidth*6 && moveYdown > EightDivideHeight*7)
            {
                num = 28;
            }
            else if(moveXdown > EightDivideWidth*6 && moveXdown < EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 29;
            }
            else if(moveXdown > EightDivideWidth*7 && moveYdown > EightDivideHeight*7)
            {
                num = 30;
            }
			break;

		case 69:	
			if(moveXdown < NineDivideWidth*5 && moveYdown < NineDivideHeight*5)
			{
				num = 0;
			}
            else if(moveXdown > NineDivideWidth*5 && moveYdown < NineDivideHeight*4)
            {
                num = 1;
            }

            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*4 && moveYdown < NineDivideHeight*6)
            {
                num = 2;
            }
            else if(moveXdown > NineDivideWidth*7 && moveYdown > NineDivideHeight*4 && moveYdown < NineDivideHeight*6)
            {
                num = 3;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 4;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 5;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 6;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 7;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 8;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 9;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 10;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 11;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 12;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 13;
            }
            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*6 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 14;
            }
            else if(moveXdown > NineDivideWidth*6 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 15;
            }
            else if(moveXdown > NineDivideWidth*7 && moveXdown < NineDivideWidth*8 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 16;
            }
            else if(moveXdown > NineDivideWidth*8 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 17;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 18;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 19;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 20;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 21;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 22;
            }
            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*6 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 23;
            }
            else if(moveXdown > NineDivideWidth*6 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 24;
            }
            else if(moveXdown > NineDivideWidth*7 && moveXdown < NineDivideWidth*8 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 25;
            }
            else if(moveXdown > NineDivideWidth*8 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 26;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*8)
            {
                num = 27;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*8)
            {
                num = 28;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*8)
            {
                num = 29;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*8)
            {
                num = 30;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*8)
            {
                num = 31;
            }
            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*6 && moveYdown > NineDivideHeight*8)
            {
                num = 32;
            }
            else if(moveXdown > NineDivideWidth*6 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*8)
            {
                num = 33;
            }
            else if(moveXdown > NineDivideWidth*7 && moveXdown < NineDivideWidth*8 && moveYdown > NineDivideHeight*8)
            {
                num = 34;
            }
            else if(moveXdown > NineDivideWidth*8 && moveYdown > NineDivideHeight*8)
            {
                num = 35;
            }
			break;

		case 70:	
			if(moveXdown < NineDivideWidth*5 && moveYdown < NineDivideHeight*5)
			{
				num = 0;
			}
            else if(moveXdown > NineDivideWidth*5 && moveYdown < NineDivideHeight*4)
            {
                num = 1;
            }

            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*4 && moveYdown < NineDivideHeight*6)
            {
                num = 2;
            }

            else if(moveXdown > NineDivideWidth*7 && moveXdown < NineDivideWidth*8 && moveYdown > NineDivideHeight*4 && moveYdown < NineDivideHeight*5)
            {
                num = 3;
            }
            else if(moveXdown > NineDivideWidth*8 && moveYdown > NineDivideHeight*4 && moveYdown < NineDivideHeight*5)
            {
                num = 4;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 5;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 6;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 7;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 8;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 9;
            }
            else if(moveXdown > NineDivideWidth*7 && moveXdown < NineDivideWidth*8 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 10;
            }
            else if(moveXdown > NineDivideWidth*8 && moveYdown > NineDivideHeight*5 && moveYdown < NineDivideHeight*6)
            {
                num = 11;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 12;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 13;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 14;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 15;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 16;
            }
            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*6 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 17;
            }
            else if(moveXdown > NineDivideWidth*6 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 18;
            }
            else if(moveXdown > NineDivideWidth*7 && moveXdown < NineDivideWidth*8 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 19;
            }
            else if(moveXdown > NineDivideWidth*8 && moveYdown > NineDivideHeight*6 && moveYdown < NineDivideHeight*7)
            {
                num = 20;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 21;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 22;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 23;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 24;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 25;
            }
            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*6 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 26;
            }
            else if(moveXdown > NineDivideWidth*6 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 27;
            }
            else if(moveXdown > NineDivideWidth*7 && moveXdown < NineDivideWidth*8 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 28;
            }
            else if(moveXdown > NineDivideWidth*8 && moveYdown > NineDivideHeight*7 && moveYdown < NineDivideHeight*8)
            {
                num = 29;
            }

            else if(moveXdown < NineDivideWidth*1 && moveYdown > NineDivideHeight*8)
            {
                num = 30;
            }
            else if(moveXdown > NineDivideWidth*1 && moveXdown < NineDivideWidth*2 && moveYdown > NineDivideHeight*8)
            {
                num = 31;
            }
            else if(moveXdown > NineDivideWidth*2 && moveXdown < NineDivideWidth*3 && moveYdown > NineDivideHeight*8)
            {
                num = 32;
            }
            else if(moveXdown > NineDivideWidth*3 && moveXdown < NineDivideWidth*4 && moveYdown > NineDivideHeight*8)
            {
                num = 33;
            }
            else if(moveXdown > NineDivideWidth*4 && moveXdown < NineDivideWidth*5 && moveYdown > NineDivideHeight*8)
            {
                num = 34;
            }
            else if(moveXdown > NineDivideWidth*5 && moveXdown < NineDivideWidth*6 && moveYdown > NineDivideHeight*8)
            {
                num = 35;
            }
            else if(moveXdown > NineDivideWidth*6 && moveXdown < NineDivideWidth*7 && moveYdown > NineDivideHeight*8)
            {
                num = 36;
            }
            else if(moveXdown > NineDivideWidth*7 && moveXdown < NineDivideWidth*8 && moveYdown > NineDivideHeight*8)
            {
                num = 37;
            }
            else if(moveXdown > NineDivideWidth*8 && moveYdown > NineDivideHeight*8)
            {
                num = 38;
            }
            break;

        case 71:
            if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 8 && moveYdown < TenDivideHeight * 6) {
                num = 0;
            }

            else if (moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 8) {
                num = 1;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 8) {
                num = 2;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 8) {
                num = 3;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown < TenDivideHeight * 1) {
                num = 4;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown < TenDivideHeight * 1) {
                num = 5;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 6;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 7;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 8;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 9;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 10;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight && moveYdown < TenDivideHeight * 2) {
                num = 11;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 12;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 14;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 15;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 16;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 17;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 18;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 19;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 20;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 21;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 23;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 24;
            }
            else if (moveXdown > TenDivideWidth && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 27;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 30;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 31;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 33;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 34;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 35;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 36;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 37;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 38;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 39;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 40;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 41;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 42;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 43;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 44;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 45;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 9) {
                num = 46;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 9) {
                num = 47;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 48;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 49;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 50;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 51;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 52;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 53;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 54;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 55;
            }
            break;

        case 72:
            if (moveXdown < SixDivideWidth * 4 && moveYdown < SixDivideHeight * 4) {
                num = 0;
            }

            else if (moveXdown > SixDivideWidth * 4 && moveYdown < SixDivideHeight * 2) {
                num = 1;
            }

            else if (moveXdown > SixDivideWidth * 4 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 4) {
                num = 2;
            }

            else if (moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 4) {
                num = 3;
            }
            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 4) {
                num = 4;
            }

            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 5;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 6;
            }

            else if (moveXdown > SixDivideWidth * 4 && moveXdown < SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 7;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 5) {
                num = 8;
            }
            break;

        case 73:
            if (moveXdown < FourhalfWidth * 4 && moveYdown < FourhalfHeight * 4) {
                num = 0;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown < FourhalfHeight * 1) {
                num = 1;
            }

            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 1 && moveYdown < FourhalfHeight * 2) {
                num = 2;
            }

            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 3;
            }

            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 4;
            }

            else if (moveXdown < FourhalfWidth * 1 && moveYdown > FourhalfHeight * 4) {
                num = 5;
            }
            else if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 4) {
                num = 6;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 4) {
                num = 7;
            }
            else if (moveXdown > FourhalfWidth * 3 && moveXdown < FourhalfWidth * 4 && moveYdown > FourhalfHeight * 4) {
                num = 8;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 4) {
                num = 9;
            }
            break;

        case 74:
            if (moveXdown < EightDivideWidth * 6 && moveYdown < EightDivideHeight * 6) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown < EightDivideHeight * 2) {
                num = 1;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 4) {
                num = 2;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 6) {
                num = 3;
            }

            else if (moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6) {
                num = 5;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6) {
                num = 6;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 7;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 8;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 9;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 7) {
                num = 10;
            }
            break;

        case 75:
            if (moveXdown < TenDivideWidth * 8 && moveYdown < TenDivideHeight * 8) {
                num = 0;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveYdown < TenDivideHeight * 2) {
                num = 1;
            }

            else if (moveXdown > TenDivideWidth * 8 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 4) {
                num = 2;
            }

            else if (moveXdown > TenDivideWidth * 8 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 6) {
                num = 3;
            }

            else if (moveXdown > TenDivideWidth * 8 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 8) {
                num = 4;
            }

            else if (moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8) {
                num = 5;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8) {
                num = 6;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8) {
                num = 8;
            }

            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 9;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 10;
            }

            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 11;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 12;
            }
            break;

        case 76:
            if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 4 && moveYdown < FourhalfHeight * 3) {
                num = 0;
            }

            else if (moveXdown > FourhalfWidth * 3 && moveYdown > FourhalfHeight * 3) {
                num = 1;
            }

            else if (moveXdown < FourhalfWidth * 1 && moveYdown < FourhalfHeight * 1) {
                num = 2;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown < FourhalfHeight * 1) {
                num = 3;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 4;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight && moveYdown < FourhalfHeight * 2) {
                num = 5;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 6;
            }
            else if (moveXdown > FourhalfWidth * 4 && moveYdown > FourhalfHeight * 2 && moveYdown < FourhalfHeight * 3) {
                num = 7;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 8;
            }
            else if (moveXdown > FourhalfWidth * 1 && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 9;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 3 && moveYdown < FourhalfHeight * 4) {
                num = 10;
            }

            else if (moveXdown < FourhalfWidth && moveYdown > FourhalfHeight * 4) {
                num = 11;
            }
            else if (moveXdown > FourhalfWidth && moveXdown < FourhalfWidth * 2 && moveYdown > FourhalfHeight * 4) {
                num = 12;
            }
            else if (moveXdown > FourhalfWidth * 2 && moveXdown < FourhalfWidth * 3 && moveYdown > FourhalfHeight * 4) {
                num = 13;
            }
            break;

        case 77:
            if (moveXdown > SixDivideWidth * 1 && moveXdown < SixDivideWidth * 5 && moveYdown < SixDivideHeight * 4) {
                num = 0;
            }

            else if (moveXdown < SixDivideWidth * 2 && moveYdown > SixDivideHeight * 4) {
                num = 1;
            }
            else if (moveXdown > SixDivideWidth * 4 && moveYdown > SixDivideHeight * 4) {
                num = 2;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown < SixDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown < SixDivideHeight * 1) {
                num = 4;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 5;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 1 && moveYdown < SixDivideHeight * 2) {
                num = 6;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 7;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 2 && moveYdown < SixDivideHeight * 3) {
                num = 8;
            }

            else if (moveXdown < SixDivideWidth * 1 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 9;
            }
            else if (moveXdown > SixDivideWidth * 5 && moveYdown > SixDivideHeight * 3 && moveYdown < SixDivideHeight * 4) {
                num = 10;
            }

            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 11;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 4 && moveYdown < SixDivideHeight * 5) {
                num = 12;
            }

            else if (moveXdown > SixDivideWidth * 2 && moveXdown < SixDivideWidth * 3 && moveYdown > SixDivideHeight * 5) {
                num = 13;
            }
            else if (moveXdown > SixDivideWidth * 3 && moveXdown < SixDivideWidth * 4 && moveYdown > SixDivideHeight * 5) {
                num = 14;
            }
            break;

        case 78:
            if (moveXdown < EightDivideWidth * 6 && moveYdown < EightDivideHeight * 6) {
                num = 0;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown < EightDivideHeight * 2) {
                num = 1;
            }
            else if (moveXdown < EightDivideWidth * 2 && moveYdown > EightDivideHeight * 6) {
                num = 2;
            }
            else if (moveXdown > EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6) {
                num = 3;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 4;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 2 && moveYdown < EightDivideHeight * 3) {
                num = 5;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 6;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 3 && moveYdown < EightDivideHeight * 4) {
                num = 7;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 8;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 4 && moveYdown < EightDivideHeight * 5) {
                num = 9;
            }

            else if (moveXdown > EightDivideWidth * 6 && moveXdown < EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 10;
            }
            else if (moveXdown > EightDivideWidth * 7 && moveYdown > EightDivideHeight * 5 && moveYdown < EightDivideHeight * 6) {
                num = 11;
            }

            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 12;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 6 && moveYdown < EightDivideHeight * 7) {
                num = 15;
            }

            else if (moveXdown > EightDivideWidth * 2 && moveXdown < EightDivideWidth * 3 && moveYdown > EightDivideHeight * 7) {
                num = 16;
            }
            else if (moveXdown > EightDivideWidth * 3 && moveXdown < EightDivideWidth * 4 && moveYdown > EightDivideHeight * 7) {
                num = 17;
            }
            else if (moveXdown > EightDivideWidth * 4 && moveXdown < EightDivideWidth * 5 && moveYdown > EightDivideHeight * 7) {
                num = 18;
            }
            else if (moveXdown > EightDivideWidth * 5 && moveXdown < EightDivideWidth * 6 && moveYdown > EightDivideHeight * 7) {
                num = 19;
            }
            break;

        case 79:
            if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 9 && moveYdown < TenDivideHeight * 8) {
                num = 0;
            }
            else if (moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8) {
                num = 1;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8) {
                num = 2;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8) {
                num = 3;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown < TenDivideHeight * 1) {
                num = 4;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 5;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 1 && moveYdown < TenDivideHeight * 2) {
                num = 6;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 1 && moveYdown < TenDivideHeight * 2) {
                num = 7;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 8;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 9;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 10;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 11;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 12;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 13;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 14;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 15;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 16;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 17;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 18;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 19;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 20;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 21;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 23;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 24;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 27;
            }
            break;

        case 80:
            if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 9 && moveYdown < TenDivideHeight * 8) {
                num = 0;
            }
            else if (moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8) {
                num = 1;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8) {
                num = 2;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown < TenDivideHeight * 1) {
                num = 3;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 4;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 1 && moveYdown < TenDivideHeight * 2) {
                num = 5;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 1 && moveYdown < TenDivideHeight * 2) {
                num = 6;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 8;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 9;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 10;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 11;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 12;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 14;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 15;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 16;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 17;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 18;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 20;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 21;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 24;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 27;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 30;
            }
            break;

        case 81:
            if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 9 && moveYdown < TenDivideHeight * 8) {
                num = 0;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8) {
                num = 1;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown < TenDivideHeight * 1) {
                num = 2;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown < TenDivideHeight * 1) {
                num = 3;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 1 && moveYdown < TenDivideHeight * 2) {
                num = 4;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 1 && moveYdown < TenDivideHeight * 2) {
                num = 5;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 6;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 2 && moveYdown < TenDivideHeight * 3) {
                num = 7;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 8;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 9;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 10;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 11;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 12;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 13;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 15;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 16;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 17;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 18;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 20;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 21;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 24;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 25;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 9) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 9) {
                num = 27;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 30;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 31;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 33;
            }
            break;

        case 82:
            if (moveXdown < TenDivideWidth * 7 && moveYdown < TenDivideHeight * 7) {
                num = 0;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveYdown < TenDivideHeight * 3) {
                num = 1;
            }
            else if (moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 9) {
                num = 2;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 9) {
                num = 3;
            }

            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 4;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 5;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 6;
            }

            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 8;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 9;
            }

            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 10;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 11;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 12;
            }

            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 14;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 15;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 16;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 17;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 18;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 20;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 21;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 22;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 24;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 27;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 9) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 9) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 30;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 31;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 33;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 34;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 35;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 36;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 37;
            }
            break;

        case 83:
            if (moveXdown < TenDivideWidth * 7 && moveYdown < TenDivideHeight * 7) {
                num = 0;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveYdown < TenDivideHeight * 3) {
                num = 1;
            }
            else if (moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 9) {
                num = 2;
            }

            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 3;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 4;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 3 && moveYdown < TenDivideHeight * 4) {
                num = 5;
            }

            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 6;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 7;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 4 && moveYdown < TenDivideHeight * 5) {
                num = 8;
            }

            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 9;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 10;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 5 && moveYdown < TenDivideHeight * 6) {
                num = 11;
            }

            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 12;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 13;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 6 && moveYdown < TenDivideHeight * 7) {
                num = 14;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 15;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 16;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 17;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 18;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 19;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 20;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 21;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 7 && moveYdown < TenDivideHeight * 8) {
                num = 22;
            }

            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 23;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 24;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 25;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 26;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 27;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 28;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 29;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 8 && moveYdown < TenDivideHeight * 9) {
                num = 30;
            }

            else if (moveXdown < TenDivideWidth * 1 && moveYdown > TenDivideHeight * 9) {
                num = 31;
            }
            else if (moveXdown > TenDivideWidth * 1 && moveXdown < TenDivideWidth * 2 && moveYdown > TenDivideHeight * 9) {
                num = 32;
            }
            else if (moveXdown > TenDivideWidth * 2 && moveXdown < TenDivideWidth * 3 && moveYdown > TenDivideHeight * 9) {
                num = 33;
            }
            else if (moveXdown > TenDivideWidth * 3 && moveXdown < TenDivideWidth * 4 && moveYdown > TenDivideHeight * 9) {
                num = 34;
            }
            else if (moveXdown > TenDivideWidth * 4 && moveXdown < TenDivideWidth * 5 && moveYdown > TenDivideHeight * 9) {
                num = 35;
            }
            else if (moveXdown > TenDivideWidth * 5 && moveXdown < TenDivideWidth * 6 && moveYdown > TenDivideHeight * 9) {
                num = 36;
            }
            else if (moveXdown > TenDivideWidth * 6 && moveXdown < TenDivideWidth * 7 && moveYdown > TenDivideHeight * 9) {
                num = 37;
            }
            else if (moveXdown > TenDivideWidth * 7 && moveXdown < TenDivideWidth * 8 && moveYdown > TenDivideHeight * 9) {
                num = 38;
            }
            else if (moveXdown > TenDivideWidth * 8 && moveXdown < TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 39;
            }
            else if (moveXdown > TenDivideWidth * 9 && moveYdown > TenDivideHeight * 9) {
                num = 40;
            }
            break;
    }
    return num;

}