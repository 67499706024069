import axios from "axios";
import { action, headers } from "Common/WSCommon";
import Footer from "Components/Footer";
import Header from "Components/Header";
import Popup from "Components/Popup";
import RefreshToken from "Components/RefreshToken";
import TopMenuRoute from "Components/TopMenuRoute";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


function UserPage(){

    const location = useLocation();
    const preData = location.state || null;

    const [userCount, setUserCount] = useState();
    const [userData, setUserData] = useState();
    const [user, setUser] = useState();
    const [endPageNum, setEndPageNum] = useState(1);
    const [data, setData] = useState([]);
    const [part, setPart] = useState();
    const [partList, setPartList] = useState();
    const [pageList, setPageList] = useState();
    const [pageNum, setPageNum] = useState(1);
    const [userModifyPop, setUserModifyPop] = useState(false);
    const [userAddPop, setUserAddPop] = useState(false);

    

    const navigate = useNavigate();

    useEffect(() => {
        loadPartList();
    },[]);

    useEffect(() => {
        loadPageList();
    }, [endPageNum]);

    useEffect(() => {
        loadUserData();
    }, [pageNum]);

    useEffect (() => {
        setData(preData);
        if(data){
            setPart(data.part);
        }
    }, [data]);

    async function loadUserData(){
        const postData = {
            "pageNum": pageNum
        };

        try{
            const res = await axios.post(action + "LoadUserListAction", JSON.stringify(postData), headers());
            console.log(res.data);
            setUserData(res.data);
            const resCnt = await axios.post(action + "LoadUserCountAction", JSON.stringify(postData), headers());
            setUserCount(resCnt.data);
            setEndPageNum(resCnt.data.endPageNum);
        }catch{
            sessionStorage.removeItem("token");
            navigate("/Login");
        }
    }

    function loadPageList(){
        let pageList = new Array;
        if(pageNum < 4){
            for(let i = 1; i <= endPageNum; i++){
                if(i > 5){
                    break;
                }
                pageList.push(i);
            }
        }else if(pageNum + 2 > endPageNum){
            pageList.push(endPageNum - 4);
            pageList.push(endPageNum - 3);
            pageList.push(endPageNum - 2);
            pageList.push(endPageNum - 1);
            pageList.push(endPageNum);
        }else{
            pageList.push(pageNum - 2);
            pageList.push(pageNum - 1);
            pageList.push(pageNum);
            pageList.push(pageNum + 1);
            pageList.push(pageNum + 2);
        }
        setPageList(pageList);
    }

    async function loadPartList(){

        try{
            const res = await axios.post(action + "LoadPartListAction", "", headers());
            setPartList(res.data);
        }catch{
            sessionStorage.removeItem("token");
            navigate("/Login");
        }
    }

    function handlePageNum(item){
        if(item < 1 || item > endPageNum){
            return false;
        }else{
            setPageNum(item);
        }
    }

    function handleModify(user){
        setUser(user);
        handleUserModifyPop("true");
    }

    function handleUserAdd(){
        handleUserAddPop("true");
    }

    function handleUserModifyPop(state){
        setUserModifyPop(state);
    }

    function handleUserAddPop(state){
        setUserAddPop(state);
    }

    async function removeUser(user){
        let ur = window.confirm("삭제하시겠습니까?");
        if(ur){
            const postData = {
                "id": user.id
            };

            try{
                await axios.post(action + "RemoveUserAction", JSON.stringify(postData), headers());
                loadUserData();
            }catch(ex){
                console.log(ex);
            }
        }
    }

    return(
        <div className="container">
            <RefreshToken></RefreshToken>
            <div className="wrap">
                {data.level && (
                    <Header route={"user"} level={data.level}></Header>
                )}
                <div className="content">
                    <TopMenuRoute route={"user"} handleUserAdd={handleUserAdd}></TopMenuRoute>
                    <div className="video-count">
                        <h1>Total</h1>
                        {userCount && (
                            <h2>{userCount.count}</h2>
                        )}
                        <h1>건</h1>
                        <h1>/</h1>
                        {endPageNum && (
                            <h1>{endPageNum}</h1>
                        )}
                        <h1>Page</h1>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    <span>사용자</span>
                                </th>
                                <th>
                                    <span>아이디</span>
                                </th>
                                <th>
                                    <span>소속</span>
                                </th>
                                <th>
                                    <span>권한</span>
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData && (
                                userData.userList.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span>{item.name}</span>
                                        </td>
                                        <td>
                                            <span>{item.id}</span>
                                        </td>
                                        <td>
                                            <span>{item.part}</span>
                                        </td>
                                        <td>
                                            <span>{item.level}</span>
                                        </td>
                                        <td>
                                            <>
                                            <button className="green" onClick={() => handleModify(item)}>수정</button>
                                            <button className="red" onClick={() => removeUser(item)}>삭제</button>
                                            </>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <ul className="paging">
                        <li onClick={() => handlePageNum(1)}>
                            <img src={require("Img/page_prev_02.png")}></img>
                        </li>
                        <li onClick={() => handlePageNum(Number(pageNum) - 1)}>
                            <img src={require("Img/page_prev_01.png")}></img>
                        </li>
                        {pageList && (
                            pageList.map((item, index) => (
                                <li key={index} className={pageNum == item ? "active" : ""} onClick={() => handlePageNum(item)}>
                                    {item}
                                </li>
                            ))
                        )}
                        <li onClick={() => handlePageNum(Number(pageNum) + 1)}>
                            <img src={require("Img/page_next_01.png")}></img>
                        </li>
                        <li onClick={() => handlePageNum(Number(endPageNum))}>
                            <img src={require("Img/page_next_02.png")}></img>
                        </li>
                    </ul>
                </div>
                <Footer></Footer>
            </div>
            {userModifyPop == "true" && (
                <Popup param={"userModify"} popAction={handleUserModifyPop} userData={user} partList={partList}></Popup>
            )}
            {userAddPop == "true" && (
                <Popup param={"userAdd"} popAction={handleUserAddPop} partList={partList}></Popup>
            )}
        </div>
    );
}

export default UserPage;