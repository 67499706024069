import { useState } from "react";
import Popup from "./Popup";


function TopMenuRoute({route, handleRoomAdd, handleRoomRemove, handleUserAdd}){

    return(
        <>
            <div className="top-menu">
                <ul className="route">
                    <li>
                        <img src={require("Img/home-icon.png")}></img>
                    </li>
                    {route == "server" && (
                        <>
                            <li>MCU 목록</li>
                        </>
                    )}
                    {route == "room" && (
                        <>
                            <li>MCU 목록</li>
                            <li><img src={require("Img/route-arrow.png")}></img></li>
                            <li>방목록</li>
                        </>
                    )}
                    {route == "video" && (
                        <>
                            <li>녹화 영상</li>
                        </>
                    )} 
                    {route == "user" && (
                        <>
                            <li>사용자 관리</li>
                        </>
                    )}   
                    {route == "stat" && (
                        <>
                            <li>통계 자료</li>
                        </>
                    )}      
                </ul>
                {route == "user" && (
                    <div className="top-menu-room">
                        <button className="room-btn add" onClick={() => handleUserAdd("true")}>
                            <img src={require("Img/add-icon.png")}></img>
                            사용자 생성</button>
                    </div>
                )}
                {route == "room" && (
                    <div className="top-menu-room">
                        <button className="room-btn add" onClick={() => handleRoomAdd("true")}>
                            <img src={require("Img/add-icon.png")}></img>
                            방 생성</button>
                        <button className="room-btn remove" onClick={() => handleRoomRemove()}>
                            <img src={require("Img/delete-icon.png")} ></img>
                            방 삭제</button>
                    </div>
                )}
                
            </div>
            
        </>
    );
}

export default TopMenuRoute;