import { useLayoutEffect, useState } from "react";
import RefreshToken from "Components/RefreshToken";
import Header from "Components/Header";
import TopMenuRoute from "Components/TopMenuRoute";
import Footer from "Components/Footer";
import { page, headers } from "Common/WSCommon";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function ServerPage(){
    const location = useLocation();
    const preData = location.state || null;
    const [data, setData] = useState([]);

    const navigate = useNavigate();

    useLayoutEffect (() => {
        setData(preData);
    }, [data]);

    async function goRoomPage(ip){
        const postData = {
            "ip": ip
        };
        try{
            const pre = await axios.post(page + "RoomPage", JSON.stringify(postData), headers());
            navigate("/Room/", {state : pre.data});
        }catch{
            sessionStorage.removeItem("token");
            navigate("/Login");
        }
        
    }

    return(
        <div className="container">
            <RefreshToken></RefreshToken>
            <div className="wrap">
                {data.level && (
                    <Header route={"server"} level={data.level}></Header>
                )}
                <div className="content">
                    <TopMenuRoute route={"server"}></TopMenuRoute>
                    <ul className="server-list">
                        {data.servers && (
                            data.servers.map((server, index) => (
                                <li key={index} onClick={() => goRoomPage(server.ip)}>
                                    <img src={require("Img/server-icon.png")}></img>
                                    <h2>{server.name}</h2>
                                    <span>서버 입장</span>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
                <Footer></Footer>
            </div>
        </div>
    );
}

export default ServerPage;