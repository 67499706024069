import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { action, headers } from "../Common/WSCommon";

function RefreshToken(){
    
    const navigate = useNavigate();

    useEffect(() => {
        Refresh();
    });

    async function Refresh(){
        try{
            const res = await axios.post(action + "RefreshTokenAction", "", headers());
            sessionStorage.removeItem("token");
            sessionStorage.setItem("token", res.data);
        }catch(ex){
            // sessionStorage.removeItem("token");
            // navigate("/Login");
        }
    }
}

export default RefreshToken;