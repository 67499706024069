import { useLayoutEffect, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { action, headers } from "Common/WSCommon";
import axios from "axios";

function Panel({isMobile, control, area, num, loadData, loadPreset, preData, hideState, setHideState}){

    const autoRotationStateTrue = useRef();
    const autoRotationStateFalse = useRef();
    const autoRotationExceptRef = useRef();
    const autoRotationFreeRef = useRef();
    const groupAutoRotationStateTrue = useRef();
    const groupAutoRotationStateFalse = useRef();
    const layoutPanelRef = useRef();
    const preferPanelRef = useRef();
    const preferContainerRef = useRef();
    const removePreferBtnRef = useRef();
    const muteExceptRef = useRef();
    const muteFreeRef = useRef();
    const micRef = useRef();
    const levelRef = useRef();
    const kickRef = useRef();
    const tailExceptRef = useRef();
    const tailFreeRef = useRef();
    const hideRef = useRef();
    const cameraRef = useRef();
    const camRef = useRef();
    const textRef = useRef();

    const [data, setData] = useState();
    const [isVisible, setIsVisible] = useState([]);
    const [autoRotationTime, setAutoRotationTime] = useState(0);
    const [autoRotationExceptList, setAutoRotationExceptList] = useState([]);
    const [autoRotationFreeList, setAutoRotationFreeList] = useState([]);
    const [autoRotationExceptCount , setAutoRotationExceptCount] = useState(0);
    const [groupAutoRotationTime, setGroupAutoRotationTime] = useState(0);
    const [groupAutoRotationSlot, setGroupAutoRotationSlot] = useState(0);
    const [autoLayoutIdx, setAutoLayoutIdx] = useState(0);
    const [layoutList, setLayoutList] = useState([]);
    const [preferList, setPreferList] = useState([]);
    const [layout, setLayout] = useState();
    const [preferMode, setPreferMode] = useState(false);
    const [muteExceptList, setMuteExceptList] = useState([]);
    const [muteFreeList, setMuteFreeList] = useState([]);
    const [muteExceptCount , setMuteExceptCount] = useState(0);
    const [micList, setMicList] = useState([]);
    const [micLevel, setMicLevel] = useState(1);
    const [micLevelList, setMicLevelList] = useState([]);
    const [kickList, setKickList] = useState([]);
    const [tailExceptList, setTailExceptList] = useState([]);
    const [tailFreeList, setTailFreeList] = useState([]);
    const [tailExceptCount , setTailExceptCount] = useState(0);
    const [cameraList, setCameraList] = useState([]);
    const [cameraCamList, setCameraCamList] = useState([]);
    const [availableCamList, setAvailableCamList] = useState([]);
    const [selectedCam, setSelectedCam] = useState();


    
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isCamLoaded, setIsCamLoaded] = useState(false);
    const [isObserving, setIsObserving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const observer = new MutationObserver(observeCallback);

    useEffect(() => {
        return() => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        setData(preData);

        if(!isDataLoaded && data){
            loadLayoutList();
            loadPreferList();
            setIsDataLoaded(true);
        }
        if(data) {
            if(!isEditing){
                setAutoRotationTime(data.autoRotationTime);
                setGroupAutoRotationTime(data.groupAutoRotationTime);
                setGroupAutoRotationSlot(data.fixedSlots);
            }

            setAutoRotationExceptCount(data.exceptSlotCnt);
            setAutoLayoutIdx(data.layoutAutoRotationIdx);
            setMuteExceptCount(data.soundCnt);
            setTailExceptCount(data.tailCnt);

            loadAutoRotation();
            loadMute();
            loadMic();
            loadKick();
            loadTail();
            loadCamera();

        }
        
        if(tailFreeRef.current && data){
            if(!isObserving){
                observer.observe(tailFreeRef.current, {attributes: true, childList: true, subtree: true});
                setIsObserving(true);
            }
        };

    }, [isDataLoaded, data, loadData]);


    function handleMicLevel(e){
        setMicLevel(e.target.value);
    }

    async function handleAutoLayoutIdx(num) {
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "num": num
        };

        try{
            await axios.post(action + "SetAutoLayoutIdxAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function handleAutoLayoutState(state) {
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "num": autoLayoutIdx,
            "state": state
        };

        try{
            await axios.post(action + "SetAutoLayoutStateAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }
    
    function handleAutoRotationTime(e){
        setAutoRotationTime(e.target.value);
        setIsEditing(true);
    }

    function blurAutoRotationTime(){
        setAutoRotation();
        setIsEditing(false);
    }


    function handleGroupAutoRotationTime(e){
        setGroupAutoRotationTime(e.target.value);
        setIsEditing(true);
    }

    function blurGroupAutoRotationTime(){
        setGroupAutoRotation();
        setIsEditing(false);
    }

    function handleGroupAutoRotationSlot(e){
        setGroupAutoRotationSlot(e.target.value);
        setIsEditing(true);
    }

    function blurGroupAutoRotationSlot(){
        changeGroupAutoRotationSlot();
        setIsEditing(false);
    }

    async function loadLayoutList(){
        const postData = {
            "ip": data.ip
        };

        try{
            const res = await axios.post(action + "LoadLayoutListAction", JSON.stringify(postData), headers());
            setLayoutList(res.data);
        }catch (ex){
            console.log(ex);
        }
    }

    async function loadPreferList(){
        try{
            const res = await axios.post(action + "LoadPreferAction", "", headers());
            setPreferList(res.data);
        }catch (ex){
            console.log(ex);
        }
    }

    function loadAutoRotation(){
        let partList = data.partlist;
        let exceptList = partList.filter(item => !data.exceptSlots.some(exceptSlots => exceptSlots.partid === item.partid));
        exceptList = exceptList.filter(item => item.devName !== "ECB");
    
        setAutoRotationExceptList(exceptList);

        let freeList = data.exceptSlots;
        for(let i = 0; i < freeList.length; i++){
            for(let j = 0; j < partList.length; j++){
                if(freeList[i].partid === partList[j].partid){
                    freeList[i].devName = partList[j].devName;
                }
            }
        }
        freeList = freeList.filter(item => item.devName !== "ECB");

        setAutoRotationFreeList(freeList);
    }

    function loadMute(){
        let partList = data.partlist;
        let exceptList = partList.filter(item => !data.soundSlots.some(soundSlots => soundSlots.partid === item.partid));
        exceptList = exceptList.filter(item => item.devName !== "ECB");
    
        setMuteExceptList(exceptList);

        let freeList = data.soundSlots;
        for(let i = 0; i < freeList.length; i++){
            for(let j = 0; j < partList.length; j++){
                if(freeList[i].partid === partList[j].partid){
                    freeList[i].devName = partList[j].devName;
                }
            }
        }
        freeList = freeList.filter(item => item.devName !== "ECB");

        setMuteFreeList(freeList);
    }

    function loadMic(){
        let partList = data.partlist;
        let micList = partList.filter(item => item.devName !== "ECB");
         
        setMicList(micList);

        let micLevelList = new Array;
        for(let i = 1; i <= 8; i++){
            micLevelList.push({"value": i, "label": "LINE " + i});
        }
        for(let i = 11; i <= 18; i++){
            micLevelList.push({"value": i, "label": "MIC " + (i - 10)});
        }

        setMicLevelList(micLevelList);
    }

    function loadKick(){
        let partList = data.partlist;
        let kickList;
        if(data.level == 20){
            kickList = partList;
        }else{
            kickList = partList.filter(item => item.devName !== "ECB");
        }
        
        setKickList(kickList);
    }

    async function loadCamera(){
        let partList = data.partlist;
        let cameraList = partList.filter(item => item.devName !== "ECB");

        setCameraList(cameraList);

        if(cameraRef.current && cameraRef.current.value){
            if(!isCamLoaded){
                getCamera(cameraRef.current.value);
                setIsCamLoaded(true);
            }
        }

        if(cameraCamList != null && cameraCamList.camStatus != null){
            let availableCamList = new Array;
            for(let i = 0; i < cameraCamList.camStatus.length; i++){
                if (cameraCamList.camStatus[i].cam != "0") {
                    if ((i + 1) == cameraCamList.camIndex) {
                        availableCamList.push({"value": cameraCamList.camStatus[i].value, "label": (i + 1) + "번 카메라"});
                        setSelectedCam(cameraCamList.camStatus[i].value);
                    }else{
                        availableCamList.push({"value": cameraCamList.camStatus[i].value, "label": (i + 1) + "번 카메라"});
                    }
                }
            }
            setAvailableCamList(availableCamList);
        }
    }

    function loadTail(){
        let partList = data.partlist;
        let exceptList = partList.filter(item => !data.tail.some(tail => tail.partid === item.partid));
        exceptList = exceptList.filter(item => item.devName !== "ECB");
    
        setTailExceptList(exceptList);

        let freeList = data.tail;
        for(let i = 0; i < freeList.length; i++){
            for(let j = 0; j < partList.length; j++){
                if(freeList[i].partid === partList[j].partid){
                    freeList[i].devName = partList[j].devName;
                }
            }
        }
        freeList = freeList.filter(item => item.devName !== "ECB");

        setTailFreeList(freeList);
    }


    async function autoRotationExcept(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "partid": autoRotationExceptRef.current.value,
            "num": autoRotationExceptCount
        };

        try{
            await axios.post(action + "AutoRotationExceptAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function autoRotationFree(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "num": autoRotationFreeRef.current.value
        };

        try{
            await axios.post(action + "AutoRotationFreeAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function rotate(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
        };

        try{
            await axios.post(action + "RotateAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function groupRotate(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
        };

        try{
            await axios.post(action + "GroupRotateAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    

    async function setAutoRotation(state) {

        if(state == null){
            if(autoRotationStateTrue.current.className == "active"){
                state = true;
            }else if(autoRotationStateFalse.current.className == "active"){
                state = false;
            }
        }

        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "state": state,
            "time": autoRotationTime
        };

        try{
            await axios.post(action + "SetAutoRotationAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function setGroupAutoRotation(state) {

        if(state == null){
            if(groupAutoRotationStateTrue.current.className == "active"){
                state = true;
            }else if(groupAutoRotationStateFalse.current.className == "active"){
                state = false;
            }
        }

        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "state": state,
            "time": groupAutoRotationTime
        };

        try{
            await axios.post(action + "SetGroupAutoRotationAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function changeGroupAutoRotationSlot(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "num": groupAutoRotationSlot
        };

        try{
            await axios.post(action + "SetGroupAutoRotationSlotAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    function setLayoutImage(e){
        const layoutPanel = layoutPanelRef.current;
        
        layoutPanel.scrollTop = (layoutPanel.scrollHeight - layoutPanel.clientHeight) / 1.45;

        setLayout(e.target.value);
    }

    async function changeLayout(value){
        if(!preferMode){
            const postData = {
                "ip": data.ip,
                "uid": data.uid,
                "compType": value
            };

            try{
                await axios.post(action + "SetLayoutAction", JSON.stringify(postData), headers());
                loadData();
            }catch (ex){
                console.log(ex);
            }
        }
    }

    async function setPreferImage(e){
        const value = e.target.value;
        const postData = {
            "type": value
        };

        try{
            await axios.post(action + "SetPreferAction", JSON.stringify(postData), headers());
            setIsDataLoaded(false);
            loadData();
        }catch (ex){
            console.log(ex);
        }
        
    }
    
    function changePreferMode(){
        if(!preferMode){
            preferContainerRef.current.querySelectorAll("img").forEach(child => {child.classList.add("remove")});
            removePreferBtnRef.current.classList.add("active");
        }else{
            preferContainerRef.current.querySelectorAll("img").forEach(child => {child.classList.remove("remove")});
            removePreferBtnRef.current.classList.remove("active");
        }
        setPreferMode(!preferMode);
    }

    async function removePrefer(type){
        if(preferMode){
            const postData = {
                "type": type
            };
    
            try{
                await axios.post(action + "RemovePreferAction", JSON.stringify(postData), headers());
                setIsDataLoaded(false);
                loadData();
            }catch (ex){
                console.log(ex);
            }
        }
    }

    async function muteExcept(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "partid": muteExceptRef.current.value,
            "num": muteExceptCount
        };

        try{
            await axios.post(action + "MuteExceptAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function muteFree(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "num": muteFreeRef.current.value
        };

        try{
            await axios.post(action + "MuteFreeAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function setMicLevelValue(){
        if(micRef.current.value == 101){
            setMicLevel(1);
        }else{
            const postData = {
                "ip": data.ip,
                "uid": data.uid,
                "partid": micRef.current.value
            };
    
            try{
                const res = await axios.post(action + "GetMicLevelAction", JSON.stringify(postData), headers());
                setMicLevel(res.data.micLevel);
                loadData();
            }catch (ex){
                console.log(ex);
            }
        }
    }

    async function changeMicLevel(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "partid": micRef.current.value,
            "level": levelRef.current.value
        };

        try{
            await axios.post(action + "SetMicLevelAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function kick(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "partid": kickRef.current.value,
        };

        try{
            await axios.post(action + "KickAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function tailExcept(){

        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "partid": tailExceptRef.current.value,
            "num": tailExceptCount
        };

        try{
            await axios.post(action + "TailExceptAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function tailFree(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "num": tailFreeRef.current.value,
            "hide": false
        };

        try{
            await axios.post(action + "HideAction", JSON.stringify(postData), headers());
            await axios.post(action + "TailFreeAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    function observeCallback(mutationsList){
        for (let i = 0; i < mutationsList.length; i++){
            if(mutationsList[i].type === "childList"){
                if(tailFreeRef.current.options.length > 0){
                    const value = tailFreeRef.current.options[tailFreeRef.current.selectedIndex].value;
                    getHideState(value);
                }else {
                    getHideState();
                }
            }
        }
    }

    async function getHideState(value){
        if(value){
            const postData = {
                "ip": data.ip,
                "uid": data.uid,
                "num": value,
            };

            const res = await axios.post(action + "GetHideStateAction", JSON.stringify(postData), headers());
            setHideState(res.data.hide);
        }else{
            setHideState("false");
        }
    }

    async function hide(){
        if(tailFreeRef.current){
            let state;
            if(hideState == "true"){
                state = "false";
            }else{
                state = "true";
            }
            
            const postData = {
                "ip": data.ip,
                "uid": data.uid,
                "num": tailFreeRef.current.value,
                "hide": state
            };

            try{
                await axios.post(action + "HideAction", JSON.stringify(postData), headers());
                setHideState(state);
                loadData();
            }catch (ex){
                console.log(ex);
            }
        }
        
    }

    async function getCamera(value){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "partid": value
        };

        try{
            const res = await axios.post(action + "GetCameraAction", JSON.stringify(postData), headers());
            setCameraCamList(res.data);
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function setCamera(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "partid": cameraRef.current.value,
            "cam": camRef.current.value,
        };

        try{
            await axios.post(action + "SetCameraAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    }

    async function setText(){
        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "fontSize": textRef.current.value
        };

        try{
            await axios.post(action + "SetTextAction", JSON.stringify(postData), headers());
            loadData();
        }catch (ex){
            console.log(ex);
        }
    } 








    async function closePanel(){
        const postData = {
            "area": area,
            "num": num,
            "control": ""
        };

        try{
            await axios.post(action + "UpdatePresetAction", JSON.stringify(postData), headers());
            setIsVisible(false);
            loadPreset();
        }catch (ex){
            console.log(ex);
        }

    }

    if(!isVisible){
        observer.disconnect();
        return null;
    }

    function DrawPanel(){
        if(control === "slot"){
            return(
                <>
                    {data && (
                        <>
                            <div className="panel-header">
                                <div className="panel-title">
                                    <img src={require("Img/slot-icon.png")}></img>
                                    <h2>슬롯 컨트롤</h2>
                                </div>
                                {!isMobile && (
                                    <button className="panel-close" onClick={() => {closePanel()}}></button>
                                )}
                            </div>
                            <div className="panel-content">
                                <div className="panel-item">
                                    <div className="panel-item-left">
                                        <label className="panel-label">2번 슬롯 자동 변경</label>
                                        <div className="panel-inner">
                                            <ul className="switch-control">
                                                {data && (
                                                    <>
                                                        <li className={data.autoRotationState == "true" ? "active" : "inactive"} onClick={() => setAutoRotation(true)} ref={autoRotationStateTrue}>활성화</li>
                                                        <li className={data.autoRotationState == "true" ? "inactive" : "active"} onClick={() => setAutoRotation(false)} ref={autoRotationStateFalse}>비활성화</li>
                                                    </>
                                                )}
                                            </ul>
                                            <button className="ok-btn" onClick={() => rotate()}>회전</button>
                                        </div>
                                    </div>
                                    <div className="panel-item-right">
                                        <label className="panel-label">&nbsp;</label>
                                        <div className="panel-inner">
                                            <span>회전시간</span>
                                            <input type="number" className="panel-item-number" onChange={handleAutoRotationTime} onBlur={blurAutoRotationTime} value={autoRotationTime}></input>
                                            <span>초</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-item">
                                    <div className="panel-item-left">
                                        <label className="panel-label">제외하기</label>
                                        <div className="panel-inner">
                                            <select ref={autoRotationExceptRef}>
                                                {autoRotationExceptList && (
                                                    autoRotationExceptList.map((item) => (
                                                        <option key={item.partid} value={item.partid}>{item.devName}</option>
                                                    ))
                                                )}
                                            </select>
                                            <button className="ok-btn" onClick={() => autoRotationExcept()}>적용</button>
                                        </div>
                                    </div>
                                    <div className="panel-item-right">
                                        <label className="panel-label">제외해제</label>
                                        <div className="panel-inner">
                                            <select ref={autoRotationFreeRef}>
                                                {autoRotationFreeList && (
                                                    autoRotationFreeList.map((item) => (
                                                        <option key={item.slotNum} value={item.slotNum}>{item.devName}</option>
                                                    ))
                                                )}
                                            </select>
                                            <button className="ok-btn" onClick={() => autoRotationFree()}>적용</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-underbar"></div>
                                <div className="panel-item">
                                    <div className="panel-item-left">
                                        <label className="panel-label">그룹 자동 회전</label>
                                            <div className="panel-inner">
                                                <ul className="switch-control">
                                                    {data && (
                                                        <>
                                                            <li className={data.groupAutoRotationState == "true" ? "active" : "inactive"} onClick={() => setGroupAutoRotation(true)} ref={groupAutoRotationStateTrue}>활성화</li>
                                                            <li className={data.groupAutoRotationState == "true" ? "inactive" : "active"} onClick={() => setGroupAutoRotation(false)} ref={groupAutoRotationStateFalse}>비활성화</li>
                                                        </>
                                                    )}
                                                </ul>
                                            <button className="ok-btn" onClick={() => groupRotate()}>회전</button>
                                        </div>
                                    </div>
                                    <div className="panel-item-right">
                                        <label className="panel-label">&nbsp;</label>
                                        <div className="panel-inner">
                                            <span>회전시간</span>
                                            <input type="number" className="panel-item-number" onChange={handleGroupAutoRotationTime} onBlur={blurGroupAutoRotationTime} value={groupAutoRotationTime}></input>
                                            <span>초</span>
                                        </div>
                                        <div className="panel-inner">
                                            <span>고정슬롯</span>
                                            <input type="number" className="panel-item-number" onChange={handleGroupAutoRotationSlot} onBlur={blurGroupAutoRotationSlot} value={groupAutoRotationSlot}></input>
                                            <span>개</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="panel-underbar"></div>
                            </div>
                        </>
                    )}
                </>
            );
        }else if(control === "layout"){
            return(
                <>
                    <div className="panel-header">
                        <div className="panel-title">
                            <img src={require("Img/layout-icon.png")}></img>
                            <h2>레이아웃 변경</h2>
                        </div>
                        {!isMobile && (
                            <button className="panel-close" onClick={() => {closePanel()}}></button>
                        )}
                    </div>
                    <div ref={layoutPanelRef} className="panel-content">
                        <div className="panel-item">
                            <div className="panel-item-left">
                                <label className="panel-label">레이아웃 자동 변경</label>
                                <div className="panel-inner">
                                    {data && (
                                        <>
                                            <span>1</span>
                                            <div className={data.layoutAutoRotationIdx == 1 ? "panel-item-radio radio-active" : "panel-item-radio"} onClick={() => handleAutoLayoutIdx(1)}></div>
                                            <span>2</span>
                                            <div className={data.layoutAutoRotationIdx == 2 ? "panel-item-radio radio-active" : "panel-item-radio"} onClick={() => handleAutoLayoutIdx(2)}></div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="panel-item-right">
                                <label className="panel-label">&nbsp;</label>
                                <div className="panel-inner">
                                    <ul className="switch-control">
                                        {data && (
                                            <>
                                                <li className={data.layoutAutoRotationState == "true" ? "active" : "inactive"} onClick={() => handleAutoLayoutState(true)}>활성화</li>
                                                <li className={data.layoutAutoRotationState == "true" ? "inactive" : "active"} onClick={() => handleAutoLayoutState(false)}>비활성화</li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="panel-underbar"></div>
                        <div className="panel-item">
                            <div className="panel-item-column">
                                <label className="panel-label">레이아웃 변경</label>
                                <select className="long" onChange={(e) => setLayoutImage(e)}>
                                    <option hidden>선택</option>
                                    {layoutList.mosaiclist && (
                                        layoutList.mosaiclist.map((item, index) => (
                                            <option key={index} value={item.value}>{item.key}</option>
                                        ))
                                    )}
                                </select>
                                <div className="layout-container">
                                    {layout && (
                                        <img src={require("Img/mosaic" + layout + ".png")} value={layout} onClick={() => changeLayout(layout)}></img>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="panel-underbar"></div>
                    </div>
                </>
            );
        }else if(control === "prefer"){
            return(
                <>
                    <div className="panel-header">
                        <div className="panel-title">
                            <img src={require("Img/layout-change-icon.png")}></img>
                            <h2>선호 레이아웃</h2>
                        </div>
                        <div className="panel-prefer-box">
                            <button ref={removePreferBtnRef} className="black-btn" onClick={() => changePreferMode()}>삭제</button>
                            {!isMobile && (
                                <button className="panel-close" onClick={() => {closePanel()}}></button>
                            )}
                        </div>
                    </div>
                    <div ref={preferPanelRef} className="panel-content">
                        <div className="panel-item">
                            <div className="panel-item-column">
                                <label className="panel-label">선호 레이아웃</label>
                                <select className="long" onChange={(e) => setPreferImage(e)}>
                                    <option hidden>선택</option>
                                    {layoutList.mosaiclist && (
                                        layoutList.mosaiclist.map((item, index) => (
                                            <option key={index} value={item.value}>{item.key}</option>
                                        ))
                                    )}
                                </select>
                                <div ref={preferContainerRef} className="prefer-container">
                                    {preferList.preferList && (
                                        preferList.preferList.map((item, index) => (
                                            <img key={index} data={item.type} src={require("Img/mosaic" + item.type + ".png")} onClick={() => (changeLayout(item.type), removePrefer(item.type))}></img>
                                        )) 
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="panel-underbar"></div>
                    </div>
                </>
            );
        }else if(control == "mic"){
            return(
                <>
                    <div className="panel-header">
                        <div className="panel-title">
                            <img src={require("Img/mic-icon.png")}></img>
                            <h2>마이크</h2>
                        </div>
                        {!isMobile && (
                            <button className="panel-close" onClick={() => {closePanel()}}></button>
                        )}
                    </div>
                    <div className="panel-content">
                        <div className="panel-item">
                            <div className="panel-item-left">
                                <label className="panel-label">전체 음소거 제외</label>
                                <div className="panel-inner">
                                    <select ref={muteExceptRef}>
                                    {muteExceptList && (
                                        muteExceptList.map((item) => (
                                            <option key={item.partid} value={item.partid}>{item.devName}</option>
                                        ))
                                    )}
                                    </select>
                                    <button className="ok-btn" onClick={() => muteExcept()}>적용</button>
                                </div>
                            </div>
                            <div className="panel-item-right">
                                <label className="panel-label">제외된 참여자</label>
                                <div className="panel-inner">
                                    <select ref={muteFreeRef}>
                                        {muteFreeList && (
                                            muteFreeList.map((item) => (
                                                <option key={item.soundSlot} value={item.soundSlot}>{item.devName}</option>
                                            ))
                                        )}
                                    </select>
                                    <button className="ok-btn" onClick={() => muteFree()}>적용</button>
                                </div>
                            </div>
                        </div>
                        
                        <div className="panel-underbar"></div>
                        <div className="panel-item">
                            <div className="panel-item-left">
                                <label className="panel-label">마이크 조절</label>
                                <div className="panel-inner">
                                    <select ref={micRef} onChange={setMicLevelValue}>
                                        <option value={101}>전체</option>
                                        {micList && (
                                            micList.map((item) => (
                                                <option key={item.partid} value={item.partid}>{item.devName}</option>
                                            ))
                                        )}
                                    </select>
                                        {micLevel && (
                                            <select ref={levelRef} className="short" value={micLevel} onChange={handleMicLevel}>
                                                {micLevelList &&(
                                                    micLevelList.map((item) => (
                                                        <option key={item.value} value={item.value}>{item.label}</option>
                                                    ))
                                                )}
                                            </select>
                                        )}
                                    <button className="ok-btn" onClick={() => changeMicLevel()}>적용</button>
                                </div>
                            </div>
                        </div>
                        <div className="panel-underbar"></div>
                    </div>
                </>
            );
        }else if(control === "etc"){
            return(
                <>
                    <div className="panel-header">
                        <div className="panel-title">
                            <img src={require("Img/etc-icon.png")}></img>
                            <h2>기타 컨트롤</h2>
                        </div>
                        {!isMobile && (
                            <button className="panel-close" onClick={() => {closePanel()}}></button>
                        )}
                    </div>
                    <div className="panel-content">
                        <div className="panel-item">
                            <div className="panel-item-left">
                                <label className="panel-label">추방</label>
                                <div className="panel-inner">
                                    <select ref={kickRef}>
                                        {kickList && (
                                            kickList.map((item) => (
                                                <option key={item.partid} value={item.partid}>{item.devName}</option>
                                            ))
                                        )}
                                    </select>
                                    <button className="black-btn" onClick={kick}>추방</button>
                                </div>
                            </div>
                        </div>
                        <div className="panel-underbar"></div>
                        <div className="panel-item tail">
                            <div className="panel-item-left">
                                <label className="panel-label">뒤로 보내기</label>
                                <div className="panel-inner">
                                    <select ref={tailExceptRef}>
                                        {tailExceptList && (
                                            tailExceptList.map((item) => (
                                                <option key={item.partid} value={item.partid}>{item.devName}</option>
                                            ))
                                        )}
                                    </select>
                                    <button className="ok-btn" onClick={() => tailExcept()}>적용</button>
                                </div>
                            </div>
                            <div className="panel-item-right">
                                <label className="panel-label">뒤로 보내기 해제</label>
                                <div className="panel-inner">
                                    <select ref={tailFreeRef} onChange={(e) => getHideState(e.target.value)}>
                                        {tailFreeList && (
                                            tailFreeList.map((item) => (
                                                <option key={item.tailNum} value={item.tailNum}>{item.devName}</option>
                                            ))
                                        )}
                                    </select>
                                    <button className="ok-btn" onClick={() => tailFree()}>적용</button>
                                </div>
                                <div className="panel-item-hide">
                                    <label className="panel-label">숨기기</label>
                                    <div ref={hideRef} className={hideState == "false" ? "hide" : "hide-on hide"} onClick={() => hide()}></div>
                                </div>
                            </div>
                        </div>
                        <div className="panel-underbar"></div>
                    </div>
                </>
            );
        }else if(control === "camera"){
            return(
                <>
                    <div className="panel-header">
                        <div className="panel-title">
                            <img src={require("Img/camera-icon.png")}></img>
                            <h2>카메라</h2>
                        </div>
                        {!isMobile && (
                            <button className="panel-close" onClick={() => {closePanel()}}></button>
                        )}
                    </div>
                    <div className="panel-content">
                        <div className="panel-item">
                            <div className="panel-item-left">
                                <label className="panel-label">카메라 전환</label>
                                <div className="panel-inner">
                                    <select ref={cameraRef} onChange={(e) => getCamera(e.target.value)}>
                                        {cameraList && (
                                            cameraList.map((item) => (
                                                <option key={item.partid} value={item.partid}>{item.devName}</option>
                                            ))
                                        )}
                                    </select>
                                    <select ref={camRef} defaultValue={selectedCam}>
                                        <option value={0}>기본 카메라</option>
                                        {availableCamList && (
                                            availableCamList.map((item) => (
                                                <option key={item.value} value={item.value}>{item.label}</option>
                                            ))
                                        )}
                                    </select>
                                    <button className="ok-btn" onClick={setCamera}>적용</button>
                                </div>
                            </div>
                        </div>
                        <div className="panel-underbar"></div>
                    </div>
                </>
            );
        } else if (control === "text"){
            return(
                <>
                    <div className="panel-header">
                        <div className="panel-title">
                            <img src={require("Img/text-icon.png")}></img>
                            <h2>텍스트</h2>
                        </div>
                        {!isMobile && (
                            <button className="panel-close" onClick={() => {closePanel()}}></button>
                        )}
                    </div>
                    <div className="panel-content">
                        <div className="panel-item">
                            <div className="panel-item-left">
                                <label className="panel-label">이름 크기</label>
                                <div className="panel-inner">
                                    {data && (
                                        <select ref={textRef} defaultValue={data.fontSize}>
                                            <option value="1">소</option>
                                            <option value="2">중</option>
                                            <option value="3">대</option>
                                        </select>
                                    )}
                                    <button className="ok-btn" onClick={setText}>적용</button>
                                </div>
                            </div>
                        </div>
                        <div className="panel-underbar"></div>
                    </div>
                </>
            );
        }
    }
    
    return(
        <>
            {DrawPanel()}
        </>
    );
}

export default Panel;