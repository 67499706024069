import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { action, page, headers } from "Common/WSCommon";
import Popup from "Components/Popup";
import Cookies from "js-cookie";

function LoginPage(){

    const navigate = useNavigate();

    const idRef = useRef(null);
    const pwRef = useRef(null);
    const loginBtnRef = useRef(null);

    const [updatePop, setUpdatePop] = useState(false);

    useEffect(() => {
        if(sessionStorage.getItem("token")){
            goServerPage();
        }
        if(!Cookies.get("2.02")){
            setUpdatePop("true");
            Cookies.set("2.02", true, { expires: 365 });
        }

    }, []);

    async function goServerPage(){
        try{
            const pre = await axios.post(page + "ServerPage", "", headers());
            navigate("/Server/", {state : pre.data});
        }catch{
            sessionStorage.removeItem("token");
            window.location.reload();
        }
    }

    function handleKeyDown(e){
        if(e.key === "Enter"){
            loginBtnRef.current.click();
        }
    }

    async function LoginAction(){
        const postData = {
            "id": idRef.current.value,
            "pw": pwRef.current.value
        };

        try {
            if(sessionStorage.getItem("token")){
                sessionStorage.removeItem("token");
            }
            const res = await axios.post(action + "LoginAction", JSON.stringify(postData), headers());

            if(!res.data){
                alert("아이디와 비밀번호를 확인하여 주십시오!");
            }else {
                sessionStorage.setItem("token", res.data);
                const pre = await axios.post(page + "ServerPage", "", headers());
                navigate("/Server", {state: pre.data});
            }
        }catch(ex){
            alert("로그인 과정에서 문제가 발생하였습니다!");
            console.log(ex);
        }
        
    }
    function handleUpdate(state){
        setUpdatePop(state);
    }

    return(
        <div className="login-container">
            <div className="login-background"></div>
            <div className="login-area">
                <div className="login-box">
                    <div className="login-logo">
                        <img src={require("Img/logo-01.png")}></img>
                    </div>
                    <div className="login-title">
                        <h2>MCU ECB</h2>
                        <h3>욱성미디어의 향상된 MCU 제어기술을 만나보세요.</h3>
                    </div>
                    <div className="login-form">
                        <label className="login-input-label">ID</label>
                        <input className="login-input" ref={idRef} type="text" onKeyDown={handleKeyDown} placeholder="아이디를 입력해주세요."></input>
                        <label className="login-input-label">Password</label>
                        <input className="login-input" ref={pwRef} type="password" onKeyDown={handleKeyDown} placeholder="비밀번호를 입력해주세요."></input>
                        <div className="login-btn">
                            <button className="login-btn" ref={loginBtnRef} onClick={LoginAction}>로그인</button>
                            <button className="login-btn" onClick={() => handleUpdate("true")}>업데이트 내역</button>
                        </div>
                    </div>
                    <div className="login-footer">
                        <p>대전광역시 유성구 테크노2로 340 원플러스 2층</p>
                        <div className="login-footer-second">
                            <p>Tel : 010-8304-5594</p>
                            <p>|</p>
                            <p>Email : yglee@wooksungmedia.com</p>
                        </div>
                        <p>ⓒ Copyright 욱성미디어. All Rights Reserved</p>
                    </div>
                </div>
            </div>
            {updatePop == "true" && (
                <Popup param={"update"} popAction={handleUpdate}></Popup>
            )}
        </div>
    );
}

export default LoginPage;