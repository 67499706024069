import { useEffect, useState } from "react";
import RefreshToken from "Components/RefreshToken";
import Header from "Components/Header";
import TopMenuRoute from "Components/TopMenuRoute";
import Footer from "Components/Footer";
import { page, headers, action } from "Common/WSCommon";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Popup from "Components/Popup";

function VideoPage(){
    const location = useLocation();
    const preData = location.state || null;
    const [data, setData] = useState([]);
    const [videoCount, setVideoCount] = useState();
    const [endPageNum, setEndPageNum] = useState(1);
    const [videoData, setVideoData] = useState();
    const [pageNum, setPageNum] = useState(1);
    const [onDateStart, setOnDateStart] = useState();
    const [onDateEnd, setOnDateEnd] = useState();
    const [searchName, setSearchName] = useState("");
    const [searchServer, setSearchServer] = useState();
    const [part, setPart] = useState();
    const [pageList, setPageList] = useState();
    const [partList, setPartList] = useState();
    const [videoNamePop, setVideoNamePop] = useState();
    const [videoName, setVideoName] = useState();
    const [videoId, setVideoId] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        loadPartList();
    },[]);

    useEffect(() => {
        loadPageList();
    }, [endPageNum, pageNum]);

    useEffect (() => {
        setData(preData);
        if(data){
            setPart(data.part);
        }
    }, [data]);

    useEffect(() => {
        if(part){
            loadVideoData();
        }
    }, [part, pageNum]);

    async function loadPartList(){

        try{
            const res = await axios.post(action + "LoadPartListAction", "", headers());
            setPartList(res.data);
        }catch{
            sessionStorage.removeItem("token");
            navigate("/Login");
        }
    }

    function loadPageList(){
        let pageList = new Array;
        if(pageNum < 4){
            for(let i = 1; i <= endPageNum; i++){
                if(i > 5){
                    break;
                }
                pageList.push(i);
            }
        }else if(pageNum + 2 > endPageNum){
            pageList.push(endPageNum - 4);
            pageList.push(endPageNum - 3);
            pageList.push(endPageNum - 2);
            pageList.push(endPageNum - 1);
            pageList.push(endPageNum);
        }else{
            pageList.push(pageNum - 2);
            pageList.push(pageNum - 1);
            pageList.push(pageNum);
            pageList.push(pageNum + 1);
            pageList.push(pageNum + 2);
        }
        setPageList(pageList);
    }

    async function loadVideoData(){
        const postData = {
            "part": part,
            "pageNum": pageNum,
            "onDateStart": onDateStart,
            "onDateEnd": onDateEnd,
            "searchName": searchName,
            "searchServer": searchServer,
        };

        try{
            const res = await axios.post(action + "LoadVideoListAction", JSON.stringify(postData), headers());
            setVideoData(res.data);
            const resCnt = await axios.post(action + "LoadVideoCountAction", JSON.stringify(postData), headers());
            setVideoCount(resCnt.data);
            setEndPageNum(resCnt.data.endPageNum);
        }catch{
            sessionStorage.removeItem("token");
            navigate("/Login");
        }
    }

    function handleOnDateStart(e){
        setOnDateStart(e.target.value);
    }

    function handleOnDateEnd(e){
        setOnDateEnd(e.target.value);
    }

    function handleSearchName(e){
        setSearchName(e.target.value);
    }

    function handleSearchServer(e){
        setSearchServer(e.target.value);
    }

    function handlePageNum(item){
        if(item < 1 || item > endPageNum){
            return false;
        }else{
            setPageNum(item);
        }
    }

    async function handleDownload(name){
        const postData = {
            "name": name
        };

        window.location.href = action + "DownloadVideoAction?name=" + name;

        // try{
        //     const res = await axios.post(action + "DownloadVideoAction", JSON.stringify(postData), { 
        //         headers: {
        //             "Authorization": 'Bearer ' + sessionStorage.getItem("token")
        //         },
        //         responseType: "blob"
        //     });
        //     const url = window.URL.createObjectURL(new Blob([res.data], {type: "video/mp4"}));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', name); 
        //     document.body.appendChild(link);
        //     link.click();
        //     link.parentNode.removeChild(link);
        // }catch(ex){
        //     console.log(ex);
        // }
    }

    function handleVideoName(name, id){
        setVideoName(name);
        setVideoId(id);
        handleVideoNamePop("true");
    }

    function handleVideoNamePop(state){
        setVideoNamePop(state);
    }

    async function removeVideo(name, id){
        let ur = window.confirm("삭제하시겠습니까?");
        if(ur){
            const postData = {
                "name": name,
                "id": id
            };

            try{
                await axios.post(action + "RemoveVideoAction", JSON.stringify(postData), headers());
                loadVideoData();
            }catch(ex){
                console.log(ex);
            }
        }
    }

    return(
        <div className="container">
            <RefreshToken></RefreshToken>
            <div className="wrap">
                {data.level && (
                    <Header route={"video"} level={data.level}></Header>
                )}
                <div className="content">
                    <TopMenuRoute route={"video"}></TopMenuRoute>
                    <div className="video-count">
                        <h1>Total</h1>
                        {videoCount && (
                            <h2>{videoCount.count}</h2>
                        )}
                        <h1>건</h1>
                        <h1>/</h1>
                        {endPageNum && (
                            <h1>{endPageNum}</h1>
                        )}
                        <h1>Page</h1>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    <span>날짜</span>
                                    <input type="date" value={onDateStart} onChange={handleOnDateStart}></input>
                                    <span>-</span>
                                    <input type="date" value={onDateEnd} onChange={handleOnDateEnd}></input>
                                </th>
                                <th>
                                    <span>이름</span>
                                    <input type="text" value={searchName} onChange={handleSearchName}></input>
                                </th>
                                <th>
                                    <span>서버</span>
                                    {part == "wooksung" && (
                                        <select value={searchServer} onChange={handleSearchServer}>
                                            {partList && (
                                                partList.partList.map((item, index) => (
                                                    <option key={index} value={item.part}>{item.name}</option>
                                                ))
                                            )}
                                        </select>
                                    )}
                                </th>
                                <th>
                                    <button className="green" onClick={loadVideoData}>검색</button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {videoData && (
                                videoData.list.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span>{item.date}</span>
                                        </td>
                                        <td>
                                            <span>{item.name.replace(".mp4", "")}</span>
                                        </td>
                                        <td>
                                            <span>{item.server}</span>
                                        </td>
                                        <td>
                                            {item.encoding == "false" && (
                                                <button className="encoding">인코딩 중</button>
                                            )}
                                            {item.encoding == "true" && (
                                                <>
                                                <button className="green" onClick={() => handleDownload(item.name)}>다운로드</button>
                                                <button className="green" onClick={() => handleVideoName(item.name.replace(".mp4", ""), item.id)}>이름 변경</button>
                                                <button className="red" onClick={() => removeVideo(item.name, item.id)}>삭제</button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <ul className="paging">
                        <li onClick={() => handlePageNum(1)}>
                            <img src={require("Img/page_prev_02.png")}></img>
                        </li>
                        <li onClick={() => handlePageNum(Number(pageNum) - 1)}>
                            <img src={require("Img/page_prev_01.png")}></img>
                        </li>
                        {pageList && (
                            pageList.map((item, index) => (
                                <li key={index} className={pageNum == item ? "active" : ""} onClick={() => handlePageNum(item)}>
                                    {item}
                                </li>
                            ))
                        )}
                        <li onClick={() => handlePageNum(Number(pageNum) + 1)}>
                            <img src={require("Img/page_next_01.png")}></img>
                        </li>
                        <li onClick={() => handlePageNum(Number(endPageNum))}>
                            <img src={require("Img/page_next_02.png")}></img>
                        </li>
                    </ul>
                </div>
                <Footer></Footer>
            </div>
            {videoNamePop == "true" && (
                <Popup param={"videoName"} popAction={handleVideoNamePop} preVideoName={videoName} preVideoId={videoId} videoData={loadVideoData}></Popup>
            )}
        </div>
    );
}

export default VideoPage;