
import { useState, useLayoutEffect, useEffect  } from "react";
import axios from "axios";
import RefreshToken from "Components/RefreshToken";
import Header from "Components/Header";
import Footer from "Components/Footer";
import { page, headers, action } from "Common/WSCommon";
import { useNavigate, useLocation } from "react-router-dom";
import TopMenuRoute from "Components/TopMenuRoute";
import Popup from "Components/Popup";


function RoomPage(){
    const location = useLocation();
    const preData = location.state || {};

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [ip, setIp] = useState();
    const [isRemoveOn, setIsRemoveOn] = useState(false);
    const [roomAddPop, setRoomAddPop] = useState(false);
    const [createRoomParam, setCreateRoomParam] = useState([]);
    const [conflist, setConflist] = useState([]);
    

    useEffect(() => {
        loadRoomCreateData();
        setData(preData);
        if(!data){
            loadData();
        }
        console.log(preData);
    }, []);

    useEffect(() => {
        
    }, [preData])

    useEffect(() => {
        if(data){
            setIp(data.ip);
        }
    }, [data])

    
    function handleData(data){
        setData(data);
    }

    function handleRoomAdd(state){
        setRoomAddPop(state);
    }

    function handleRoomRemove(){
        setIsRemoveOn(!isRemoveOn);
    }

    async function loadData(){
        const postData = {
            "ip": ip
        };

        try{
            const res = await axios.post(page + "RoomPage", JSON.stringify(postData), headers());
            setData(res.data);
        }catch{
            sessionStorage.removeItem("token");
            navigate("/Login");
        }
    }
    
    async function removeRoom(uid){
        const postData = {
            "ip": data.ip,
            "uid": uid
        };

        try{
            await axios.post(action + "RemoveRoomAction", JSON.stringify(postData), headers());
            const pre = await axios.post(page + "RoomPage", JSON.stringify(postData), headers());
            handleData(pre.data);
        }catch(ex){
            console.log(ex);
        }
    }

    async function loadRoomCreateData(){
        const postData = {
            "ip": preData.ip
        };

        try{
            const res = await axios.post(action + "GetCreateRoomParamAction", JSON.stringify(postData), headers());
            setCreateRoomParam(res.data);
        }catch{
            sessionStorage.removeItem("token");
            navigate("/Login");
        }
    }

    async function goControlPage(ip, uid, did, name){
        const postData = {
            "ip": ip,
            "uid": uid,
            "did": did,
            "name": name
        };
        try{
            navigate("/Control/", {state : postData});
        }catch{
            sessionStorage.removeItem("token");
            navigate("/Login");
        }
    }

    async function getRoomCount(ip, uid){
        let count = 0;

        const postData = {
            "ip": ip,
            "uid": uid
        };

        try{
            const res = await axios.post(action + "LoadControlDataAction", JSON.stringify(postData), headers());
            console.log(res);
            count = Number(res.data.participantCnt);
            if(res.data.partlist){
                for (let i = 0; i < res.data.partlist.length; i++) {
                    if (res.data.partlist[i].devName == "ECB") {
                        count -= 1;
                    }
                }
                
                if (count < 0) {
                    count = 0;
                }
            }
        }catch(ex){
            console.log(ex);
        }

        return count;
    }

    return(
        <div className="container">
            <RefreshToken></RefreshToken>
            <div className="wrap">
                {data.level && (
                    <Header route={"server"} level={data.level}></Header>
                )}
                <div className="content">
                    <TopMenuRoute route={"room"} handleRoomAdd={handleRoomAdd} handleRoomRemove={handleRoomRemove}></TopMenuRoute>
                    <ul className="server-list">
                        {data.conflist && (
                            data.conflist.map((room, index) => (
                                <li className={isRemoveOn ? "remove" : ""} key={index} onClick={isRemoveOn ? () => removeRoom(room.uid) : () => goControlPage(data.ip, room.uid, room.did, room.name)}>
                                    <img src={require("Img/room-icon.png")}></img>
                                    <div className="room-label">
                                        <h2>{room.name}</h2><h2 className={Number(room.numpart) > 0 ? "red" : ""}>({room.numpart})</h2>
                                    </div>
                                    <span>{isRemoveOn ? "방 삭제" : "방 입장"}</span>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
                <Footer></Footer>
            </div>
            {roomAddPop == "true" && (
                <Popup param={"roomAdd"} popAction={handleRoomAdd} data={createRoomParam} ip={data.ip} handleData={handleData}></Popup>
            )}
        </div>
             
    );
}

export default RoomPage;