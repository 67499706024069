

function Footer(){
    return(
        <div className="footer">
            <p>대전광역시 유성구 테크노2로 340 원플러스 2층</p>
            <p>Tel :   010-8304-5594    |    Email :    yglee@wooksungmedia.com</p>
            <p>ⓒ Copyright 욱성미디어. All Rights Reserved</p>
        </div>
    );
}

export default Footer;