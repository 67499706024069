import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import ServerPage from "./Pages/ServerPage";
import LoginPage from "./Pages/LoginPage";
import RoomPage from "./Pages/RoomPage";
import ControlPage from "./Pages/ControlPage";
import VideoPage from "Pages/VideoPage";
import UserPage from "Pages/UserPage";
import StatPage from "Pages/StatPage";
import { useEffect } from "react";

function App(){

    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" Component={LoginPage}></Route>
                <Route path="/Login" Component={LoginPage}></Route>
                <Route path="/Server" Component={ServerPage}></Route>
                <Route path="/Room" Component={RoomPage}></Route>
                <Route path="/Control" Component={ControlPage}></Route>
                <Route path="/Video" Component={VideoPage}></Route>
                <Route path="/User" Component={UserPage}></Route>
                <Route path="/Stat" Component={StatPage}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;