import JsSIP from "jssip";
import { useEffect, useRef, useState } from "react";
import { slotChangeClick } from "Components/SlotChange";
import { action, headers } from "Common/WSCommon";
import axios from "axios";

function VideoClent({preData, compType, did}){
    const remoteVideoRef = useRef();

    const [data, setData] = useState();
    const [slotFrom, setSlotFrom] = useState();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [safari, setSafari] = useState(false);

    let session = null;
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    function handleBeforeUnload() {
        if(session){
            session.terminate();
        }
    };
    useEffect(() => {
        setData(preData);

        navigator.permissions.query({ name: 'microphone' })
            .then(permissionStatus => {
                if (permissionStatus.state != 'granted') {
                    navigator.mediaDevices.getUserMedia({ audio: true })
                        .then(function () {

                        })
                        .catch(function () {
                            alert("메인 페이지에서 유틸리티를 설치해 주십시오!");
                            window.history.back();
                        });
                }

            });

        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvasContext.fillStyle = 'rgb(120, 150, 120)';
        canvasContext.fillRect(0, 0, canvas.width, canvas.height);

        loadVideo();
        
        async function loadVideo(){
            if(data && !isDataLoaded){

                const userAgent = navigator.userAgent.toLowerCase();
                const isSafari = userAgent.includes('safari') && !userAgent.includes('chrome');
                setSafari(isSafari);

                let localStream = canvas.captureStream();
                // const videoOption = {
                //     width: {min: 640, ideal:640, max: 1280},
                //     height: {min: 360, ideal:360, max: 720},
                //     frameRate: { ideal: 24, max: 30}
                // };
                // navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
                //     localStream = stream;
                // });

                localStream = addVirtualSoundToStream(localStream);
                

                JsSIP.debug.enable('JsSIP:*');
                var configuration = {
                    uri: "sip:ecb@mcuecb.com",
                    authorization_user: "ecb",
                    password: "roskfl",
                    register: true,
                    sockets: [new JsSIP.WebSocketInterface("wss://mcuecb.com:10062/asterisk/ws")],
                };
    
                const ua = new JsSIP.UA(configuration);
                ua.start();
    
                ua.on('connected', function () {
                    var options = {
                        mediaStream: localStream
                    };
                
                    session = ua.call("sip:" + did + "@mcuecb.com", options);

                    if(isSafari){
                        session.on("connecting", function () {
                            var transceivers = session.connection.getTransceivers();

                            try {
                                var codecAudioObj = [{
                                    channels: 1,
                                    clockRate: 8000,
                                    mimeType: "audio/G722"
                                }];

                                var codecVideoObj = [{
                                    mimeType: "video/H264",
                                    clockRate: 90000,
                                    sdpFmtpLine: "level-asymmetry-allowed=1;packetization-mode=1;profile-level-id=42e01f"
                                }];

                                transceivers[0].setCodecPreferences(codecVideoObj);
                                transceivers[1].setCodecPreferences(codecAudioObj);
                            } catch (e) {
                                console.log(e);
                                alert("코덱을 불러오는데 실패했습니다! 다시 접속해 주세요.");
                            }
                        });
                    }

                    session.on("accepted", function () {
                        console.log("Session Accepted!!");
                        try {
                    
                            let stream = new MediaStream();
                            stream.addTrack(session.connection.getReceivers()[0].track);
                            stream.addTrack(session.connection.getReceivers()[1].track);

                            remoteVideoRef.current.srcObject = stream;

                            console.log("Complete!");
                            setIsDataLoaded(true);
                        } catch (e) {
                            console.log(e);
                        }
                    
                    });
                });
            }
        }
  
        return () => {
            if(session){
                session.terminate();
            }
        };
    }, [data])


    function handleSlotFrom(e){
        const res = slotChangeClick(e, compType, remoteVideoRef.current);
        setSlotFrom(res);
    }

    async function handleSlotTo(e){
        const res = slotChangeClick(e, compType, remoteVideoRef.current);
        const slotTo = res;

        const postData = {
            "ip": data.ip,
            "uid": data.uid,
            "slot1": slotFrom + 1,
            "slot2": slotTo + 1
        }

        try{
            await axios.post(action + "ChangeTwoSlotAction", JSON.stringify(postData), headers());
        }catch(ex){
            console.log(ex);
        }
    }

    async function addVirtualSoundToStream(stream) {
        try {
            const response = await fetch(action + "GetVirtualAudioAction");
            const arrayBuffer = await response.arrayBuffer();
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;
    
            const destination = audioContext.createMediaStreamDestination();
    
            source.connect(destination);
            source.start();
    
            const mediaStream = destination.stream;
            const audioTrack = mediaStream.getAudioTracks()[0];
            console.log(audioTrack);
            await stream.addTrack(audioTrack);
            
            return stream;
        } catch (ex) {
            console.log(ex);
        }
    }

    return(
        <video ref={remoteVideoRef} controls={safari ? true : false}  autoPlay playsInline onMouseDown={(e) => handleSlotFrom(e)} onMouseUp={(e) => handleSlotTo(e)}></video>
    );
}

export default VideoClent;