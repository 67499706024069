import axios from "axios";
import { action, headers } from "Common/WSCommon";
import Header from "Components/Header";
import RefreshToken from "Components/RefreshToken";
import TopMenuRoute from "Components/TopMenuRoute";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


function StatPage(){

    const location = useLocation();
    const preData = location.state || null;

    const [data, setData] = useState([]);
    const [part, setPart] = useState();
    const [onMonth, setOnMonth] = useState(1);
    const [searchServer, setSearchServer] = useState("ganghwa");
    const [searchRoom, setSearchRoom] = useState();
    const [partList, setPartList] = useState();
    const [monthList, setMonthList] = useState();
    const [roomList, setRoomList] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        loadPartList();
        loadMonthList();
    },[]);

    useEffect (() => {
        setData(preData);
        if(data){
            setPart(data.part);
        }
    }, [data]);

    function loadMonthList(){
        let monthList = new Array;
        for(let i = 1; i <= 12; i++){
            monthList.push({"label": i+"월", "value": i});
        }
        setMonthList(monthList);
    }

    async function loadRoomNameList(server, month){
        let postData;
        if(server == "empty"){
            postData = {
                "onMonth": month,
                "server": searchServer
            };
        }else if(month == "empty"){
            postData = {
                "onMonth": onMonth,
                "server": server
            };
        }

        

        try{
            const res = await axios.post(action + "LoadStatRoomListAction", JSON.stringify(postData), headers());
            setRoomList(res.data);
            setSearchRoom(res.data.room[0]);
        }catch(ex){
            console.log(ex);
        }

    }

    async function loadPartList(){

        try{
            const res = await axios.post(action + "LoadPartListAction", "", headers());
            setPartList(res.data);
        }catch{
            sessionStorage.removeItem("token");
            navigate("/Login");
        }
    }

    function handleOnMonth(e){
        setOnMonth(e.target.value);
        loadRoomNameList("empty", e.target.value);
    }

    function handleSearchServer(e){
        setSearchServer(e.target.value);
        loadRoomNameList(e.target.value, "empty");
    }

    function handleSearchRoom(e){
        setSearchRoom(e.target.value);
    }

    async function downloadExcel(){
        const postData = {
            "onMonth": onMonth,
            "server": searchServer,
            "room": searchRoom
        };

        try{
            const res = await axios.post(action + "LoadStatExcelAction", JSON.stringify(postData), { 
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem("token")
                },
                responseType: "blob",
            });

            let today = new Date();

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', today.toLocaleDateString("en-US") + '.xlsx'); // 다운로드할 파일 이름 설정
            document.body.appendChild(link);
            link.click();
            link.remove();
        }catch(ex){
            console.log(ex);
        }
    }

    return(
        <div className="container">
            <RefreshToken></RefreshToken>
            <div className="wrap">
                {data.level && (
                    <Header route={"stat"} level={data.level}></Header>
                )}
                <div className="content">
                    <TopMenuRoute route={"stat"}></TopMenuRoute>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    <span>월</span>
                                    <select onChange={handleOnMonth}>
                                    {monthList && (
                                        monthList.map((item, index) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                    )}
                                    </select>
                                </th>
                                <th>
                                    <span>서버</span>
                                    {part == "wooksung" && (
                                        <select value={searchServer} onChange={handleSearchServer}>
                                            {partList && (
                                                partList.partList.map((item, index) => (
                                                    <option key={index} value={item.part}>{item.name}</option>
                                                ))
                                            )}
                                        </select>
                                    )}
                                </th>
                                <th>
                                    <span>방 이름</span>
                                    <select value={searchRoom} onChange={handleSearchRoom}>
                                        {roomList && (
                                            roomList.room.map((item, index) => (
                                                <option key={index} value={item}>{item}</option>
                                            ))
                                        )}
                                    </select>
                                </th>
                                <th>
                                    <button className="green" onClick={downloadExcel}>다운로드</button>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                
            </div>
        </div>
    );
}

export default StatPage;