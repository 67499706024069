import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "./Popup";
import { page, headers } from "Common/WSCommon";
import axios from "axios";

function Header({route, level}){

    const [utilPop, setUtilPop] = useState(false);
    const [passwordPop, setPasswordPop] = useState(false);
    const [menuToggle, setMenuToggle] = useState(false);

    const navigate = useNavigate();


    async function goServerPage(){
        const pre = await axios.post(page + "ServerPage", "", headers());
        navigate("/Server", {state: pre.data});
    }

    async function goVideoPage(){
        const pre = await axios.post(page + "VideoPage", "", headers());
        navigate("/Video", {state: pre.data});
    }

    async function goStatPage(){
        const pre = await axios.post(page + "StatPage", "", headers());
        navigate("/Stat", {state: pre.data});
    }

    async function goUserPage(){
        const pre = await axios.post(page + "UserPage", "", headers());
        navigate("/User", {state: pre.data});
    }

    function handleUtil(state){
        setUtilPop(state);
    }

    function handlePassword(state){
        setPasswordPop(state);
    }

    function logout(){
        sessionStorage.removeItem("token");
        navigate("/Login");
    }

    function handleMenuToggle(){
        setMenuToggle(!menuToggle);
    }

    return(
        
        <div className="header">
            {utilPop && (
                <>
                    <Popup param={"util"} popAction={handleUtil}></Popup>
                </>
            )}
            {passwordPop && (
                <>
                    <Popup param={"password"} popAction={handlePassword}></Popup>
                </>
            )}
            <div className="header-logo" onClick={() => goServerPage()}>
                <img src={require("Img/logo-01.png")}></img>
            </div>
            <ul className="header-nav">
                {Number(level) >= 10 && (
                    <>
                        <li className={route == "video" ? "active" : ""} onClick={() => goVideoPage()}>녹화 영상</li>
                        <li className={route == "stat" ? "active" : ""} onClick={() => goStatPage()}>통계 자료</li>
                    </>
                )}
                {Number(level) >= 20 && (
                    <>
                        {/* <li onClick={() => goStatPage()}>통계 자료</li> */}
                        <li className={route == "user" ? "active" : ""} onClick={() => goUserPage()}>사용자 관리</li>
                    </>
                )}

                {Number(level) >= 0 && (
                    <>
                        <li className={route == "server" ? "active" : ""} onClick={() => goServerPage()}>MCU 목록</li>
                    </>
                )}  
            </ul>
            {!menuToggle && (
                <button className="header-toggle" onClick={handleMenuToggle}>
                <img src={require("Img/menu.png")}></img>
                </button>
            )}
            <ul className="header-btn-box">
                <li onClick={() => handleUtil(true)}>
                    <img src={require("Img/util-icon.png")}></img>
                    유틸리티 다운
                </li>
                <li onClick={() => handlePassword(true)}>
                    <img src={require("Img/key.png")}></img>
                    비밀번호 변경
                </li>
                <li onClick={() => logout()}>
                    <img src={require("Img/logout-icon.png")}></img>
                    로그아웃
                </li>
            </ul>
            {menuToggle && (
                <div className="mobile-menu">
                    <div className="mobile-menu-top">
                        <button className="close-menu" onClick={handleMenuToggle}>
                            <img src={require("Img/close-menu.png")}></img>
                        </button>
                    </div>
                    <div className="mobile-menu-content">
                        <ul className="mobile-menu-list">
                            {Number(level) >= 10 && (
                                <>
                                    <li className={route == "video" ? "active" : ""} onClick={() => goVideoPage()}>녹화 영상</li>
                                    <li className={route == "stat" ? "active" : ""} onClick={() => goStatPage()}>통계 자료</li>
                                </>
                            )}
                            {Number(level) >= 20 && (
                                <>
                                    {/* <li onClick={() => goStatPage()}>통계 자료</li> */}
                                    <li className={route == "user" ? "active" : ""} onClick={() => goUserPage()}>사용자 관리</li>
                                </>
                            )}

                            {Number(level) >= 0 && (
                                <>
                                    <li className={route == "server" ? "active" : ""} onClick={() => goServerPage()}>MCU 목록</li>
                                </>
                            )}
                            <li onClick={() => handlePassword(true)}>
                                비밀번호 변경
                            </li>
                            <li onClick={() => logout()}>
                                로그아웃
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            
        </div>
    );

    
}

export default Header;